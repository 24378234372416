/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import React, { useState } from "react";
import {
  PieChart,
  Pie,
  // Sector,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";

const colors = ["#20BB7DA3", "#6678D1A3", "#DC9E57", "#575D5E"];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    // innerRadius,
    outerRadius,
    // startAngle,
    // endAngle,
    fill,
    // payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 10;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text> */}
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        fontSize={14}
        fontWeight={600}
      >{`${(percent * 100).toFixed(1)}%`}</text>
      {/* >{`${value}`}</text> */}
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

// Custom legend rendering function
const renderLegend = (props) => {
  const { payload } = props; // Payload contains legend data
  return (
    <ul
      style={{
        listStyleType: "none",
        margin: 0,
        padding: 0,
        textAlign: "center",
        display: "flex",
        gap: "12px",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {payload.map((entry, index) => {
        return (
          <li
            key={`item-${index}`}
            style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
          >
            {/* Circle Icon */}
            <span
              style={{
                display: "inline-block",
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: entry.color,
                marginRight: "6px",
              }}
            />
            {/* Legend Text with Percentage */}
            <span
              style={{ color: "#4A4C50", fontSize: "14px", fontWeight: "400" }}
            >
              {entry.value}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default function PMChart({summaryData}) {
  
  const data = [
    { name: "Complete", value: summaryData?.total_completed_pm },
    { name: "Open", value: summaryData?.total_open_pm},
    { name: "In progress", value: summaryData?.total_in_progress_pm },
    { name: "On hold", value: summaryData?.total_on_hold_pm},
  ];

  const isAllZero = data.every((entry) => entry.value === 0);

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      {isAllZero ? (
        // Render fallback when there's no data
        <div style={{ textAlign: "center", color: "#4A4C50", padding: "20px", paddingTop: "70px" }}>
          <p style={{ fontSize: "16px", fontWeight: "500" }}>No data</p>
        </div>
      ) : (
        // Render pie chart if there's valid data
        <PieChart>
          <Legend content={renderLegend} wrapperStyle={{ bottom: 20 }} />
          <Pie
            activeIndex={activeIndex}
            label={renderActiveShape}
            data={data}
            cx="50%"
            cy="45%"
            innerRadius={29}
            outerRadius={40}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </PieChart>
      )}
    </ResponsiveContainer>
  );
}
