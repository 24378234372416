/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  getBillsSummary,
  reset,
  resetBillSummaryState,
  setBOSCurrentPage,
  setBOSRowPerPage,
  setIsBillSummaryPrint,
  getBillsSummaryById,
  deleteBillSummaryById,
} from '../../../redux/features/SOR/SORSlice';
import EpqTable from '../epqTable';
import Loader from '../../../components/loader/loader';
import Pagination from '../../../components/pagination/pagination';
import IconDownload from '../../../assets/svg-icons/IconDownload';
import DeleteIcon from '../../../assets/svg-icons/DeleteIcon';
import EditIcon from '../../../assets/svg-icons/EditIcon';
import { migrateBillSummaryData } from '../ebqUtils';
import NewModal from '../../../components/modals/NewModal';
import EbqSummaryPdf from '../ebqSummaryPdf';

function BOS() {
  const dispatch = useDispatch();
  const pdf = true;
  const {
    SORTab,
    BOSSearchValue,
    BOSData,
    BOSRowPerPage,
    BOSCurrentPage,
    BOSDateFilterState,
    BOSStatus,
    singleBillSummary,
    isBillSummaryPrint,
    isBillSummaryDeleteSuccess,
    isBillSummaryDeleteError,
    summaryBillData,
  } = useSelector((state) => state.SOR);

  const [iSShowFilter, setISShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isShowPopUp, setIsShowPopUp] = useState({ flag: false, type: null, id: null });
  const history = useHistory();

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (cell.column.id !== 'actions') {
      history.push(`/ebq/bill-summary/preview/${selectedRow?.id}`);
    }
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    onAfterPrint: () => {
      dispatch(resetBillSummaryState());
    },
  });

  const handleClickEdit = (id) => {
    history.push(`/ebq/bill-summary/${id}`);
  };
  const handleClickDownload = async (id) => {
    const theBill = await dispatch(getBillsSummaryById(id));
    migrateBillSummaryData(theBill.payload, dispatch);
    dispatch(setIsBillSummaryPrint(true));
  };

  const handleClickCopy = (id) => {
    setIsShowPopUp({ flag: true, type: 'copy', id });
  };
  const handleClickDelete = async (id) => {
    setIsShowPopUp({ flag: true, type: 'delete', id });
  };

  const handlePopupAction = async () => {
    if (isShowPopUp.type === 'delete') {
      await dispatch(deleteBillSummaryById(isShowPopUp.id));
    } else if (isShowPopUp.type === 'copy') {
      console.log('COPY');
    }
  };

  useEffect(() => {
    if (isBillSummaryDeleteSuccess) {
      toast.success('Bill Summary Deleted Successfully', {
        toastId: 'integrationSyncStarted',
      });
    }
    if (isBillSummaryDeleteError) {
      toast.error('Something went wrong while Deleting Bill Summary', {
        toastId: 'integrationSyncStarted',
      });
    }
    dispatch(reset());
    setIsShowPopUp({ flag: false, type: null, id: null });
  }, [isBillSummaryDeleteSuccess, isBillSummaryDeleteError]);

  useEffect(() => {
    if (singleBillSummary && isBillSummaryPrint) {
      handlePrint();
    }
  }, [singleBillSummary, isBillSummaryPrint]);

  const cols = React.useMemo(
    () => [
      {
        Header: 'Job no.',
        accessor: 'jobNo',
      },
      {
        Header: 'Work title',
        accessor: 'workTitle',
      },
      {
        Header: 'Total Approved Claims',
        accessor: 'totalApprovedClaims',
      },
      {
        Header: 'Created date',
        Cell: ({ row }) => {
          return <span>{moment(row?.original?.createdDate).format('MMMM DD, YYYY hh:mm a')}</span>;
        },
      },
      {
        Header: 'Created by',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original.createdBy?.first_name} {row?.original.createdBy?.last_name}
            </span>
          );
        },
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          return (
            <button
              style={{
                padding: '4px 8px',
                borderRadius: '4px',
                width: '80px',
                backgroundColor: row?.original?.status === 'draft' ? '#72747829' : '#4472B829',
                color: row?.original?.status === 'draft' ? '#727478' : '#4472B8',
              }}
              type="button"
            >
              {row?.original?.status === 'draft' ? 'Draft' : 'Published'}
            </button>
          );
        },
      },
      {
        id: 'actions',
        Header: 'Actions',
        Cell: ({ row }) => {
          return (
            <>
              {/* <button type='button' className='new-btn' onClick={() => handleClickCopy(row?.original?.id)}> <CopyIcon /> </button> */}
              {row?.original?.status === 'draft' ? (
                <button
                  onClick={() => handleClickEdit(row?.original?.id)}
                  title="Edit"
                  type="button"
                  className="new-btn"
                >
                  {' '}
                  <EditIcon />{' '}
                </button>
              ) : (
                <button
                  type="button"
                  className="new-btn"
                  title="Download"
                  onClick={() => handleClickDownload(row?.original?.id)}
                >
                  {' '}
                  <IconDownload />{' '}
                </button>
              )}
              <button
                type="button"
                className="new-btn"
                title="Delete"
                onClick={() => handleClickDelete(row?.original?.id)}
              >
                {' '}
                <DeleteIcon />{' '}
              </button>
            </>
          );
        },
      },
    ],
    [summaryBillData],
  );

  const getDataRows = async () => {
    const filters = {
      rowPerPage: BOSRowPerPage,
      currentPage: BOSCurrentPage,
      searchValue: BOSSearchValue,
      startDate: BOSDateFilterState?.find((item) => item.startDate)?.startDate
        ? moment(BOSDateFilterState?.find((item) => item.startDate)?.startDate).format('YYYY-MM-DD')
        : '',
      endDate: BOSDateFilterState?.find((item) => item.startDate)?.startDate
        ? moment(BOSDateFilterState?.find((item) => item.endDate)?.endDate).format('YYYY-MM-DD')
        : '',
      status: BOSStatus,
    };
    await dispatch(getBillsSummary(filters));
    setIsLoading(false);
  };

  useEffect(() => {
    getDataRows();
  }, [BOSSearchValue, BOSRowPerPage, BOSCurrentPage]);

  const modifiedData = useMemo(
    () =>
      BOSData?.results?.length > 0
        ? BOSData?.results?.map((item) => {
            return {
              id: item?.id,
              jobNo: item?.job_num,
              workTitle: item?.work_title,
              status: item?.status,
              createdDate: item?.created_at,
              createdBy: item?.created_by,
              totalApprovedClaims: `RM${parseFloat(item?.total_approved_claims).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            };
          })
        : [],
    [BOSData],
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <EpqTable
        iSShowFilter={iSShowFilter}
        setISShowFilter={setISShowFilter}
        getSelectedRowwValues={getSelectedRowwValues}
        data={modifiedData}
        columns={cols}
        getDataRows={getDataRows}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Pagination
        totalRows={BOSData?.count || 0}
        rowPerPage={BOSRowPerPage}
        pageChangeHandler={setBOSCurrentPage}
        changeRowsPerPage={setBOSRowPerPage}
        currentPage={BOSCurrentPage}
      />
      {isShowPopUp.flag && (
        <NewModal
          showModal={isShowPopUp.flag}
          handleClose={() => setIsShowPopUp({ flag: false, type: null, id: null })}
          handleCreateSave={handlePopupAction}
          primaryButtonText={isShowPopUp.type === 'delete' ? 'Delete' : 'Duplicate'}
          modalTitle={`${isShowPopUp.type === 'delete' ? 'Delete' : 'Duplicate'} this item?`}
        >
          This item will be {isShowPopUp.type === 'delete' ? 'permanently deleted' : 'duplicated'}.
        </NewModal>
      )}
      <EbqSummaryPdf ref={componentRef} pdf={pdf} />
    </>
  );
}

export default BOS;
