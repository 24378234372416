import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tnbLogo from '../../assets/img/tnbLogo.png';
import WorkDataPdf from './workDataPdf';
import SummaryBillsItems from './summaryBillsItems';
import SummaryBillSummary from './summaryBillSummary';
import ArrowRightIcon from '../../assets/svg-icons/ArrowRightIcon';
import { setSORTab } from '../../redux/features/SOR/SORSlice';

const EbqSummaryPdf = forwardRef((props, ref) => {
  const { summaryBillWorkData, singleBillSummary } = useSelector((state) => state.SOR);
  const dispatch = useDispatch();
  return (
    <div
      className="card-body"
      style={{ display: props.isPreview ? '' : 'none', paddingTop: '20px' }}
      ref={ref}
    >
      {props.isPreview && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
            marginLeft: '10px',
          }}
        >
          <button type="button">
            <Link to="/ebq" style={{ color: '#727478' }} onClick={() => dispatch(setSORTab('bos'))}>
              Bill summary
            </Link>
          </button>
          <ArrowRightIcon />
          <button type="button" style={{ color: '#36383C', fontWeight: '600', cursor:"text" }}>
            {/* <Link to="/ebq"  onClick={() => dispatch(setSORTab('bos'))}> */}
              {singleBillSummary?.status === 'draft' ? 'Draft Preview' : 'Preview'}
            {/* </Link> */}
          </button>
        </div>
      )}
      <div className="epq-pdf-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 className="ml-2 mb-0">Summary bill of quantity</h3>
        </div>
        <img src={tnbLogo} alt="TNB logo" />
      </div>
      <WorkDataPdf summary />
      <hr />
      <div className="epq-large-header-text">
        <h3>{summaryBillWorkData?.title}</h3>
        <p>{summaryBillWorkData?.description}</p>
      </div>
      <SummaryBillsItems pdf={props.pdf} />
      <hr />
      <SummaryBillSummary pdf={props.pdf} />
    </div>
  );
});

export default EbqSummaryPdf;
