/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */

import React from 'react';
import {  useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classes from '../assetsPage/assestsPage.module.css';
import BillIcon from '../../assets/svg-icons/BillIcon';



function epqHeader() {
    const { billData, isPrint, summaryBillData, isBillSummaryPrint } = useSelector(state => state.SOR)

    return (
        <div className={classes.assetsTop}>
            <h4 className={classes.assetsTitle}>e-Bill of Quantity</h4>
        </div>
    );
}

export default epqHeader;
