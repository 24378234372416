import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import MinusIcon from '../../assets/svg-icons/MinusIcon';
import { setBillData, setBillQty } from '../../redux/features/SOR/SORSlice';
import DeleteIcon from '../../assets/svg-icons/DeleteIcon';
import Tooltip from '../../components/tooltip/tooltip';

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */

function BillItem({ item, pdf, index }) {
  const { billData } = useSelector((state) => state.SOR);
  const dispatch = useDispatch();

  const handleQty = (type, typeOfOrder) => {
    dispatch(setBillQty({ type, item, typeOfOrder }));
  };
  const handleInputQTYChange = (e, typeOfOrder) => {
    dispatch(setBillQty({ type: 'QTYInput', qty: e.target.value, item, typeOfOrder }));
  };
  const handleInputDiscountChange = (e) => {
    dispatch(setBillQty({ type: 'DiscountInput', dis: e.target.value, item }));
  };
  const remarkChange = (e) => {
    // dispatch(setBillQty({ type: "remarkChange", remarkText: e.target.value, item }))
    const updatedBillData = billData.map((ele, i) => {
      if (index === i) {
        return { ...ele, remarkText: e.target.value };
      }
      return ele;
    });
    dispatch(setBillData(updatedBillData));
  };

  const deleteItemHandler = () => {
    dispatch(dispatch(setBillData(billData.filter((_, i) => i !== index))));
  };

  return (
    <tr>
      <td>{item?.source}</td>
      <td style={{ fontSize: '12px' }}>
        {`${item?.id_1 || ""}${item?.id_2? '/' : ''}${item?.id_2 || ""}${item?.id_3? '/' : ''}${item?.id_3 || ""}`}<br />
        {item?.description_1} <br />
        {item?.description_2} <br />
        {item?.description_3}
      </td>
      <td>
        {item?.discount === 'Y' ? (
          !pdf ? (
            <input
              type="number"
              className="bill-item-discount"
              onChange={handleInputDiscountChange}
              value={item.discountRate}
            />
          ) : (
            <span>{item?.discountRate}%</span>
          )
        ) : !pdf ? (
          <Tooltip input content="This field can't be edited" />
        ) : (
          <span>0%</span>
        )}
      </td>
      <td>{item?.unit}</td>
      <td>RM{(+item?.rate).toFixed(2)?.toLocaleString()}</td>
      <td>
        <div style={!pdf ? { display: 'grid', gridTemplateColumns: '50px 41px 58px' } : {}}>
          {!pdf && (
            <button
              type="button"
              className="new-btn"
              style={{ height: '36px' }}
              onClick={() => handleQty('dec', 'client')}
            >
              <MinusIcon />
            </button>
          )}
          {!pdf ? (
            <input
              type="number"
              className="bill-item-discount"
              onChange={(e) => handleInputQTYChange(e, 'client')}
              value={item?.quantity}
              min="1"
            />
          ) : (
            <span>{item?.quantity}</span>
          )}
          {!pdf && (
            <button
              type="button"
              className="new-btn ml-2"
              onClick={() => handleQty('inc', 'client')}
            >
              <PlusIcon />
            </button>
          )}
        </div>
      </td>
      <td>RM{parseFloat(item?.totalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>

      <td>
        <div style={!pdf ? { display: 'grid', gridTemplateColumns: '50px 41px 58px' } : {}}>
          {!pdf && (
            <button
              type="button"
              className="new-btn"
              style={{ height: '36px' }}
              onClick={() => handleQty('dec', 'vendor')}
            >
              <MinusIcon />
            </button>
          )}
          {!pdf ? (
            <input
              type="number"
              className="bill-item-discount"
              onChange={(e) => handleInputQTYChange(e, 'vendor')}
              value={item?.vendorQuantity}
              min="1"
            />
          ) : (
            <span>{item?.vendorQuantity}</span>
          )}
          {!pdf && (
            <button
              type="button"
              className="new-btn ml-2"
              onClick={() => handleQty('inc', 'vendor')}
            >
              <PlusIcon />
            </button>
          )}
        </div>
      </td>
      <td>RM{parseFloat(item?.vendorTotalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
      <td>
        {!pdf ? (
          <input
            type="text"
            className="bill-item-discount"
            style={{ width: '150px' }}
            placeholder="Write something"
            value={item.remarkText}
            onChange={remarkChange}
          />
        ) : (
          <span>{item.remarkText}</span>
        )}
      </td>
      <td>
        {!pdf && (
          <IconButton
            style={{
              backgroundColor: '#F8F9F9',
              borderRadius: '8px',
              border: '1px solid #E1E1E1',
              padding: '9px',
            }}
            variant="contained"
            title="Delete item"
            className="outlined-button mr-2"
            onClick={() => deleteItemHandler()}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </td>
    </tr>
  );
}

export default BillItem;
