/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import classes from '../assetsPage/assestsPage.module.css';
import LocationHeader from '../../components/location/LocationHeader';
import {
  // getLocationsByAuthentication,
  closeSiteModeul,
  setLocationsReset,
  getLocationByPagination,
  closeAddFloor,
  setSearchBar,
  closeLocationModalUpdate,
  closeSubLocationModalUpdate,
  setRowPerPage,
  setCurrentPage,
  setLocationSearcValue,
  reset,
} from '../../redux/features/location/locationSlice';
import AddALocation from '../../components/location/addLocation';
// import SiteLocation from '../../components/location/siteLocation';
import './location.scss';
import LocationTable from '../../components/location/LocationTable';
import Pagination from '../../components/pagination/pagination';
import SiteLocation from '../../components/location/siteLocation';
import AddFloor from '../../components/location/addFloor';
import UpdateLocationPage from '../../components/location/UpdateLocationPage';
import Loader from '../../components/loader/loader';

export default function Locations() {
  const { user } = useSelector(state => state.user);
  // const [showSearchBar, setSearchBar] = useState(true);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [locationsData, setLocations] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdateLocation, setShowUpdateLocation] = useState(false);

  const { showSiteModule, showFloorAdd, locationsCount, showSearchBar, pathValue, boolValue, locationSearcValue, currentPage, rowPerPage } = useSelector(state => state.location);

  useEffect(() => {
    if (pathValue === "locations") {
      setShow(false)
      setShowAdd(false)
      setShowUpdateLocation(false)
      dispatch(closeSiteModeul());
      dispatch(closeLocationModalUpdate());
      dispatch(closeAddFloor());
      dispatch(setSearchBar(true));
      dispatch(closeSubLocationModalUpdate());
    }
  }, [pathValue, boolValue])


  // useEffect(() => {
  //   setLocationsReset([]);
  //   dispatch(getLocationByPagination());
  // }, [dispatch]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);
  // const [locationSearcValue, setLocationSearcValue] = useState('');
  // const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setShow(false)
    setShowAdd(false)
    setShowUpdateLocation(false)
    dispatch(setSearchBar(true))
    dispatch(closeSiteModeul())
    dispatch(closeAddFloor())
  }, [])

  useEffect(() => {
    setLocationsReset([]);
    const getDataRows = async () => {
      await dispatch(getLocationByPagination({ rowPerPage, currentPage, searchValue: locationSearcValue }));
    };
    getDataRows();
  }, [rowPerPage, currentPage, locationSearcValue]);

  const { 
    locations, 
    location,
    isLocationImportedError,
    isLocationImported,
    isLocationImportedLoading
  } = useSelector(state => state.location);

  useEffect(() => {
    setLocations(locations);
  }, [locations, showSiteModule]);

  // select the value of each location that are needed
  const modifiedData = locationsData.map(loc => {
    return {
      id: loc?.id || '-',
      site: loc?.site || '-',
      tenant: loc?.management?.tenant || '-',
      owner: loc?.management?.owner || '-',
      category: loc?.management?.category || '-',
      duration: `${loc?.management?.start_rent_date?.split('T')[0] || '-'} ${loc?.management?.start_rent_date ? 'to' : ''
        } ${loc?.management?.end_rent_date?.split('T')[0] || ''}`,
    };
  });
  useEffect(() => {
    if (isLocationImportedError) {
      toast.error('Something went wrong while Importing Location', {
        toastId: 'DeletedLocationError',
      });
      dispatch(reset());
    } else if (isLocationImported) {
      toast.success('Location Imported successfully', {
        toastId: 'Location Imported Success',
      });
      dispatch(reset());
    }
  }, [isLocationImportedError, isLocationImported])


  return (
    <div className={classes.assets}>
      <LocationHeader
        showSearchBar={showSearchBar}
        setSearchBar={setSearchBar}
        stylesHow={show}
        handlehow={() => setShow(!show)}
        data={user}
        showAdd={() => setShowAdd(!showAdd)}
        closeAdd={() => setShowAdd(false)}
        locations={locations}
        myLocation={location}
        setSearchValue={setLocationSearcValue}
        locationSearcValue={locationSearcValue}
      />
      {
        showFloorAdd && <AddFloor id={location?.id} />
      }
      <AddALocation show={showAdd} showAdd={() => setShowAdd(false)} setSearchBar={setSearchBar} />
      <UpdateLocationPage show={showUpdateLocation} closeUpdateLocation={() => setShowUpdateLocation(false)} />

      {/* <!-- Site Details --> */}
      <div style={{ display: showSiteModule ? '' : 'none' }}>
        <SiteLocation
          openUpdateLocation={() => setShowUpdateLocation(true)}
          setSearchBar={setSearchBar}
          show={showSiteModule}
          close={() => {
            dispatch(closeSiteModeul());
          }}
        />
      </div>
      {!showSiteModule && !showAdd && !showFloorAdd && !showUpdateLocation && (
        isLocationImportedLoading ? 
        <Loader /> :
        <>
          <LocationTable
            setSearchBar={setSearchBar}
            handlehow={() => setShow(!show)}
            closeAdd={() => setShowAdd(false)}
            isShowAdd={setShowAdd}
            locations={modifiedData}
          />
          <Pagination
            totalRows={locationsCount}
            rowPerPage={rowPerPage}
            pageChangeHandler={setCurrentPage}
            changeRowsPerPage={setRowPerPage}
            currentPage={currentPage}
          />
        </>
      )}

      {/* {!showSiteModule && !showAdd && !showFloorAdd && (
        <div id="empty-message" className="pt-5" style={{ height: '400px', display: 'block' }}>
          <center className="my-auto">
            Select a Site or{' '}
            <button
              type="button"
              onClick={() => {
                setShowAdd(true);
                dispatch(closeSiteModeul());
              }}
              style={{ color: 'red' }}
            >
              Add Site
            </button>{' '}
          </center>
        </div>
      )} */}
    </div>
  );
}
