import React from 'react'
import "./epq.css"
import { useSelector } from 'react-redux'
/* eslint-disable no-unsafe-optional-chaining */

export default function SummaryBillSummary({ pdf }) {
    const { summarybillTotalPrice } = useSelector(state => state.SOR)
    return (
        <div style={pdf ? { breakInside: 'avoid' } : {}}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h5 className='mb-0'>Summary</h5>
            </div >
            <table className='bills-table' style={{ width: "100%", padding: "16px" }}>
                <thead>
                    <tr>
                        <td >
                            Total of purchase order (RM)/ <br />
                            <span style={{ color: "#727478" }}>Jumlah keseluruhan butiran sebut harga (RM)</span>
                        </td>
                        <td >
                            Total of approved claims (RM)/<br />
                            <span style={{ color: "#727478" }}>Jumlah keseluruhan tuntutan diperakukan (RM)</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ padding: "20px 10px" }}>
                            RM{summarybillTotalPrice?.summaryBillTotalOrder?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                        <td style={{ padding: "20px 10px" }}>
                            RM{summarybillTotalPrice?.summaryBillTotalVendor?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
