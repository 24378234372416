/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../assetsPage/assestsPage.module.css';
import EpqHeader from './epqHeader';
import Loader from '../../components/loader/loader';
import {
  getSORSoucrcesTardes,
  getSORSummary,
  reset,
  resetBillSummaryState,
  resetState,
  setBillData,
  setSingleBill,
} from '../../redux/features/SOR/SORSlice';
import EpqTabs from './epqTabs';
import SOR from './Pages/SOR';
import BOQ from './Pages/BOQ';
import SORHistory from './Pages/SORHistory';
import BOS from './Pages/BOS';
import FooterButton from './FooterButton';

/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */

export default function ScheduleRate() {
  const dispatch = useDispatch();
  const {
    SORTab,
    isSORImportedLoading,
    isSORImported,
    isSORImportedError,
    isSORGetLoading,
    isBillsGetLoading,
    singleBill,
    singleBillSummary,
    billData,
  } = useSelector((state) => state.SOR);

  function displayToast() {
    if (isSORImportedError) {
      toast.error('Something went wrong while Importing SOR', {
        toastId: 'DeletedSORError',
      });
      dispatch(reset());
    } else if (isSORImported) {
      toast.success(
        'Your data import is being processed. You can safely proceed with other tasks while the system completes the import.',
        {
          toastId: 'integrationSyncStarted',
        },
      );
      dispatch(reset());
    }
  }

  useEffect(() => {
    dispatch(getSORSoucrcesTardes());
    dispatch(getSORSummary());
    dispatch(setSingleBill(null));
    if (singleBill) {
      dispatch(resetState());
    }
    if (singleBillSummary) {
      dispatch(resetBillSummaryState());
    }
  }, []);


  return (isSORImportedLoading) ? (
    <Loader />
  ) : (
    <div>
      {displayToast()}
      <div className={`${classes.assets} mb-2`}>
        <EpqHeader SORTab={SORTab} />
        <EpqTabs />
        {SORTab === 'sor' && <SOR billData={billData} setBillData={setBillData} />}
        {SORTab === 'boq' && <BOQ />}
        {SORTab === 'bos' && <BOS />}
        {SORTab === 'history' && <SORHistory />}
      </div>
      {(SORTab === 'sor' || SORTab === 'boq') && ((!isSORGetLoading && !isBillsGetLoading) && <FooterButton />)}
    </div>
  );
}
