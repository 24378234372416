import React from 'react'
import {  useSelector } from 'react-redux'

function WorkDataPdf({summary}) {
    const { billWorkData, summaryBillWorkData } = useSelector(state => state.SOR)    
    return (
        summary ? 
        <section className='work-section-pdf'>
            <div className='work-content-epq'>
                <div className='work-title-epq'>Technician name</div>
                <div className='work-data-epq'>{summaryBillWorkData?.technicianName}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>Contractor name</div>
                <div className='work-data-epq'>{summaryBillWorkData?.contractorName}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>Vendor no</div>
                <div className='work-data-epq'>{summaryBillWorkData?.noVendor}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>Period</div>
                <div className='work-data-epq'>{summaryBillWorkData?.startDate && summaryBillWorkData?.endDate &&  `${summaryBillWorkData?.startDate}-${summaryBillWorkData?.endDate}`}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>Job no</div>
                <div className='work-data-epq'>{summaryBillWorkData?.jobNo}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>Purchase order number</div>
                <div className='work-data-epq'>{summaryBillWorkData?.purchaseOrderNumber}</div>
            </div>
        </section>
        :
        <section className='work-section-pdf'>
            <div className='work-content-epq'>
                <div className='work-title-epq'>WORK ORDER (CMMS)</div>
                <div className='work-data-epq'>{billWorkData?.order}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>JOB NO</div>
                <div className='work-data-epq'>{billWorkData?.jobNum}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>LOCATION</div>
                <div className='work-data-epq'>{billWorkData?.location}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>OUTLINE AGREEMENT NO</div>
                <div className='work-data-epq'>{billWorkData?.agreementNo}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>WORK ORDER TYPE</div>
                <div className='work-data-epq'>{billWorkData?.orderType === "sla" ? "SLA" : "NON-SLA"}</div>
            </div>
        </section>
    )
}

export default WorkDataPdf