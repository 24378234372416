/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getInventoryServiceById } from '../../redux/features/inventory/inventorySlice';
import foxLogo3 from '../../assets/img/FOX-Logo3.png';
import './QrCodeResult.scss';

const QrCodeResult = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.company);
  const { inventory } = useSelector(state => state.inventory);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get('uuid').split('/')[0];
  useEffect(() => {
    dispatch(getInventoryServiceById(uuid));
  }, [uuid]);
  return (
    <>
      <div className="row">
        {/* <div
          className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 ml-4 mt-2"
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {profile?.logo ? (
            <img src={profile?.logo} height="130" width="250" alt="" />
          ) : (
            <img src={foxLogo3} alt="company logo" height="130" width="250" />
          )}
        </div> */}
        <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 mt-3 ml-2 text-center">
          <h1>{inventory?.company_name}</h1>
        </div>
      </div>
      <div className="mt-5 ml-3 mr-3">
        <center>
          <div className="card col-lg-7 col-sm-12 col-xs-12 col-md-7 ">
            <div className="card-header medium-title text-center">
              <span>
                <b>Inventory details</b>
              </span>
            </div>
            <div className="card-body">
              <div className="row medium-title">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 text-left">
                  <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Name:</label>
                  <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">{inventory?.name}</span>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 text-left">
                  <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Inventory ID:</label>
                  <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                    {inventory?.inventory_id}
                  </span>
                </div>
                {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6 fw-bold">
                    Description:
                  </label>
                  <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                    {inventory?.description}
                  </span>
                </div> */}
                {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6 fw-bold">Type:</label>
                  <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">{inventory?.type}</span>
                </div> */}
              </div>
            </div>
          </div>
        </center>
      </div>
    </>
  );
};

export default QrCodeResult;
