/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function FooterButton() {
  const { SORTab, billData, summaryBillData } = useSelector((state) => state.SOR);
  return (
    <div className="ebq-footer-button-container">
      <div className="ebq-footer-content">
        <div>
          <span style={{ color: '#727478' }}>Items selected</span> <br />{' '}
          <span>
            {SORTab === 'sor'
              ? billData?.length > 0
                ? billData?.length
                : '—'
              : summaryBillData?.length > 0
              ? summaryBillData?.length
              : '—'}
          </span>
        </div>
        <Link to={SORTab === 'sor' ? '/ebq/bill' : '/ebq/bill-summary'}>
          <button
            type="button"
            className="new-btn primary-btn"
            disabled={SORTab === 'sor' ? billData?.length === 0 : summaryBillData?.length === 0}
          >
            {SORTab === 'sor' ? 'Create bill of quantity' : 'Create bill summary'}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default FooterButton;
