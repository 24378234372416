import React from "react";

export default function SectionTitle({ title }) {
  return (
    <p
      style={{
        fontSize: '21px',
        fontWeight: '600',
        lineHeight: '32px',
      }}
    >
      {title}
    </p>
  );
}
