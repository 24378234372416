import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import workOrderServiceRequestService from './workOrderServiceRequestService';
import { smartTryCatch } from '../../../utils';

const initState = {
  workOrderServiceRequests: [],
  workOrderServiceRequest: {},

  // fields
  workOrderServiceRequestName: '',

  // modal
  workOrderServiceRequestModalCreate: false,
  workOrderServiceRequestModalUpdate: false,
  workOrderServiceRequestModalDelete: false,

  // success
  isWorkOrderServiceRequestCreateSuccess: false,
  isWorkOrderServiceRequestGetSuccess: false,
  isWorkOrderServiceRequestDeleteSuccess: false,
  isWorkOrderServiceRequestUpdateSuccess: false,

  // loading
  isWorkOrderServiceRequestCreateLoading: false,
  isWorkOrderServiceRequestGetLoading: false,
  isWorkOrderServiceRequestDeleteLoading: false,
  isWorkOrderServiceRequestUpdateLoading: false,

  // error
  isWorkOrderServiceRequestCreateError: false,
  isWorkOrderServiceRequestGetError: false,
  isWorkOrderServiceRequestDeleteError: false,
  isWorkOrderServiceRequestUpdateError: false,
};

export const createWorkOrderServiceRequests = createAsyncThunk(
  'createWorkOrderServiceRequests',
  async (data, thunkAPI) => {
    const createWO = await smartTryCatch(
      workOrderServiceRequestService.createWorkOrderServiceRequest,
      data,
      thunkAPI,
    );
    return createWO;
  },
);

export const getWorkOrderServiceRequestByAuthentication = createAsyncThunk(
  'getWorkOrderServiceRequestByAuthentication',
  async (data, thunkAPI) => {
    const getWo = await smartTryCatch(
      workOrderServiceRequestService.getWorkOrderServiceRequestByAuthentication,
      data,
      thunkAPI,
    );
    return getWo;
  },
);

export const getWorkOrderServiceRequestsById = createAsyncThunk(
  'getWorkOrderServiceRequestsById',
  async (data, thunkAPI) => {
    const getWo = await smartTryCatch(
      workOrderServiceRequestService.getWorkOrderServiceRequestById,
      data,
      thunkAPI,
    );
    return getWo;
  },
);

export const deleteWorkOrderServiceRequests = createAsyncThunk(
  'deleteWorkOrderServiceRequests',
  async (data, thunkAPI) => {
    const deleteWo = await smartTryCatch(
      workOrderServiceRequestService.deleteWorkOrderServiceRequest,
      data,
      thunkAPI,
    );
    return deleteWo;
  },
);

export const updateWorkOrderServiceRequests = createAsyncThunk(
  'updateWorkOrderServiceRequests',
  async (data, thunkAPI) => {
    const update = await smartTryCatch(
      workOrderServiceRequestService.updateWorkOrderServiceRequest,
      data,
      thunkAPI,
    );
    return update;
  },
);

export const workOrderServiceRequestSlice = createSlice({
  name: 'workOrderServiceRequest',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isWorkOrderServiceRequestCreateError = false;
      state.isWorkOrderServiceRequestCreateSuccess = false;
      state.isWorkOrderServiceRequestCreateLoading = false;
      state.isWorkOrderServiceRequestGetError = false;
      state.isWorkOrderServiceRequestGetSuccess = false;
      state.isWorkOrderServiceRequestGetLoading = false;
      state.isWorkOrderServiceRequestDeleteError = false;
      state.isWorkOrderServiceRequestDeleteSuccess = false;
      state.isWorkOrderServiceRequestDeleteLoading = false;
      state.isWorkOrderServiceRequestUpdateError = false;
      state.isWorkOrderServiceRequestUpdateSuccess = false;
      state.isWorkOrderServiceRequestUpdateLoading = false;
      // state.workOrderServiceRequest = null;
    },
    openWorkOrderServiceRequestModalCreate: (state) => {
      state.workOrderServiceRequestModalCreate = true;
    },
    closeWorkOrderServiceRequestModalCreate: (state) => {
      state.workOrderServiceRequestModalCreate = false;
    },
    openWorkOrderServiceRequestModalUpdate: (state) => {
      state.workOrderServiceRequestModalUpdate = true;
    },
    closeWorkOrderServiceRequestModalUpdate: (state) => {
      state.workOrderServiceRequestModalUpdate = false;
    },
    openWorkOrderServiceRequestModalDelete: (state) => {
      state.workOrderServiceRequestModalDelete = true;
    },
    closeWorkOrderServiceRequestModalDelete: (state) => {
      state.workOrderServiceRequestModalDelete = false;
    },
    setWorkOrderServiceRequest: (state, action) => {
      state.workOrderServiceRequest = action.payload;
    },
    setWorkOrderServiceRequestName: (state, action) => {
      state.workOrderServiceRequestName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkOrderServiceRequestByAuthentication.pending, (state) => {
        state.isWorkOrderServiceRequestGetLoading = true;
        state.isWorkOrderServiceRequestGetSuccess = false;
        state.isWorkOrderServiceRequestGetError = false;
      })
      .addCase(getWorkOrderServiceRequestByAuthentication.fulfilled, (state, action) => {
        state.isWorkOrderServiceRequestGetLoading = false;
        state.isWorkOrderServiceRequestGetSuccess = true;
        state.isWorkOrderServiceRequestGetError = false;
        state.workOrderServiceRequests=action.payload;
      })
      .addCase(getWorkOrderServiceRequestByAuthentication.rejected, (state) => {
        state.isWorkOrderServiceRequestGetLoading = false;
        state.isWorkOrderServiceRequestGetSuccess = false;
        state.isWorkOrderServiceRequestGetError = true;
      })
      .addCase(getWorkOrderServiceRequestsById.pending, (state) => {
        state.isWorkOrderServiceRequestGetLoading = true;
        state.isWorkOrderServiceRequestGetSuccess = false;
        state.isWorkOrderServiceRequestGetError = false;
      })
      .addCase(getWorkOrderServiceRequestsById.fulfilled, (state, action) => {
        state.isWorkOrderServiceRequestGetLoading = false;
        state.isWorkOrderServiceRequestGetSuccess = true;
        state.isWorkOrderServiceRequestGetError = false;
        state.workOrderServiceRequest = action.payload;
      })
      .addCase(getWorkOrderServiceRequestsById.rejected, (state) => {
        state.isWorkOrderServiceRequestGetLoading = false;
        state.isWorkOrderServiceRequestGetSuccess = false;
        state.isWorkOrderServiceRequestGetError = true;
      })
      .addCase(createWorkOrderServiceRequests.pending, (state) => {
        state.isWorkOrderServiceRequestCreateLoading = true;
        state.isWorkOrderServiceRequestCreateSuccess = false;
        state.isWorkOrderServiceRequestCreateError = false;
      })
      .addCase(createWorkOrderServiceRequests.fulfilled, (state, action) => {
        state.isWorkOrderServiceRequestCreateLoading = false;
        state.isWorkOrderServiceRequestCreateSuccess = true;
        state.isWorkOrderServiceRequestCreateError = false;
        state.workOrderServiceRequests.push(action.payload);
      })
      .addCase(createWorkOrderServiceRequests.rejected, (state) => {
        state.isWorkOrderServiceRequestCreateLoading = false;
        state.isWorkOrderServiceRequestCreateSuccess = false;
        state.isWorkOrderServiceRequestCreateError = true;
      })
      .addCase(deleteWorkOrderServiceRequests.pending, (state) => {
        state.isWorkOrderServiceRequestDeleteLoading = true;
        state.isWorkOrderServiceRequestDeleteSuccess = false;
        state.isWorkOrderServiceRequestDeleteError = false;
      })
      .addCase(deleteWorkOrderServiceRequests.fulfilled, (state, action) => {
        state.isWorkOrderServiceRequestDeleteLoading = false;
        state.isWorkOrderServiceRequestDeleteSuccess = true;
        state.isWorkOrderServiceRequestDeleteError = false;
        // state.workOrderServiceRequests = action.payload;
        state.workOrderServiceRequests = state.workOrderServiceRequests.filter(
          (workOrderServiceRequest) => workOrderServiceRequest.id !== action.payload,
        );
      })
      .addCase(deleteWorkOrderServiceRequests.rejected, (state) => {
        state.isWorkOrderServiceRequestDeleteLoading = false;
        state.isWorkOrderServiceRequestDeleteSuccess = false;
        state.isWorkOrderServiceRequestDeleteError = true;
      })
      .addCase(updateWorkOrderServiceRequests.pending, (state) => {
        state.isWorkOrderServiceRequestUpdateLoading = true;
        state.isWorkOrderServiceRequestUpdateSuccess = false;
        state.isWorkOrderServiceRequestUpdateError = false;
      })
      .addCase(updateWorkOrderServiceRequests.fulfilled, (state, action) => {
        state.isWorkOrderServiceRequestUpdateLoading = false;
        state.isWorkOrderServiceRequestUpdateSuccess = true;
        state.isWorkOrderServiceRequestUpdateError = false;
        state.workOrderServiceRequests = state.workOrderServiceRequests.map((workOrderServiceRequest) =>
        workOrderServiceRequest.id === action.payload.id ? action.payload : workOrderServiceRequest,
      );
      })
      .addCase(updateWorkOrderServiceRequests.rejected, (state) => {
        state.isWorkOrderServiceRequestUpdateLoading = false;
        state.isWorkOrderServiceRequestUpdateSuccess = false;
        state.isWorkOrderServiceRequestUpdateError = true;
      });
  },
});

export const {
  reset,
  openWorkOrderServiceRequestModalCreate,
  closeWorkOrderServiceRequestModalCreate,
  openWorkOrderServiceRequestModalUpdate,
  closeWorkOrderServiceRequestModalUpdate,
  openWorkOrderServiceRequestModalDelete,
  closeWorkOrderServiceRequestModalDelete,
  setWorkOrderServiceRequest,
  setWorkOrderServiceRequestName,
} = workOrderServiceRequestSlice.actions;

export default workOrderServiceRequestSlice.reducer;
