/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

import React, { useState } from "react";
import IconInformation from "./icons/IconInformation";
import TrendWOChart from "./TrendWOChart";
import Tooltip from "./Tooltip";

export default function TrendWO({ trendWorkData, intervals }) {
  return (
    <div
      style={{
        marginTop: "12px",
        // backgroundColor: "#F8F9F9",
        backgroundColor: "white",
        border: '1px solid #919EAB29',
        borderRadius: "12px",
        padding: '15px 15px 28px',
        paddingTop: "20px",
        boxShadow: "0px 1px 2px 0px #919EAB29",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Tooltip icon={<IconInformation color="#727478" />}>
          <p style={{ fontSize: "12px", color: "#727478", marginBottom: "0px" }}>
          Data trend for WO Open and Completed. It give insight to operational efficiency and workload management.
          </p>
        </Tooltip>
      </div>
      <div style={{ marginLeft: "24px" }}>
        <div style={{ fontWeight: "600", fontSize: "18px", color: "#36383C" }}>
          Trend of Work Orders
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{ fontWeight: "400", fontSize: "14px", color: "#757980" }}
          >
            Showing the work order request & compilation
          </div>
          <div style={{ display: "flex", gap: "16px", marginRight: "24px" }}>
            <div>
              <Dot color="#4472B8A3" />
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  color: "#4A4C50",
                }}
              >
                Work order open
              </span>
            </div>
            <div>
              <Dot color="#20BB7DA3" />
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  color: "#4A4C50",
                }}
              >
                Work order Completed
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TrendWOChart
          trendWorkData={trendWorkData}
          intervals={intervals}
        />
      </div>
    </div>
  );
}

function Dot({ color }) {
  return (
    <>
      <span
        style={{
          backgroundColor: color ?? "black",
          width: "9px",
          height: "9px",
          borderRadius: "50%",
          display: "inline-block",
          marginRight: "6px",
        }}
      ></span>{" "}
    </>
  );
}
