import React from 'react'

function EllipsisIcon() {
    return (
        <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.74986 1.75H7.24986M6.74986 2.25H7.24986M2.08319 1.75H2.58319M2.08319 2.25H2.58319M11.4165 1.75H11.9165M11.4165 2.25H11.9165M7.66669 2.00004C7.66669 2.36823 7.36821 2.66671 7.00002 2.66671C6.63183 2.66671 6.33335 2.36823 6.33335 2.00004C6.33335 1.63185 6.63183 1.33337 7.00002 1.33337C7.36821 1.33337 7.66669 1.63185 7.66669 2.00004ZM3.00002 2.00004C3.00002 2.36823 2.70154 2.66671 2.33335 2.66671C1.96516 2.66671 1.66669 2.36823 1.66669 2.00004C1.66669 1.63185 1.96516 1.33337 2.33335 1.33337C2.70154 1.33337 3.00002 1.63185 3.00002 2.00004ZM12.3334 2.00004C12.3334 2.36823 12.0349 2.66671 11.6667 2.66671C11.2985 2.66671 11 2.36823 11 2.00004C11 1.63185 11.2985 1.33337 11.6667 1.33337C12.0349 1.33337 12.3334 1.63185 12.3334 2.00004Z"
                stroke="#36383C"
                strokeWidth="1.75"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default EllipsisIcon