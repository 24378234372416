/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./epq.css"
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import { setFilterWords, setIsBookmarked, setOpenAddItemsModal, setSearchValue, setSource, setSummaryBillTotalPrice, setTrade } from '../../redux/features/SOR/SORSlice';
import Tooltip from '../../components/tooltip/tooltip';
import AddBillItemsModal from './addBillItemsModal';
import SummaryBillsItem from './summaryBillsItem';


export default function SummaryBillsItems({ pdf }) {
    const dispatch = useDispatch()
    const { openAddItemsModal, summaryBillData, summarybillTotalPrice } = useSelector(state => state.SOR)

    const handleEraserFilterClick = () => {
        dispatch(setSource(""))
        dispatch(setFilterWords({
            all: "",
            exact: "",
            any: "",
            none: "",
        }))
        dispatch(setIsBookmarked(false))
        dispatch(setTrade(""))
        dispatch(setSearchValue(""))
    }

    useEffect(() => {
        // Calculate total
        const totalSum = summaryBillData?.reduce((sum, item) => {
            return sum + parseFloat(item.grand_total_first);
        }, 0);

        // Calculate total
        const SumVendorTotalPrice = summaryBillData?.reduce((sum, item) => {
            return sum + parseFloat(item.grand_total_second);
        }, 0);

        dispatch(setSummaryBillTotalPrice(
            {
                summaryBillTotalOrder: totalSum.toFixed(2),
                summaryBillTotalVendor: SumVendorTotalPrice.toFixed(2),
            }
        ))
    }, [summaryBillData])

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h5 className='mb-0'>Bills</h5>
                {/* {!pdf && <div className='choose-assets-location'>
                    <button type='button' onClick={() => {
                        dispatch(setOpenAddItemsModal(true))
                        handleEraserFilterClick()
                    }}>
                        <span className='mr-2'><PlusIcon color="#D9314A" /> </span> Add Item
                    </button>
                </div>} */}
            </div >
            <table className='bills-table' style={{ width: "100%", padding: "16px", borderCollapse: "collapse" }}>
                <thead>
                    <tr>
                        <td>
                            Work order (CMMS) <br />
                        </td>
                        <td style={{ width: "400px" }}>
                            Work description/ <br />
                            <span style={{ color: "#727478" }}>Keterangan kerja</span>
                        </td>
                        <td>
                            Total purchase order (RM)/ <br />
                            <span style={{ color: "#727478" }}>Jumlah pesanan belian (RM)</span>
                        </td>
                        <td style={{ borderLeft: "1px solid #72747814" }}>
                            Total approved vendor claim (RM)/ <br />
                            <span style={{ color: "#727478" }}>Jumlah tuntutan vendor yang diluluskan (RM)</span>
                        </td>
                        <td>
                            Percentage approved vendor claim (%)/ <br />
                            <span style={{ color: "#727478" }}>Peratusan tuntutan vendor yang diluluskan (%)</span>
                        </td>
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {summaryBillData?.map((item, i) => (
                        <SummaryBillsItem item={item} index={i} key={item?.id} pdf={pdf} />
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2" style={{ textAlign: "left", fontWeight: 600 }}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                {!pdf && <Tooltip color="black" content={<span>Total bills = Sum (total price)</span>} />}
                                <span className='ml-2'>
                                    <span>Total price (RM)/</span> <br />
                                    <span>Jumlah keseluruhan harga (RM)</span>
                                </span>
                            </div>
                        </td>
                        <td style={{ fontWeight: 700 }}>RM{parseFloat(summarybillTotalPrice?.summaryBillTotalOrder).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                        <td style={{ fontWeight: 700 }}>RM{parseFloat(summarybillTotalPrice?.summaryBillTotalVendor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                        <td />
                        <td />
                    </tr>
                </tfoot>
            </table>
            {openAddItemsModal && <AddBillItemsModal />}
        </div>
    )
}
