import { setBillData, setBillSummaryData, setBillTotalPrice, setBillWorkData, setPenaltyData, setPenaltyTotalPrice, setSubTotal, setSummaryBillTotalPrice, setSummaryBillWorkData, setSurchargeData, setSurchargeTotalPrice } from "../../redux/features/SOR/SORSlice"

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */

export function migrateBillData(singleBill, dispatch) {
  dispatch(setBillData(
    singleBill.bill_sor_items?.map(item => {
      return {
        ...item,
        "id": item.sor,
        "remarkText": item.remarks,
        "quantity": +item.quantity_first,
        "totalPrice": +item.total_price_first,
        "vendorQuantity": +item.quantity_second,
        "vendorTotalPrice": +item.total_price_second,
        "discountRate": +item.discount_rate,
        "rate": +item.rate,
        "unit": item.unit,
        "source": item.source,
        "description": item.description,
        "discount": item.discount,
      }
    }),
  ))
  dispatch(setSurchargeData(
    singleBill.surcharges?.map(item => {
      return {
        "code": item.code,
        "description": item.description,
        "rate": +item.rate,
      }
    }),
  ))
  dispatch(setPenaltyData(
    singleBill.penalties?.map(item => {
      return {
        "parameter": item.parameter,
        "description": item.description,
        "active": item.is_active,
        "penaltyQuantumRate": +item.penalty_value,
        "startDate": item.period_start,
        "endDate": item.period_end,
        "penaltyQuantumType": item.penalty_type === "PRICE" ? "price" : "percentage",
      }
    }),
  ))
  dispatch(setBillWorkData(
    {
      title: singleBill?.work_title,
      description: singleBill?.work_description,
      agreementNo: singleBill?.outline_agreement_no,
      jobNum: singleBill?.job_num,
      order: singleBill?.work_order_cmms,
      orderType: singleBill?.work_order_type,
      location: singleBill?.location,
    }
  ))
  dispatch(setBillTotalPrice(
    {
      billTotalOrder: parseFloat(singleBill?.total_bills_first),
      billTotalVendor: parseFloat(singleBill?.total_bills_second)
    }
  ))

  dispatch(setSurchargeTotalPrice(
    {
      surchargeTotalOrder: +singleBill?.total_surcharge_first,
      surchargeTotalVendor: +singleBill?.total_surcharge_second,
    }
  ))
  dispatch(
    setSubTotal(
      {
        subTotalOrder: +(+singleBill?.total_bills_first + (+singleBill?.total_surcharge_first)),
        subTotalVendor: +(+singleBill?.total_bills_second + (+singleBill?.total_surcharge_second))
      }
    )
  )
  dispatch(setPenaltyTotalPrice(+singleBill?.total_penalty))
}




export function migrateBillSummaryData(singleBillSummary, dispatch) {  
  dispatch(setBillSummaryData(
    singleBillSummary.bill_summary_items?.map(item => {
      return {
        "bill": item.bill,
        "id": item.id,
        "grand_total_first": +item.total_purchase_order,
        "grand_total_second": +item.total_approved_claims,
        "work_order_cmms": item.work_order_cmms,
        "work_description": item.work_description,
      }
    }),
  ))
  dispatch(setSummaryBillWorkData(
    {
      title: singleBillSummary?.work_title,
      technicianName: singleBillSummary?.technician_name,
      contractorName: singleBillSummary?.contractor_name,
      noVendor: singleBillSummary?.no_vendor,
      startDate: singleBillSummary?.period_start,
      endDate: singleBillSummary?.period_end,
      jobNo: singleBillSummary?.job_num,
      purchaseOrderNumber: singleBillSummary?.purchase_order_number,
    }
  ))
  dispatch(setSummaryBillTotalPrice(
    {
      summaryBillTotalOrder: singleBillSummary?.total_price_first,
      summaryBillTotalVendor: singleBillSummary?.total_price_second,
    }
  ))
}
