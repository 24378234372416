import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap from '@fullcalendar/bootstrap';
import './calendar.css';
import { getEventsByAuthentication, getMeterEventsByAuthentication } from '../../redux/features/dashboard/dashboardSlice';
import Loader from '../loader/loader';

/* eslint-disable react/jsx-boolean-value */
// eslint-disable-next-line react/prop-types

export default function Calendar({ handleEventClick, events, setIsLoading, isLoading }) {
  const dispatch = useDispatch();

  const statusOrder = {
    "In-Progress": 1,
    "On-Hold": 2,
    "Open": 3,
    "Completed": 4,
  };

  const sortEventsByTime = () => {
    return [...events]?.sort((a, b) => {
      const statusComparison = statusOrder[a.status] - statusOrder[b.status];
      if (statusComparison === 0) {
        const startA = new Date(a.due_finish);
        const startB = new Date(b.due_finish);
        return startA.getTime() - startB.getTime();
      }
      if (a.reading_date || b.reading_date) {
        const startA = new Date(a.reading_date);
        const startB = new Date(b.reading_date);
        return startA.getTime() - startB.getTime();
      }
      return statusComparison;
    });
  };

  const calendarRef = useRef(null);
  const [sortedData, setSortedData] = useState([]);
  const [currentDate, setCurrentDate] = useState(null); // Current date state

  useEffect(() => {
    setSortedData(sortEventsByTime());
    if (calendarRef.current) {
      calendarRef.current.getApi().refetchEvents();
    }
  }, [events]);

  const fetchData = async () => {
    setIsLoading(true)
    await dispatch(getEventsByAuthentication(currentDate));
    dispatch(getMeterEventsByAuthentication(currentDate));
    setIsLoading(false)
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = new Date(dateInfo.start);
    const updatedDate = new Date(startDate.setDate(startDate.getDate() + 10));    
    if (currentDate?.toISOString() !== updatedDate?.toISOString()) {
      setCurrentDate(updatedDate);
    }
  };

  useEffect(() => {
    if (currentDate) {
      fetchData();
    }
  }, [currentDate]);

  return (<>
    {isLoading && <Loader />} {/* Loading indicator */}
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, bootstrap, interactionPlugin, timeGridPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      }}
      eventClassNames="fc-button"
      themeSystem="bootstrap"
      editable={false}
      selectable={false}
      selectMirror={false}
      dayMaxEvents={2}
      eventClick={(e) => {
        handleEventClick(e);
      }}
      droppable={false}
      showNonCurrentDates
      fixedWeekCount={false}
      events={sortedData}
      height={700}
      eventOrder={false}
      views={{
        timeGridWeek: {
          dayMaxEvents: 15,
        },
        timeGridDay: {
          dayMaxEvents: 15,
        },
      }}
      datesSet={handleDatesSet} // Triggered when date range changes
    />
  </>
  );
}
