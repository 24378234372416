/* eslint-disable react/prop-types */
/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Link } from 'react-router-dom';
// import { BlobProvider } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { Drawer } from '@material-ui/core';
// import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/esm/Button';
// import { useReactToPrint } from 'react-to-print';
// import PopUpDeleteModel from '../location/popUpDeleteModel';
import styles from '../layout/layout.css';
// import Card from '../../Ui/Card';

import {
  updateInventory,
  setInventoryAssignedTo,
  setInventoryCategory,
  setInventoryLocation,
  setInventoryDescription,
  setInventoryName,
  setInventoryPrice,
  setInventorySerialNumber,
  setInventoryQuantity,
  setInventorySite,
  setInventoryMinimumQuantity,
  setInventoryType,
  setInventoryPurchaseOrder,
  setInventoryStock,
  setInventoryVendor,
  setInventoryImage,
  resetInventory,
  // closeInventoryModalUpdate
  closeInventoryModalDelete,
  openInventoryModalDelete,
  deleteInventory,
  // closeInventoryModalUpdate,
  setInventoryPhoto,
  setInventoryAttach,
  setDepartment,
  setInventoryIdNumber,
  openInventoryAdjustmentModal,
  // setInventoryDetails,
  closeInventoryAdjustmentModal,
  setInventoryQuantityImg,
  setInventoryQuantityPhoto,
  setinventoryDetails,
  updateInventoryQuantity,
} from '../../redux/features/inventory/inventorySlice';
// import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
// import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import { SelectLocation, SelectPersons, SelectSite } from '../select/select';
import InventoryQrPrint from './inventoryQrPrint';
import formatNumber from '../../utils/formatNumber';
import PopUpDeleteModal from '../popUpModals/editCategory/popUpDeleteModal';

export default function InventoryUpdateModal({ openModalUpdate, closeModalUpdate, inventoryData }) {
  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const dispatch = useDispatch();

  const { profile } = useSelector(state => state.company);

  // const { locations } = useSelector((state) => state.location);
  const { inventories } = useSelector(state => state.inventoryCategory);
  const [adjustmentValue, setAdjustmentValue] = useState(0);
  // const [newValue, setNewValue] = useState(0);
  const [invers, setInventories] = useState(inventories || []);
  const [showMen, setShowMenue] = useState(false);
  const {
    inventoryName,
    InventoryModalDelete,
    inventorySerialNumber,
    inventoryIdNumber,
    inventoryDescription,
    inventoryAssignedTo,
    inventoryPrice,
    inventoryQuantity,
    inventoryMinimumQuantity,
    // inventoryLocation,
    inventoryVendor,
    inventoryPurchaseOrder,
    // inventorySite,
    inventoryStock,
    inventoryType,
    inventoryCategory,
    inventoryImage,
    inventoryPhoto,
    inventoryAttach,
    department,
    inventoryDetails,
    InventoryAdjustmentModal,
    inventoryAdjustQuantityImg,
    inventoryAdjustQuantityPhoto,
  } = useSelector(state => state.inventory);
  const { departments } = useSelector(state => state.department);
  const { user } = useSelector(state => state.user);
  const { teams } = useSelector(state => state.teams);
  // const [users, setUsers] = useState([]);
  // const [locationsData, setLocations] = useState([]);
  // const [locs, seetlocs] = useState([]);

  React.useEffect(() => {
    setInventories(inventories);
  }, [inventories]);
  // React.useEffect(() => {
  //   seetlocs(locations);
  // }, [locations]);

  React.useEffect(() => {
    const getData = async () => {
      dispatch(setInventoryName(inventoryData?.name));
      dispatch(setInventorySerialNumber(inventoryData?.serial_number));
      dispatch(setInventoryIdNumber(inventoryData?.inventory_id));
      dispatch(setInventoryDescription(inventoryData?.description));
      // const assignedTo = inventoryData?.assigned_to || [];
      // const array = [];
      // if (inventoryData?.assigned_to > 0) {
      //   inventoryData?.assigned_to.map(e => array.push(e.id));
      // }
      // dispatch(setInventoryAssignedTo(assignedTo.map(e => e.id)));
      dispatch(
        setInventoryAssignedTo(
          inventoryData?.assigned_to?.length > 0
            ? inventoryData?.assigned_to?.map(i => ({
                value: i?.id,
                label: `${i?.name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
                image: i?.image,
              }))
            : [],
        ),
      );
      dispatch(setInventoryPrice(inventoryData?.price));
      dispatch(setInventoryQuantity(inventoryData?.total));
      dispatch(setInventoryMinimumQuantity(inventoryData?.minimum_inventory));
      dispatch(setInventoryLocation(inventoryData?.location));
      dispatch(setInventoryVendor(inventoryData?.vendor));
      dispatch(setInventoryPurchaseOrder(inventoryData?.po_number));
      dispatch(setInventorySite(inventoryData?.sub_location?.id));
      dispatch(setInventoryStock(inventoryData?.non_stock));
      dispatch(setInventoryType(inventoryData?.type));
      dispatch(setInventoryCategory(inventoryData?.category?.id));
      dispatch(setInventoryAttach(inventoryData?.image));
      dispatch(
        setDepartment(
          inventoryData?.department?.length > 0 ? inventoryData?.department[0]?.id : '',
        ),
      );
      dispatch(selectActions.setIdOfSite(inventoryData?.location?.id));
      dispatch(selectActions.setIdOfLocation(inventoryData?.sub_location?.id));
      dispatch(
        selectActions.chooseSite({
          selected: inventoryData?.location?.site,
          id: inventoryData?.location?.id,
        }),
      );
      dispatch(selectActions.setIdOfLocation(inventoryData?.sub_location?.id));
      dispatch(
        selectActions.chooseLocation({
          selected:
            inventoryData?.sub_location?.room &&
            `${inventoryData?.sub_location?.room && inventoryData?.sub_location?.room}${inventoryData?.sub_location?.suite && `, ` + inventoryData?.sub_location?.suite
            }${inventoryData?.sub_location?.level && `, ` + inventoryData?.sub_location?.level}${inventoryData?.sub_location?.building && `, ` + inventoryData?.sub_location?.building
            }`,
          id: inventoryData?.sub_location?.id,
        }),
      );
    };
    getData();
  }, [dispatch, inventoryData]);

  // useEffect(() => {
  //   dispatch(getLocationsByAuthentication());
  //   dispatch(getTeamsByAuthentication());
  // }, [])

  const { locations, sublocations } = useSelector(state => state.location);
  const { selectedSite, idOfSite, idOfLocation } = useSelector(state => state.selectOptions);
  const handelPriceChange = (e)=>{
    const input = e.target;
    const {value} = input;
    const formattedValue = formatNumber(value)[0];
    dispatch(setInventoryPrice(formattedValue));
  }

  const handleUpdateInventory = async e => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('id', inventoryData.id);
    formdata.append('name', inventoryName);
    if (department?.length > 0) {
      formdata.append('department', [department]);
    }
    formdata.append('serial_number', inventorySerialNumber);
    formdata.append('inventory_id', inventoryIdNumber);
    formdata.append('description', inventoryDescription || '');
    inventoryAssignedTo?.forEach(element => {
      formdata.append('assigned_to', element.value);
    });
    formdata.append('price', inventoryPrice);
    formdata.append('total', inventoryQuantity);
    formdata.append('minimum_inventory', inventoryMinimumQuantity);
    // formdata.append('location', inventoryLocation?.id);
    // formdata.append('sub_location', inventorySite);
    if (idOfSite) {
      formdata.append('location', idOfSite);
    }
    if (idOfLocation) {
      formdata.append('sub_location', idOfLocation);
    }
    formdata.append('vendor', inventoryVendor || '');
    formdata.append('po_number', inventoryPurchaseOrder || '');
    formdata.append('non_stock', inventoryStock);
    formdata.append('type', inventoryType);
    formdata.append('category', inventoryCategory);
    if (inventoryImage) {
      formdata.append('image', inventoryImage);
    }
    if (!formatNumber(inventoryPrice)[1] && inventoryPrice) {
      toast.warning("Please write a correct Number on Price field (write the decimal part or remove decimal point)", {
        toastId: 'CreatedEventWarninggg',
      });
    }else{
    await dispatch(updateInventory({ id: inventoryData.id, formdata }));
    dispatch(resetInventory());
    closeModalUpdate();
    }
  };
  // BUtton to save the adjust value
  const handleSaveAdjustValue = async () => {
    const formdata = new FormData()
    formdata.append('inventory', inventoryData.id);
    if (adjustmentValue === 0) {
      formdata.append("new_quantity", inventoryQuantity)
    } else {
      formdata.append("new_quantity", adjustmentValue)
    }
    formdata.append("details", inventoryDetails)
    formdata.append("attachment", inventoryAdjustQuantityImg)

    await dispatch(updateInventoryQuantity(formdata))
  }

  const {
    isInventoryUpdateSuccess
  } = useSelector((state) => state.inventory);


  useEffect(() => {
    dispatch(setinventoryDetails(""))
  }, [isInventoryUpdateSuccess])
  

  const generateSerialNumber = () => {
    const random = Math.floor(Math.random() * 100000000000000000);
    dispatch(setInventoryIdNumber(random));
  };
  // React.useEffect(() => {
  //   setUsers(teams.results || []);
  // }, [teams.results]);

  
  const goTo = `/inventory-history/${inventoryData.id}`;

  // const filteredUsers =
  //   users !== undefined && users.filter(e => e?.is_active === 'True' && e?.first_name);
  // const options = filteredUsers.map(i => ({
  //   value: i.first_name,
  //   label: i.first_name,
  //   image: i.profile.image,
  // }));
  // const [selectedOptions, setSelectedOptions] = useState([]);
  // // Initialize the selected options with the existing assigned_to values
  // React.useEffect(() => {
  //   if (inventoryData.assigned_to) {
  //     const existingAssignedToOptions = inventoryData.assigned_to.map(u => ({
  //       value: u.name,
  //       label: u.name,
  //     }));
  //     setSelectedOptions(existingAssignedToOptions);
  //   }
  // }, [inventoryData.assigned_to]);

  // const handleMulti = selected => {
  //   setSelectedOptions(selected);
  // };

  // React.useEffect(() => {
  //   const selectedNames = selectedOptions.map(option => option.value);
  //   const selectedUserIds = filteredUsers
  //     .filter(u => selectedNames.includes(u.first_name))
  //     .map(u => u.id);
  //   dispatch(setInventoryAssignedTo(selectedUserIds));
  // }, [selectedOptions]);

  let deleteBtn = (
    <li role="presentation">
      <button
        type="button"
        data-toggle="modal"
        data-target="#modal-delete-part"
        title="Delete Spare part"
        className="font-danger"
        onClick={() => {
          dispatch(openInventoryModalDelete());
          setShowMenue(false)
        }}
      >
        Delete
      </button>
    </li>
  );
  if (
    user?.role === 'Technical Team' ||
    (user?.role === 'Manager' && inventoryData?.created_by?.role !== 'Manager')
  ) {
    deleteBtn = '';
  }
  const isTechCanEdit =
    user?.role === 'Technical Team' && inventoryData?.created_by?.role !== 'Technical Team';
  return (
    <>
      <PopUpDeleteModal
        showModal={InventoryModalDelete}
        handleClose={() => dispatch(closeInventoryModalDelete())}
        handleConfirm={() => {
          dispatch(deleteInventory(inventoryData?.id));
          dispatch(closeInventoryModalDelete());
        }}
        modalTitle="Delete inventory"
        modalBodyText="Are you sure you want to delete this inventory?"
      />
      <Drawer
        anchor="right"
        open={openModalUpdate}
        onClose={closeModalUpdate}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '500px',
            height: '100%',
          },
        }}
      >
        <form
          action="{{action}}"
          className="h-100"
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleUpdateInventory}
        >
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
            <div className="col-12 row px-2">
              <div className="my-auto">Update Inventories/Parts</div>

              <div className="row ml-auto">
                <div className={showMen ? 'dropdown show' : 'dropdown'}>
                  {showMen && (
                    <div className="tck-overlay" onClick={() => setShowMenue(!showMen)} />
                  )}
                  <button
                    type="button"
                    data-toggle="dropdown"
                    id="dropdownMenu1"
                    className="btn tertiary-btn ml-2"
                    onClick={() => setShowMenue(!showMen)}
                  >
                    <div className="fas fa-ellipsis-h" />
                  </button>
                  <ul
                    className={`dropdown-menu ${showMen && 'dropdown-menu-active'}`}
                    role="menu"
                    aria-labelledby="dropdownMenu1"
                  >
                    {deleteBtn}

                    <li role="presentation">
                      <Link to={goTo}>History</Link>
                    </li>
                    <li role="presentation">
                      <InventoryQrPrint inventory={inventoryData} company={profile?.logo} />
                    </li>
                    {/* <BlobProvider
                      document={
                        <InventoryQrPrint inventory={inventoryData} company={profile?.logo} />
                      }
                    >
                      {({ url, loading }) =>
                        loading ? (
                          'Loading...'
                        ) : (
                          <li role="presentation">
                            <a style={{ textDecoration: 'none' }} href={url} target="_blank">
                              QR
                            </a>
                          </li>
                        )
                      }
                    </BlobProvider> */}
                    {/* <button
                        onClick={() => {
                          handlePrint();
                          dispatch(closeInventoryModalUpdate());
                          setShowMenue(false);
                        }}
                        title="Print QR Code"
                        className="text-dark"
                        type="button"
                      >
                        Print
                      </button> */}
                  </ul>
                </div>
                <div
                  className="card-body"
                  style={{ paddingBottom: '1.25rem', display: 'none' }}
                  ref={componentRef}
                >
                  <div className="text-center p-1" id="printThisLocation">
                    <center>
                      <img
                        className="qr-print"
                        src={inventoryData?.qr_code}
                        id="location-qr-url"
                        width="144"
                        height="144"
                        alt=""
                      />
                      <div className="qr-print">{`Serial Number  : ${inventorySerialNumber}`}</div>
                      <div className="qr-print"> {`name : ${inventoryName} `}</div>
                      <div className="qr-print"> {`Location : ${selectedSite}`}</div>
                      <br />
                    </center>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn secondary-btn ml-2"
                  data-dismiss="modal"
                  onClick={closeModalUpdate}
                >
                  <div className="fas fa-times" />
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 pb-5">
            <div className="card-body row">
              <div className="col-lg-12 col-sm-12">
                <center>
                  <img
                    src={inventoryData?.qr_code}
                    id="location-qr-url"
                    width="144"
                    height="144"
                    alt=""
                  />
                </center>
                <span className="font-weight-normal row col-12">
                  Name
                  <div className="font-danger">*</div>
                </span>
                <input
                  name="spareparts"
                  type="text"
                  className="form-control mb-2"
                  required
                  placeholder="Inventories/Parts Name"
                  id="spareparts_id"
                  value={inventoryName}
                  onChange={e => {
                    dispatch(setInventoryName(e.target.value));
                  }}
                />
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="spareparts_error" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <span className="font-weight-normal row col-12">
                  Inventory ID
                  <div className="font-danger">*</div>
                </span>
                <input
                  name="serial_number"
                  type="text"
                  className="form-control"
                  required
                  placeholder="Inventory ID"
                  id="inventory_id"
                  value={inventoryIdNumber}
                  onChange={e => {
                    dispatch(setInventoryIdNumber(e.target.value));
                  }}
                />

                <button
                  className="a-color"
                  id="auto_serial_number_btn"
                  href="#"
                  onClick={generateSerialNumber}
                  type="button"
                >
                  <span className="button__text">Generate Inventory ID </span>
                  <i className="fa fa-refresh" />
                </button>
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="serial_number_error" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <span className="font-weight-normal row col-12">
                  Serial number
                  {/* <div className="font-danger">*</div> */}
                </span>
                <input
                  name="serial_number"
                  type="text"
                  className="form-control"
                  placeholder="Serial Number"
                  id="serial_number"
                  value={inventorySerialNumber}
                  onChange={e => {
                    dispatch(setInventorySerialNumber(e.target.value));
                  }}
                />

                {/* <button
                  className="a-color"
                  id="auto_serial_number_btn"
                  href="#"
                  onClick={generateSerialNumber}
                  type="button"
                >
                  <span className="button__text">Generate serial number </span>
                  <i className="fa fa-refresh" />
                </button>
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="serial_number_error" />
                </div> */}
              </div>

              <div className="col-lg-12 col-sm-12">
                <span className="font-weight-normal">Description</span>
                <textarea
                  name="description"
                  className="form-control mb-3"
                  placeholder="Description"
                  id="description"
                  value={inventoryDescription}
                  onChange={e => {
                    dispatch(setInventoryDescription(e.target.value));
                  }}
                />
              </div>

              <div className="col-lg-12 col-sm-12">
                <span className="font-weight-normal m-0 asset">
                  Assigned to <span className="m-0 font-danger">*</span>
                </span>
                {/* <Select
                  isMulti
                  options={options}
                  onChange={handleMulti}
                  value={selectedOptions}
                  id="assigned_to"
                /> */}
                <SelectPersons teams={teams?.results} assignedTo={inventoryAssignedTo} setAssignedTo={setInventoryAssignedTo} />
              </div>
              <div className="col-lg-12 col-sm-12">
                <div className="font-weight-normal">
                  Photos
                  <span className="text-secondary small-font">(Max upload size is 5MB)</span>
                  <span id="file-name" />
                  <div className="col-12 p-0 m-0">
                    <input
                      name="image"
                      type="file"
                      className="none"
                      id="add-image"
                      accept="image/*"
                      onChange={e => {
                        dispatch(setInventoryImage(e.target.files[0]));
                        dispatch(setInventoryPhoto(URL.createObjectURL(e.target.files[0])));
                      }}
                    />
                    {inventoryPhoto || inventoryAttach ? (
                      <div className="text-center col-12 p-0">
                        <label htmlFor="add-image">
                          <img
                            className="image p-2 photo"
                            height="120"
                            width="120"
                            alt="qr code"
                            src={inventoryPhoto || inventoryAttach}
                            id="preview-image"
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="container col-2 m-0 my-2 text-center">
                        <label
                          htmlFor="add-image"
                          className="custom-file-upload justify-content-center"
                          style={{
                            border: '1px solid #ccc',
                            color: '#999999',
                            background: '#f8f8f8',
                            borderRadius: '8px',
                            fontWeight: 'normal',
                            width: '4em',
                            height: '4em',
                            aspectRatio: 'auto 100 / 100',
                            boxShadow:
                              'rgb(214 218 221 / 48%) 3px 3px 3px, white -4px -4px 4px !important',
                            cursor: 'pointer',
                          }}
                        >
                          <i className="fas fa-upload col-12 mt-4" />
                          <div className="col-12">Upload</div>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {!InventoryAdjustmentModal ? (
                <>

                  <div className="row col-12 mt-4 pr-0 mr-0">
                    {/* Price Input */}
                    <div className="col-md-4 col-sm-12">
                      <label className="font-weight-normal">
                        Price <span className="font-danger">*</span>
                      </label>
                      <input
                        required
                        name="price"
                        type="text"
                        className="form-control mb-2"
                        id="price"
                        placeholder="RM 0 "
                        value={inventoryPrice}
                        onChange={handelPriceChange}
                      />
                      <div className="col-md-12 col-sm-12 m-0">
                        <span className="font-danger" id="price_error" />
                      </div>
                    </div>

                    {/* Quantity Input */}
                    <div className="col-md-3 col-sm-6">
                      <label className="font-weight-normal">
                        Quantity <span className="font-danger">*</span>
                      </label>
                      <input
                        required
                        disabled
                        name="inventory"
                        type="number"
                        min="0"
                        className="form-control mb-2"
                        id="inventory"
                        placeholder="Quantity"
                        value={inventoryQuantity}
                        onChange={(e) => {
                          dispatch(setInventoryQuantity(+e.target.value));
                        }}
                      />
                    </div>

                    {/* Button */}
                    <div className="col-md-1 col-sm-1 mt-3 pt-2">
                      <button
                        type="button"
                        className={`btn ${styles.menuBtn}`}
                        onClick={() => dispatch(openInventoryAdjustmentModal())}
                      >
                        <div style={{ padding: 2, marginTop: 2 }}>
                          <FontAwesomeIcon className="nav-icon" icon={faBars} />
                        </div>
                      </button>
                    </div>

                    {/* Min. Quantity Input */}
                    <div className="col-md-4 col-sm-6 pr-0">
                      <label className="font-weight-normal">
                        Min. Quantity <span className="font-danger">*</span>
                      </label>
                      <input
                        required
                        name="minimum_inventory"
                        type="number"
                        min="0"
                        onInput={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                        }}
                        className="form-control mb-2"
                        id="minimum_inventory"
                        placeholder="Minimum Quantity"
                        value={inventoryMinimumQuantity}
                        onChange={(e) => {
                          dispatch(setInventoryMinimumQuantity(+e.target.value));
                        }}
                      />
                      {/* Error Message */}
                      <div className="col-md-12 col-sm-12 m-0">
                        <span className="font-danger" id="minimum_inventory_error" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 pr-0 custom_Checkbox">
                      <input
                        name="non_stock_checkbox"
                        type="checkbox"
                        id="non_stock_checkbox"
                        className="ml-2"
                        checked={inventoryStock}
                        style={{ height: '20px', width: '10px !important', margin: '12px 10px' }}
                        onChange={e => {
                          dispatch(setInventoryStock(e.target.checked));
                        }}
                      />
                      <input
                        type="hidden"
                        id="non_stock"
                        name="non_stock"
                        checked={inventoryStock}
                        style={{
                          height: ' 20px',
                        }}
                      />
                      <span className="font-weight-normal ml-3">Non-Stock</span>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <span className="font-weight-normal">Vendor</span>
                      <input
                        name="vendor"
                        type="text"
                        className="form-control mb-2"
                        id="vendor"
                        placeholder="Vendor Name"
                        value={inventoryVendor}
                        onChange={e => {
                          dispatch(setInventoryVendor(e.target.value));
                        }}
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="vendor_error" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 pr-0">
                    <span className="font-weight-normal">Purchase Order</span>
                    <input
                      name="purchase_order"
                      type="text"
                      className="form-control mb-2"
                      id="purchase_order"
                      placeholder="Purchase Order Number"
                      value={inventoryPurchaseOrder}
                      onChange={e => {
                        dispatch(setInventoryPurchaseOrder(e.target.value));
                      }}
                    />
                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="purchase_order_error" />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 pt-3">
                    <div id="ifLocation" className="col-12 p-0">
                      <SelectSite locations={locations} width="100%" />
                      {selectedSite && (
                        <div className="col-12 p-0" id="building_show">
                          <SelectLocation
                            subLocation={sublocations?.results}
                            allSubLocations={[]}
                            width="100%"
                            noStar
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 pt-3 pb-3">
                    {user.role === 'CliAdm' && (
                      <div className="col-lg-12 col-sm-12 p-0">
                        <label className="font-weight-normal">Inventory Department </label>
                        <div className="col-12 p-0">
                          <select
                            name="department"
                            value={department}
                            onChange={e => {
                              dispatch(setDepartment(e.target.value));
                            }}
                            className="form-control select-dash"
                          >
                            <option value="" disabled selected>
                              {' '}
                              --Select--{' '}
                            </option>
                            {departments?.map(singleDepartment => (
                              <option key={departments.id} value={singleDepartment.id}>
                                {singleDepartment.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 col-sm-6">
                    <span className="font-weight-normal">
                      Type <span className="font-danger">*</span>
                    </span>
                    <div className="col-12 p-0">
                      <select
                        className="custom-select2 mb-2"
                        style={{ width: '100%' }}
                        required
                        name="spareparts_type"
                        id="spareparts_type"
                        value={inventoryType}
                        onChange={e => {
                          dispatch(setInventoryType(e.target.value));
                        }}
                      >
                        <option value="" disabled>
                          --SELECT--
                        </option>
                        <option value="Consumable">Consumable</option>
                        <option value="Non-Consumable">Non-Consumable</option>
                      </select>
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="spareparts_type_error" />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6">
                    <span className="font-weight-normal">
                      Category <span className="font-danger">*</span>
                    </span>
                    <div className="col-12 p-0">
                      <select
                        className="custom-select2 mb-2"
                        style={{ width: '100% !important' }}
                        required
                        name="category"
                        id="category"
                        value={inventoryCategory}
                        onChange={e => {
                          dispatch(setInventoryCategory(+e.target.value));
                        }}
                      >
                        <option value="" disabled>
                          --SELECT--
                        </option>
                        {invers.map(e => (
                          <option value={e.id}>{e.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="category_error" />
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-lg-12 col-sm-12">
                  <div className="meter-card ml-4 p-5">
                    <b>Adjust Quantity</b>
                    <div className="col-lg-12 col-sm-12">
                      <span className="font-weight-normal text-dark">
                        Adjust by <span className="font-danger">*</span>
                      </span>
                      <input
                        required
                        name="inventory"
                        type="number"
                        min="0"
                        className="form-control mb-2"
                        id="inventory"
                        placeholder="Quantity"
                        onChange={e => {
                          const newAdjustmentValue = Number(e.target.value) + inventoryQuantity;
                          // setNewValue(+e.target.value);
                          setAdjustmentValue(newAdjustmentValue);

                          // const newInventoryQuantity = Number(e.target.value) + inventoryQuantity;
                          // dispatch(setInventoryQuantity(newInventoryQuantity))
                        }}
                      />

                      <div className="text-muted mt-2 text-right">+/-</div>
                      <div>
                        <input
                          name="image"
                          type="file"
                          className="none"
                          id="add-image-quantity"
                          accept="image/*"
                          onChange={e => {
                            dispatch(setInventoryQuantityImg(e.target.files[0]));
                            dispatch(
                              setInventoryQuantityPhoto(URL.createObjectURL(e.target.files[0])),
                            );
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          color: 'gray',
                          fontWeight: 'bold',
                          marginBottom: 4
                        }}
                      >
                        <span>Original: {inventoryQuantity}</span>
                        <span>New: {adjustmentValue}</span>
                      </div>
                      <hr style={{ marginLeft: '5px', marginRight: '5px', width: 'auto', height: '2px' }} />
                      <div className="col-lg-12 col-sm-12">
                        <span className="font-weight-normal text-dark">Details</span>
                        <textarea
                          name="description"
                          className="form-control mb-3 "
                          placeholder="Adjustment Details"
                          id="details"
                          value={inventoryDetails}
                          onChange={e => dispatch(setinventoryDetails(e.target.value))}
                        />
                        {inventoryAdjustQuantityImg || inventoryAdjustQuantityPhoto ? (
                          <div className="text-center col-12 p-0">
                            <label htmlFor="add-image-quantity">
                              <img
                                className="image p-2 photo"
                                height="120"
                                width="120"
                                alt="qr code"
                                src={inventoryAdjustQuantityPhoto || inventoryAdjustQuantityImg}
                                id="preview-image"
                              />
                            </label>
                          </div>
                        ) : (
                          <div className="container col-2 m-0 my-2">
                            <label
                              htmlFor="add-image-quantity"
                              className="custom-file-upload justify-content-center"
                            // style={{
                            //   border: '1px solid #ccc',
                            //   color: '#999999',
                            //   background: '#f8f8f8',
                            //   borderRadius: '8px',
                            //   fontWeight: 'normal',
                            //   width: '4em',
                            //   height: '4em',
                            //   aspectRatio: 'auto 100 / 100',
                            //   boxShadow:
                            //     'rgb(214 218 221 / 48%) 3px 3px 3px, white -4px -4px 4px !important',
                            //   cursor: 'pointer',
                            // }}
                            >
                              {/* <i className="fas fa-upload col-12 mt-4" /> */}

                              <div className="col-6" style={{ display: 'inline-block' }}>
                                Receipt:
                              </div>
                              <div className="col-12 text-primary " style={{ display: 'inline-block', cursor: 'pointer' }}>Upload</div>
                            </label>
                          </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Button
                            disabled={isTechCanEdit}
                            className="btn button-btn pmd-btn-fab"
                            variant="light"
                            onClick={() => {
                              dispatch(closeInventoryAdjustmentModal());
                            }}
                          >
                            <span className="button__text">Cancel</span>
                          </Button>
                          <Button
                            // onClick={dispatch(setInventoryQuantity(adjustmentValue))}
                            onClick={() => {
                              dispatch(closeInventoryAdjustmentModal())
                              handleSaveAdjustValue()
                            }}
                            disabled={!adjustmentValue}
                            className="btn button-btn pmd-btn-fab primary-btn"
                            type="button"
                          >
                            <span className="button__text mb-4">Adjust Quantity</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row col-lg-12 m-0 p-2 page-profile-footer">
            {!InventoryAdjustmentModal && <button
              disabled={isTechCanEdit}
              className="btn button-btn pmd-btn-fab primary-btn"
              // onClick="this.classList.toggle('button--loading')"
              type="submit"
            >
              <span className="button__text">Update</span>
            </button>}
            {/* )} */}
          </div>
        </form>
      </Drawer>
    </>
  );
}

/* 

  <div className="col-lg-3 col-sm-6">
                      <span className="font-weight-normal">
                        Quantity <span className="font-danger">*</span>
                      </span>
                      <input
                        required
                        disabled
                        name="inventory"
                        // type="number"
                        min="0"
                        className="form-control mb-2"
                        id="inventory"
                        placeholder="Quantity"
                        value={inventoryQuantity}
                        onChange={e => {
                          dispatch(setInventoryQuantity(+e.target.value));
                        }}
                      />
                    </div>
                    <div className="col-lg-1 col-sm-1 mt-3 pt-2">
                      <button
                        type="button"
                        className={styles.menuBtn}
                        onClick={() => dispatch(openInventoryAdjustmentModal())}
                      >
                        <Card style={{ padding: 4, marginTop: 2 }}>
                          <FontAwesomeIcon className="nav-icon" icon={faBars} />
                        </Card>
                      </button>
                    </div>
*/
