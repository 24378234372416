import React from 'react'

function CopyIcon() {
    return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.4 9C7.96005 9 8.24008 9 8.45399 8.89101C8.64215 8.79513 8.79513 8.64215 8.89101 8.45399C9 8.24008 9 7.96005 9 7.4V2.6C9 2.03995 9 1.75992 8.89101 1.54601C8.79513 1.35785 8.64215 1.20487 8.45399 1.10899C8.24008 1 7.96005 1 7.4 1H4.6C4.03995 1 3.75992 1 3.54601 1.10899C3.35785 1.20487 3.20487 1.35785 3.10899 1.54601C3 1.75992 3 2.03995 3 2.6M2.6 11H5.4C5.96005 11 6.24008 11 6.45399 10.891C6.64215 10.7951 6.79513 10.6422 6.89101 10.454C7 10.2401 7 9.96005 7 9.4V4.6C7 4.03995 7 3.75992 6.89101 3.54601C6.79513 3.35785 6.64215 3.20487 6.45399 3.10899C6.24008 3 5.96005 3 5.4 3H2.6C2.03995 3 1.75992 3 1.54601 3.10899C1.35785 3.20487 1.20487 3.35785 1.10899 3.54601C1 3.75992 1 4.03995 1 4.6V9.4C1 9.96005 1 10.2401 1.10899 10.454C1.20487 10.6422 1.35785 10.7951 1.54601 10.891C1.75992 11 2.03995 11 2.6 11Z"
                stroke="#36383C"
                strokeWidth="1.5" />
        </svg>
    )
}

export default CopyIcon