import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import './epq.css';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import AddNewSurchargeModal from './addNewSurchargeModal';
import {
  setOpenSurchargeModal,
  setSubTotal,
  setSurchargeData,
  setSurchargeRates,
  setSurchargeTotalPrice,
} from '../../redux/features/SOR/SORSlice';
import DeleteIcon from '../../assets/svg-icons/DeleteIcon';
import Tooltip from '../../components/tooltip/tooltip';

/* eslint-disable no-unsafe-optional-chaining */

export default function Surcharge({ pdf }) {
  const {
    billData,
    surchargeData,
    openSurchargeModal,
    surchargeRates,
    billTotalPrice,
    surchargeTotalPrice,
    subTotal,
  } = useSelector((state) => state.SOR);
  const dispatch = useDispatch();

  const totalRateForSource = (item) => {
    const dataBySource = [];
    for (let index = 0; index < billData.length; index += 1) {
      const element = billData[index];
      if (element.source === item.code) {
        dataBySource.push(element);
      }
    }
    const sumBySource = dataBySource?.reduce((sum, s) => {
      return sum + parseFloat(s.totalPrice);
    }, 0);
    const sumBySourceVendor = dataBySource?.reduce((sum, s) => {
      return sum + parseFloat(s.vendorTotalPrice);
    }, 0);
    const totalRateBysource = (item.rate * sumBySource) / 100;
    const totalRateBysourceVendor = (item.rate * sumBySourceVendor) / 100;
    return { totalRateBysource, totalRateBysourceVendor };
  };

  const calculateAndStoreRates = () => {
    const rates = surchargeData?.map((item) => {
      const dataBySource = billData?.filter((bill) => bill.source === item.code);

      const sumBySource = dataBySource?.reduce((sum, s) => sum + parseFloat(s.totalPrice), 0);
      const sumBySourceVendor = dataBySource?.reduce(
        (sum, s) => sum + parseFloat(s.vendorTotalPrice),
        0,
      );

      const totalRateBysource = (item.rate * sumBySource) / 100;
      const totalRateBysourceVendor = (item.rate * sumBySourceVendor) / 100;

      return {
        code: item.code,
        description: item.description,
        rate: item.rate,
        totalRateBysource,
        totalRateBysourceVendor,
      };
    });

    dispatch(setSurchargeRates(rates)); // Store in Redux
  };

  // Call this function when needed, e.g., in a useEffect or a button click
  React.useEffect(() => {
    calculateAndStoreRates();
  }, [billData, surchargeData]);

  React.useEffect(() => {
    const totalSumSurcharge = surchargeRates?.reduce((sum, item) => {
      return sum + parseFloat(item.totalRateBysource);
    }, 0);

    const SumVendorTotalSurcharge = surchargeRates?.reduce((sum, item) => {
      return sum + parseFloat(item?.totalRateBysourceVendor);
    }, 0);
    dispatch(
      setSurchargeTotalPrice({
        surchargeTotalOrder: totalSumSurcharge,
        surchargeTotalVendor: SumVendorTotalSurcharge,
      }),
    );

    dispatch(
      setSubTotal({
        subTotalOrder: totalSumSurcharge + billTotalPrice?.billTotalOrder,
        subTotalVendor: SumVendorTotalSurcharge + billTotalPrice?.billTotalVendor,
      }),
    );
  }, [billData, surchargeRates, billTotalPrice]);

  const deleteItemHandler = (index) => {
    dispatch(dispatch(setSurchargeData(surchargeData.filter((_, i) => i !== index))));
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h5 className="mb-0">
          Surcharge <span style={{ color: '#727478' }}>(Optional)</span>
        </h5>
        {!pdf && (
          <div className="choose-assets-location">
            <button type="button" onClick={() => dispatch(setOpenSurchargeModal(true))}>
              <span className="mr-2">
                <PlusIcon color="#D9314A" />{' '}
              </span>{' '}
              Add surcharge
            </button>
          </div>
        )}
      </div>
      <table
        className="bills-table"
        style={{ width: '100%', padding: '16px', borderCollapse: 'collapse' }}
      >
        <thead>
          <tr>
            <td>
              Code/ <br />
              <span style={{ color: '#727478' }}>Kod</span>
            </td>
            <td style={{ width: '300px' }}>
              Description/ <br />
              <span style={{ color: '#727478' }}>Keterangan</span>
            </td>
            <td>
              Rate (%) <br />
              <span style={{ color: '#727478' }}>Kadar (%)</span>
            </td>
            <td>
              Total price/ <br />
              <span style={{ color: '#727478' }}>Jumlah harga</span>
            </td>
            <td>
              Total price/ <br />
              <span style={{ color: '#727478' }}>Jumlah harga</span>
            </td>
            <td />
          </tr>
        </thead>
        <tbody>
          {surchargeData?.length > 0 ? (
            surchargeData?.map((item, index) => (
              <tr>
                <td>{item?.code}</td>
                <td>{item?.description}</td>
                <td>{item?.rate}</td>
                <td>
                  RM{(+totalRateForSource(item).totalRateBysource?.toFixed(2))?.toLocaleString()}
                </td>
                <td>
                  RM
                  {(+totalRateForSource(item).totalRateBysourceVendor?.toFixed(
                    2,
                  ))?.toLocaleString()}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {!pdf && (
                    <IconButton
                      style={{
                        backgroundColor: '#F8F9F9',
                        borderRadius: '8px',
                        border: '1px solid #E1E1E1',
                        padding: '9px',
                      }}
                      variant="contained"
                      title="Delete surcharge"
                      className="outlined-button mr-2"
                      onClick={() => deleteItemHandler(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" style={{ textAlign: 'center' }}>
                No data
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" />
            <td style={{ fontWeight: 600 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!pdf && (
                  <Tooltip
                    color="black"
                    content={<span>Total surcharge = Sum (total price)</span>}
                  />
                )}
                <span className="ml-2">Total surcharge</span>
              </div>
            </td>
            <td style={{ fontWeight: 600 }}>
              <span style={{ fontWeight: 700 }}>
                RM{parseFloat(surchargeTotalPrice?.surchargeTotalOrder).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </span>
            </td>
            <td style={{ fontWeight: 700 }}>
              RM{parseFloat(surchargeTotalPrice?.surchargeTotalVendor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </td>
            <td />
          </tr>
          <tr>
            <td colSpan="2" />
            <td style={{ fontWeight: 600 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!pdf && (
                  <Tooltip
                    color="black"
                    content={<span>Sub total = Total bills + Total surcharge</span>}
                  />
                )}
                <span className="ml-2">Sub total</span>
              </div>
            </td>
            <td style={{ fontWeight: 600 }}>
              <span style={{ fontWeight: 700 }}>
                RM{parseFloat(subTotal?.subTotalOrder).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </span>
            </td>
            <td style={{ fontWeight: 700 }}>
              RM{parseFloat(subTotal?.subTotalVendor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </td>
            <td />
          </tr>
        </tfoot>
      </table>
      {openSurchargeModal && <AddNewSurchargeModal />}
    </div>
  );
}
