import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@material-ui/core';
import {  setBillSummaryData } from '../../redux/features/SOR/SORSlice'
import DeleteIcon from '../../assets/svg-icons/DeleteIcon'

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */


function SummaryBillsItem({ item, pdf, index }) {
    const { summaryBillData } = useSelector(state => state.SOR)
    const dispatch = useDispatch()

    const deleteItemHandler = () => {
        dispatch(dispatch(setBillSummaryData(summaryBillData.filter((_, i) => i !== index))))
    }
    console.log("item", item);
    
    return (
        <tr>
            <td>{item?.work_order_cmms}</td>
            <td style={{fontSize: "12px" }}>{item?.work_description}</td>
            <td>RM{parseFloat(item?.grand_total_first).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
            <td>RM{parseFloat(item?.grand_total_second).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
            <td>{Math.round(((+item?.grand_total_second / +item?.grand_total_first)* 100))} %</td>
            <td>
                {!pdf && <IconButton
                    style={{
                        backgroundColor: '#F8F9F9',
                        borderRadius: '8px',
                        border: '1px solid #E1E1E1',
                        padding: "9px"
                    }}
                    variant="contained"
                    title="Delete item"
                    className='outlined-button mr-2'
                    onClick={() => deleteItemHandler()}
                ><DeleteIcon />
                </IconButton>}
            </td>

        </tr>
    )
}

export default SummaryBillsItem