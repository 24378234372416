import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tnbLogo from '../../assets/img/tnbLogo.png';
import WorkDataPdf from './workDataPdf';
import BillsItems from './billsItems';
import Surcharge from './surcharge';
import Penalty from './penalty';
import Summary from './epqSummry';
import ArrowRightIcon from '../../assets/svg-icons/ArrowRightIcon';
import { setSORTab } from '../../redux/features/SOR/SORSlice';

const EbqPdf = forwardRef((props, ref) => {
  const { billWorkData , singleBill} = useSelector((state) => state.SOR);
  const dispatch = useDispatch();
  return (
    <div
      className="card-body"
      style={{ display: props.isPreview ? '' : 'none', paddingTop: '20px' }}
      ref={ref}
    >
      {props.isPreview && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
            marginLeft: '10px',
          }}
        >
          <button type="button" onClick={() => dispatch(setSORTab('boq'))}>
            <Link to="/ebq" style={{ color: '#727478' }}>
            Bill of quantity
            </Link>
          </button>
          <ArrowRightIcon />
          <button type="button" style={{ color: '#36383C', fontWeight: '600', cursor:"text" }} onClick={() => dispatch(setSORTab('boq'))}>
            {/* <Link to="/ebq" style={{ color: '#36383C', fontWeight: '600' }}> */}
              {singleBill?.status === 'draft' ? 'Draft Preview' : 'Preview'}
            {/* </Link> */}
          </button>
        </div>
      )}
      <div className="epq-pdf-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 className="ml-2 mb-0">Bill of quantity</h3>
        </div>
        <img src={tnbLogo} alt="TNB logo" />
      </div>
      <WorkDataPdf />
      <hr />
      <div className="epq-large-header-text">
        <h3>{billWorkData?.title}</h3>
        <p>{billWorkData?.description}</p>
      </div>
      <BillsItems pdf={props.pdf} />
      <hr />
      <Surcharge pdf={props.pdf} />
      <hr />
      <Penalty pdf={props.pdf} />
      <hr />
      <Summary pdf={props.pdf} />
      <hr />
      <div style={props.pdf ? { breakInside: 'avoid' } : {}} className="epq-signature">
        <div className="epq-signature-item">
          <p>Provided by :</p>
          <div>
            <p>Name & Signature</p>
            <p>IFM Technician</p>
            <p>Date:</p>
          </div>
        </div>
        <div className="epq-signature-item">
          <p>Approved by :</p>
          <div>
            <p>Name & Signature</p>
            <p>Contractor Representative</p>
            <p>Date:</p>
          </div>
        </div>
        <div className="epq-signature-item">
          <p>Certified by :</p>
          <div>
            <p>Name & Signature</p>
            <p>IFM Executive</p>
            <p>Date:</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EbqPdf;
