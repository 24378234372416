import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import WorkData from '../workData';
import BillsItems from '../billsItems';
import Penalty from '../penalty';
import Summary from '../epqSummry';
import Surcharge from '../surcharge';
import {
  createBill,
  getBillById,
  getBillWorkOrderType,
  reset,
  resetState,
  setIsPrint,
  setSORTab,
  updateBill,
} from '../../../redux/features/SOR/SORSlice';
import Loader from '../../../components/loader/loader';
import { migrateBillData } from '../ebqUtils';
import EbqPdf from '../ebqPdf';
import NewModal from '../../../components/modals/NewModal';
import ArrowRightIcon from '../../../assets/svg-icons/ArrowRightIcon';

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */

function bill() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const pdf = true;
  const isPreview = pathname?.split('/')[3] === 'preview';
  const [savePopUp, setSavePopUp] = useState('');
  const [modalbodyMsg, setModalBodyMsg] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const {
    billData,
    billTotalPrice,
    billWorkData,
    surchargeRates,
    penaltyData,
    subTotal,
    surchargeTotalPrice,
    penaltyTotalPrice,
    isBillCreateSuccess,
    isBillCreateError,
    singleBill,
    isBillGetLoading,
    isPrint,
  } = useSelector((state) => state.SOR);

  useEffect(() => {
    dispatch(getBillWorkOrderType());
    dispatch(setSORTab('sor'));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getBillById(id));
    }
  }, [id]);

  useEffect(() => {
    if (singleBill) {
      migrateBillData(singleBill, dispatch);
    }
  }, [singleBill]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    onAfterPrint: () => {
      dispatch(resetState());
    },
  });

  const SaveDraft = () => {
    setSavePopUp('Draft');
    setModalBodyMsg('You can still edit the bill when you save it as a draft.');
    setModalTitle('Save as draft?');
  };
  const SavePublished = () => {
    dispatch(setIsPrint(true));
    setSavePopUp('Published');
    setModalBodyMsg(
      "Once published, you can't edit the form anymore. To make changes, you will need to create a new bill.",
    );
    setModalTitle('Publish the bill?');
  };

  const closePopUp = () => {
    setSavePopUp('');
    setModalBodyMsg('');
    setModalTitle('');
  };

  const handleSave = () => {
    const objData = {
      work_title: billWorkData.title,
      work_description: billWorkData.description,
      outline_agreement_no: billWorkData.agreementNo,
      job_num: billWorkData.jobNum,
      work_order_cmms: billWorkData.order,
      work_order_type: billWorkData.orderType,
      location: billWorkData.location,
      surcharges: surchargeRates?.map((item) => {
        return {
          code: item.code,
          description: item.description,
          rate: item.rate,
          total_price_first: item.totalRateBysource?.toFixed(2),
          total_price_second: item.totalRateBysourceVendor?.toFixed(2),
        };
      }),
      penalties: penaltyData?.map((item) => {
        return {
          parameter: item.parameter,
          period_start: item.startDate || null,
          period_end: item.endDate || null,
          is_active: item.active,
          penalty_type: item.penaltyQuantumType === 'price' ? 'PRICE' : 'PERCENTAGE',
          penalty_value: (+item.penaltyQuantumRate).toFixed(2),
          total_price: +(
            item?.penaltyQuantumType === 'price'
              ? parseFloat(item?.penaltyQuantumRate || 0) // Ensure penaltyQuantumRate is a valid number
              : (parseFloat(item?.penaltyQuantumRate || 0) *
                  parseFloat(subTotal?.subTotalVendor || 0)) /
                100
          ).toFixed(2),
        };
      }),
      bill_sor_items: billData?.map((item) => {
        return {
          sor: item.id,
          remarks: item.remarkText || '',
          quantity_first: +item.quantity,
          total_price_first: (+item.totalPrice)?.toFixed(2),
          quantity_second: (+item.vendorQuantity)?.toFixed(2),
          total_price_second: (+item.vendorTotalPrice)?.toFixed(2),
          discount_rate: (+item.discountRate).toFixed(2),
        };
      }),
      total_bills_first: +billTotalPrice?.billTotalOrder?.toFixed(2),
      total_bills_second: +billTotalPrice?.billTotalVendor?.toFixed(2),
      total_surcharge_first: +surchargeTotalPrice?.surchargeTotalOrder.toFixed(2),
      total_surcharge_second: +surchargeTotalPrice?.surchargeTotalVendor.toFixed(2),
      sub_total_first: +subTotal?.subTotalOrder.toFixed(2),
      sub_total_second: +subTotal?.subTotalVendor.toFixed(2),
      total_penalty: +penaltyTotalPrice.toFixed(2),
      grand_total_first: +subTotal?.subTotalOrder.toFixed(2),
      grand_total_second: +(subTotal?.subTotalVendor - penaltyTotalPrice).toFixed(2),
      status: 'draft',
    };
    if (savePopUp === 'Draft') {
      if (!billWorkData.title || !billWorkData.jobNum) {
        toast.error('Please fill the Work title and Job number fields and try again', {
          toastId: 'integrationSyncStarted',
        });
        closePopUp();
        return;
      }
    }
    if (savePopUp === 'Published') {
      objData.status = 'published';
      if (
        !billWorkData.title ||
        !billWorkData.description ||
        !billWorkData.agreementNo ||
        !billWorkData.jobNum ||
        !billWorkData.order ||
        !billWorkData.orderType ||
        !billWorkData.location
      ) {
        toast.error('Please fill the required fields and try again', {
          toastId: 'integrationSyncStarted',
        });
        closePopUp();
        return;
      }
    }
    if (id) {
      dispatch(updateBill({ id, ...objData }));
    } else {
      dispatch(createBill(objData));
    }
  };

  useEffect(() => {
    if (isBillCreateSuccess) {
      if (isPrint) {
        handlePrint();
      }
      dispatch(setSORTab('boq'));
      history.push(`/ebq`);
      dispatch(resetState());
      dispatch(reset());
      closePopUp();
    } else if (isBillCreateError) {
      
      toast.error(isBillCreateError, {
        toastId: 'integrationSyncStarted',
      });
      dispatch(setIsPrint(false))
      closePopUp();
      dispatch(reset());
    }
  }, [isBillCreateSuccess, isBillCreateError]);

  return isBillGetLoading ? (
    <Loader />
  ) : (
    <>
      {savePopUp && (
        <NewModal
          showModal={savePopUp}
          handleClose={() => setSavePopUp('')}
          handleCreateSave={handleSave}
          primaryButtonText="Save"
          modalTitle={modalTitle}
        >
          {modalbodyMsg}
        </NewModal>
      )}
      <div style={isPreview ? { display: 'none' } : {}} className="p-2">
        {billData?.length > 0 && singleBill?.status !== 'published' ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '10px',
                marginLeft: '10px',
              }}
            >
              <button type="button" onClick={() => dispatch(setSORTab('sor'))}>
                <Link to="/ebq" style={{ color: '#727478' }}>
                  Schedule of rate
                </Link>
              </button>
              <ArrowRightIcon />
              <button type="button" onClick={() => dispatch(setSORTab('boq'))}>
                <Link to="/ebq" style={{ color: '#36383C', fontWeight: '600' }}>
                  Bill of quantity
                </Link>
              </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h5 className="ml-2 mb-0">Bill of Quantity</h5>
              {singleBill?.status === 'draft' && (
                <button
                  className="new-btn"
                  style={{
                    backgroundColor: '#72747829',
                    borderColor: 'white',
                    width: '70px',
                    padding: '4px 8px',
                  }}
                  type="button"
                >
                  Draft
                </button>
              )}
            </div>
            <WorkData />
            <hr />
            <BillsItems />
            <hr />
            <Surcharge />
            <hr />
            <Penalty />
            <hr />
            <Summary />
            <div
              style={{
                marginBottom: '100px',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <button
                type="button"
                title="Draft"
                onClick={SaveDraft}
                className="new-btn secondary-btn mr-2"
                style={{ color: '#36383C' }}
              >
                Save as draft
              </button>
              <button
                type="button"
                title="Publish"
                onClick={SavePublished}
                className="new-btn primary-btn"
              >
                Publish
              </button>
            </div>
          </>
        ) : (
          <div className="bill-no-items-container">
            <div className="bill-no-items-content">
              <h1 className="bill-no-items-title">
                {singleBill?.status === 'published'
                  ? 'This bill is already published'
                  : "There's no items on the bill"}
              </h1>
              <Link to="/ebq">
                <button
                  type="button"
                  className="bill-no-items-button"
                  onClick={() => dispatch(setSORTab('boq'))}
                >
                  Back to BOQ Page
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <EbqPdf isPreview={isPreview} ref={componentRef} pdf={pdf} billWorkData={billWorkData} />
    </>
  );
}

export default bill;
