import React from "react";
import SectionTitle from "./SectionTitle";
import WOvsPM from "./WOvsPM";
import WO from "./WO";
import PM from "./PM";
import WOCompliance from "./WOCompliance";

export default function WOPM({wovspmData, summaryData, complianceData}) {
  return (
    <div style={{ marginTop: "24px" }}>
      <SectionTitle title="Work order & Preventive maintenance" />
      <div className='analytic-cards-container'>
        <WOvsPM wovspmData={wovspmData} />
        <WO summaryData={summaryData} />
        <PM summaryData={summaryData} />
        <WOCompliance complianceData={complianceData} />
      </div>
    </div>
  );
}
