/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-empty-pattern */

import React from "react";
import IconInformation from "./icons/IconInformation";
import WOChart from "./WOChart";
import Tooltip from "./Tooltip";

export default function WO({ summaryData }) {
  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        // backgroundColor: "#F8F9F9",
        backgroundColor: "white",
        border: '1px solid #919EAB29',
        borderRadius: "12px",
        flexDirection: "column",
        alignItems: "center",
        minHeight: '300px',
        minWidth: '330px',
        boxShadow: '0px 1px 2px 0px #919EAB29'
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
          width: "100%",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#36383C",
            }}
          >
            WO
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#757980",
              marginTop: "8px",
            }}
          >
            Data of work order status
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <Tooltip icon={<IconInformation color="#727478" />}>
            <h6 style={{ fontSize: "12px", color: "#4D4D4D" }}>
            Detail percentage of Total Work Order.
            </h6>
            <p style={{ fontSize: "12px", color: "#727478", marginBottom: "0px" }}>
            Wo status in percentage based on date selected
            </p>
          </Tooltip>
        </div>
      </div>
      <div style={{ height: "100%", width: "100%" }}>
        <WOChart summaryData={summaryData} />
      </div>
    </div>
  );
}