/* eslint-disable react/no-array-index-key */

import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

const COLORS = ['#D9314AA3', '#727478A3'];

// Custom legend rendering function
const renderLegend = (props) => {
  const { payload } = props; // Payload contains legend data
  const totalValue = payload.reduce((acc, item) => acc + item.payload.value, 0);
  return (
    <ul
      style={{
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {payload.map((entry, index) => {
        let percent = 0;
        if (totalValue > 0) {
          percent = ((entry.payload.value / totalValue) * 100).toFixed(1); // Calculate percentage
        }

        return (
          <li
            key={`item-${index}`}
            style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
          >
            {/* Circle Icon */}
            <span
              style={{
                display: 'inline-block',
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: entry.color,
                marginRight: 8,
              }}
            />
            {/* Legend Text with Percentage */}
            <span style={{ color: '#4A4C50', fontSize: '14px', fontWeight: '400' }}>
              {entry.value} <span style={{ fontWeight: '600' }}>{percent}%</span>
            </span>
          </li>
        );
      })}
    </ul>
  );
};

// const data = [
//   { name: "WO", value: 1400 },
//   { name: "PM", value: 600 },
// ];

export default function WOvsPMChart(
  { wovspmData }
) {
  const data = [
    { name: 'WO', value: wovspmData[0] ?? 0 },
    { name: 'PM', value: wovspmData[1] ?? 0},
  ];

  // Calculate total value for percentage computation
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          cy={85}
          startAngle={180}
          endAngle={0}
          innerRadius={70}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          cornerRadius={5}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* Add Custom Legend */}
        <Legend
          content={renderLegend}
          // wrapperStyle={{left: 55, bottom: 3}}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
