/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsForDashboard } from '../../../redux/features/Analytics/analyticsSlice';
import Loader from '../../loader/loader';
import WOSummary from './components/WOSummary';
import PMSummary from './components/PMSummary';
import WOPM from './components/WOPM';
import NewOverallProgress from './components/NewOverallProgress';
import TrendWO from './components/TrendWO';

function AnalyticMainPage({ pdfReport }) {
  const dispatch = useDispatch();
  const {
    isAnalyticsLoading,
    analytics,
    dateWord,
    filter,
    site,
    location,
    staff,
    categoryFilter,
    typeFilter,
    customDate,
  } = useSelector((state) => state.analytics);

  const [intervals, setIntervals] = useState('day');
  const [workCategory, setWorkCategory] = useState([0, 0]);
  const [totalWorkOrder, setTotalWorkOrder] = useState(0);
  const [summaryData, setSummaryData] = useState({});
  const [overallData, setOverallData] = useState({});
  const [comparingData, setComparingData] = useState({});
  const [openWork, setOpenWork] = useState([]);
  const [closeWork, setCloseWork] = useState([]);
  const trendWorkData = {
    open: [],
    close: [],
    workDates: [],
  };
  useEffect(() => {
    dispatch(
      getAnalyticsForDashboard({
        filter:
          filter?.value === 'custom' ? customDate : filter?.value ? filter?.value : 'last7days',
        site: site?.value || null,
        location: location?.value || null,
        staff: staff?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  }, []);
  // filter, custom_start, custom_end, site, location, staff, typeFilter, categoryFilter

  useEffect(() => {
    setWorkCategory([
      parseFloat(analytics?.tickets_based_on_category?.preventive),
      parseFloat(analytics?.tickets_based_on_category?.corrective),
    ]);
    setOverallData(analytics?.overall_progress);
    setTotalWorkOrder(analytics?.tickets_count?.total_tickets || 0);
    setSummaryData(analytics?.ticket_count_by_category);
    setComparingData(analytics?.comparing_data);
    if (filter === null || filter?.value === 'last15days' || dateWord === 'custom') {
      setIntervals('day');
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);
    } else if (filter?.value === 'last30days') {
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);

      setIntervals('Day');
    } else if (filter?.value === 'last365days') {
      setOpenWork(analytics?.tickets_by_status?.open_month);
      setCloseWork(analytics?.tickets_by_status?.close_month);
      setIntervals('month');
    }
  }, [analytics, intervals, filter]);
  function handleWork() {
    openWork?.forEach((work) => {
      trendWorkData.workDates.push(work[1].split('T')[0]);
      trendWorkData.open.push(work[0]);
    });
    closeWork?.forEach((work) => {
      trendWorkData.close.push(work[0]);
    });
  }
  handleWork();

  return isAnalyticsLoading ? (
    <Loader />
  ) : (
    <div className="row col-lg-12 col-12 mx-auto mt-3">
      <div className="col-12">
        <WOSummary
          totalWO={summaryData?.total_workorder}
          percentTotalWO={comparingData?.total_workorder}
          completedWO={summaryData?.total_completed_workorder}
          percentCompletedWO={comparingData?.completed_workorder}
          openWO={summaryData?.total_open_workorder}
          percentOpenWO={comparingData?.open_workorder}
          onHoldWO={summaryData?.total_on_hold_workorder}
          percentOnHoldWO={comparingData?.on_hold_workorder}
          onProgressWO={summaryData?.total_on_progress_workorder}
          percentOnProgressWO={comparingData?.on_progress_workorder}
        />
      </div>
      <div className="col-12">
        <PMSummary
          totalWO={summaryData?.total_pm}
          percentTotalWO={comparingData?.total_pm}
          completedWO={summaryData?.total_completed_pm}
          percentCompletedWO={comparingData?.completed_pm}
          openWO={summaryData?.total_open_pm}
          percentOpenWO={comparingData?.open_pm}
          onHoldWO={summaryData?.total_on_hold_pm}
          percentOnHoldWO={comparingData?.on_hold_pm}
          onProgressWO={summaryData?.total_in_progress_pm}
          percentOnProgressWO={comparingData?.in_progress_pm}
        />
      </div>
      <div className="col-12">
        <WOPM
          wovspmData={workCategory}
          summaryData={summaryData}
          complianceData={analytics?.ticket_compliance?.compliance}
        />
      </div>
      <div className="col-12">
        <NewOverallProgress
          overallData={overallData?.overall}
          pmData={overallData?.pm_completion}
          woData={overallData?.wo_completion}
        />
      </div>
      <div className="col-12">
        <TrendWO trendWorkData={trendWorkData} intervals={intervals} />
      </div>
    </div>
  );
}

export default AnalyticMainPage;
