import React from 'react'

function EBQIcon({ fillColor }) {    
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66699 2.66665C9.66699 3.40303 7.87613 3.99998 5.66699 3.99998C3.45785 3.99998 1.66699 3.40303 1.66699 2.66665M9.66699 2.66665C9.66699 1.93027 7.87613 1.33331 5.66699 1.33331C3.45785 1.33331 1.66699 1.93027 1.66699 2.66665M9.66699 2.66665V5.33331M1.66699 2.66665V5.33331C1.66699 6.06969 3.45785 6.66665 5.66699 6.66665C7.87613 6.66665 9.66699 6.06969 9.66699 5.33331M12.3337 6.59078C12.3337 7.32716 10.5428 7.92411 8.33366 7.92411C6.12452 7.92411 4.33366 7.32716 4.33366 6.59078V9.25747M12.3337 6.59078C12.3337 6.01024 11.2206 5.51635 9.66699 5.33331M12.3337 6.59078V9.25747C12.3337 9.99385 10.5428 10.5908 8.33366 10.5908C6.12452 10.5908 4.33366 9.99385 4.33366 9.25747M1.66699 10.5149C1.66699 11.2513 3.45785 11.8483 5.66699 11.8483C7.87613 11.8483 9.66699 11.2513 9.66699 10.5149V13.1816C9.66699 13.918 7.87613 14.515 5.66699 14.515C3.45785 14.515 1.66699 13.918 1.66699 13.1816V10.5149ZM1.66699 10.5149C1.66699 9.93439 2.78007 9.44051 4.33366 9.25747"
                stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
                strokeWidth="1.8"
            />
        </svg>
    )
}

export default EBQIcon