/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  copyBOQ,
  deleteBillById,
  getBillById,
  getBills,
  reset,
  resetState,
  setBOQCurrentPage,
  setBOQRowPerPage,
  setIsPrint,
  setBillSummaryData,
} from '../../../redux/features/SOR/SORSlice';
import EpqItem from '../epqItem';
import EpqTable from '../epqTable';
import Loader from '../../../components/loader/loader';
import Pagination from '../../../components/pagination/pagination';
import IconDownload from '../../../assets/svg-icons/IconDownload';
import DeleteIcon from '../../../assets/svg-icons/DeleteIcon';
import EditIcon from '../../../assets/svg-icons/EditIcon';
import { migrateBillData } from '../ebqUtils';
import EbqPdf from '../ebqPdf';
import NewModal from '../../../components/modals/NewModal';
import CopyIcon from '../../../assets/svg-icons/CopyIcon';

function BOQ() {
  const dispatch = useDispatch();
  const pdf = true;
  const {
    openSORModal,
    // SORTab,
    BOQSearchValue,
    BOQDateFilterState,
    BOQStatus,
    BOQData,
    BOQRowPerPage,
    BOQCurrentPage,
    singleBill,
    isPrint,
    isBillDeleteSuccess,
    isBillDeleteError,
    summaryBillData,
  } = useSelector((state) => state.SOR);

  const [iSShowFilter, setISShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isShowPopUp, setIsShowPopUp] = useState({ flag: false, type: null, id: null });
  const history = useHistory();

  const getDataRows = async () => {
    const filters = {
      rowPerPage: BOQRowPerPage,
      currentPage: BOQCurrentPage,
      searchValue: BOQSearchValue,
      startDate: BOQDateFilterState?.find((item) => item.startDate)?.startDate
        ? moment(BOQDateFilterState?.find((item) => item.startDate)?.startDate).format('YYYY-MM-DD')
        : '',
      endDate: BOQDateFilterState?.find((item) => item.startDate)?.startDate
        ? moment(BOQDateFilterState?.find((item) => item.endDate)?.endDate).format('YYYY-MM-DD')
        : '',
      status: BOQStatus,
    };
    await dispatch(getBills(filters));
    setIsLoading(false);
  };

  useEffect(() => {
    getDataRows();
  }, [BOQSearchValue, BOQRowPerPage, BOQCurrentPage]);

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (
      cell.column.id !== 'checkbox' &&
      cell.column.id !== 'actions' &&
      cell.column.id !== 'billSummary'
    ) {
      history.push(`/ebq/bill/preview/${selectedRow?.id}`);
    }
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    onAfterPrint: () => {
      dispatch(resetState());
    },
  });

  const handleClickEdit = (id) => {
    history.push(`/ebq/bill/${id}`);
  };
  const handleClickDownload = async (id) => {
    const theBill = await dispatch(getBillById(id));
    migrateBillData(theBill.payload, dispatch);
    dispatch(setIsPrint(true));
  };

  const handleClickCopy = (id) => {
    setIsShowPopUp({ flag: true, type: 'copy', id });
  };
  const handleClickDelete = async (id) => {
    setIsShowPopUp({ flag: true, type: 'delete', id });
  };

  const handlePopupAction = async () => {
    if (isShowPopUp.type === 'delete') {
      await dispatch(deleteBillById(isShowPopUp.id));
    } else if (isShowPopUp.type === 'copy') {
      await dispatch(copyBOQ(isShowPopUp.id));
      setIsShowPopUp({ flag: false, type: null, id: null });
      getDataRows();
    }
  };

  const handleCheckboxChange = (selectedRow) => {
    const row = selectedRow.row.original;
    // Log the current state of summaryBillData
    const findIndex = summaryBillData.findIndex((item) => item.id === row.id);
    if (findIndex === -1) {
      dispatch(
        setBillSummaryData([
          ...summaryBillData,
          {
            ...row,
            discountRate: 0,
            quantity: 1,
            totalPrice: row?.rate,
            vendorQuantity: 1,
            vendorTotalPrice: row?.rate,
          },
        ]),
      );
    } else {
      const filteredItem = summaryBillData.filter((item) => item.id !== row.id);
      dispatch(setBillSummaryData(filteredItem));
    }
  };

  const isChecked = (selectedRow) => {
    const row = selectedRow.row.original;
    const summaryBillDataCopy = [...summaryBillData];
    const findIndex = summaryBillDataCopy.findIndex((item) => item.id === row.id);
    if (findIndex !== -1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isBillDeleteSuccess) {
      toast.success('Bill Deleted Successfully', {
        toastId: 'integrationSyncStarted',
      });
    }
    if (isBillDeleteError) {
      toast.error('Something went wrong while Deleting Bill', {
        toastId: 'integrationSyncStarted',
      });
    }
    dispatch(reset());
    setIsShowPopUp({ flag: false, type: null, id: null });
  }, [isBillDeleteSuccess, isBillDeleteError]);

  useEffect(() => {
    if (singleBill && isPrint) {
      handlePrint();
    }
  }, [singleBill, isPrint]);

  const cols = React.useMemo(
    () => [
      {
        id: 'checkbox',
        Cell: (row) => {
          return (
            <input
              type="checkbox"
              style={{ width: '20px', height: '20px' }}
              onChange={() => handleCheckboxChange(row)}
              checked={isChecked(row)}
              disabled={row?.row?.original?.status === 'draft'}
            />
          );
        },
      },
      {
        Header: 'Job no.',
        accessor: 'jobNo',
      },
      {
        Header: 'Work order cmms',
        accessor: 'workOrderCmms',
      },
      {
        Header: 'Work description',
        accessor: 'workDescription',
      },
      {
        Header: 'Work title',
        accessor: 'workTitle',
      },
      {
        Header: 'Created date',
        Cell: ({ row }) => {
          return <span>{moment(row?.original?.createdDate).format('MMMM DD, YYYY hh:mm a')}</span>;
        },
      },
      {
        Header: 'Created by',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original.createdBy?.first_name} {row?.original.createdBy?.last_name}
            </span>
          );
        },
      },
      {
        Header: 'Bill summary(s)',
        id: 'billSummary',
        Cell: ({ row }) => {
          return row.original.billSummary?.length > 0
            ? row.original.billSummary?.map((bill) => {
                return (
                  bill?.status === 'published' && (
                    <Link to={`/ebq/bill-summary/preview/${bill?.id}`}>
                      <span
                        style={{
                          color: '#4472B8',
                          padding: '4px 8px',
                          borderRadius: '4px',
                          backgroundColor: '#4472B829',
                          marginRight: '4px',
                        }}
                      >
                        {bill?.job_num}
                      </span>
                    </Link>
                  )
                );
              })
            : '_';
        },
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          return (
            <button
              style={{
                padding: '4px 8px',
                borderRadius: '4px',
                width: '80px',
                backgroundColor: row?.original?.status === 'draft' ? '#72747829' : '#4472B829',
                color: row?.original?.status === 'draft' ? '#727478' : '#4472B8',
              }}
              type="button"
            >
              {row?.original?.status === 'draft' ? 'Draft' : 'Published'}
            </button>
          );
        },
      },
      {
        id: 'actions',
        Header: 'Actions',
        Cell: ({ row }) => {
          return (
            <>
              <button
                type="button"
                className="new-btn"
                title="Copy"
                onClick={() => handleClickCopy(row?.original?.id)}
              >
                {' '}
                <CopyIcon />{' '}
              </button>
              {row?.original?.status === 'draft' ? (
                <button
                  onClick={() => handleClickEdit(row?.original?.id)}
                  type="button"
                  className="new-btn"
                  title="Edit"
                >
                  {' '}
                  <EditIcon />{' '}
                </button>
              ) : (
                <button
                  type="button"
                  className="new-btn"
                  title="Download"
                  onClick={() => handleClickDownload(row?.original?.id)}
                >
                  {' '}
                  <IconDownload />{' '}
                </button>
              )}
              <button
                type="button"
                className="new-btn"
                title="Delete"
                onClick={() => handleClickDelete(row?.original?.id)}
              >
                {' '}
                <DeleteIcon />{' '}
              </button>
            </>
          );
        },
      },
    ],
    [summaryBillData],
  );

  const modifiedData = useMemo(
    () =>
      BOQData?.results?.length > 0
        ? BOQData?.results?.map((item) => {
            return {
              id: item?.id,
              jobNo: item?.job_num,
              workTitle: item?.work_title,
              status: item?.status,
              createdDate: item?.created_at,
              createdBy: item?.created_by,
              workOrderCmms: item?.work_order_cmms,
              workDescription: item?.work_description,
              billSummary: item?.related_bills_summary,
              ...item,
            };
          })
        : [],
    [BOQData],
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <EpqTable
        iSShowFilter={iSShowFilter}
        setISShowFilter={setISShowFilter}
        getSelectedRowwValues={getSelectedRowwValues}
        data={modifiedData}
        columns={cols}
        getDataRows={getDataRows}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Pagination
        totalRows={BOQData?.count || 0}
        rowPerPage={BOQRowPerPage}
        pageChangeHandler={setBOQCurrentPage}
        changeRowsPerPage={setBOQRowPerPage}
        currentPage={BOQCurrentPage}
      />
      {openSORModal && <EpqItem />}
      {isShowPopUp.flag && (
        <NewModal
          showModal={isShowPopUp.flag}
          handleClose={() => setIsShowPopUp({ flag: false, type: null, id: null })}
          handleCreateSave={handlePopupAction}
          primaryButtonText={isShowPopUp.type === 'delete' ? 'Delete' : 'Duplicate'}
          modalTitle={`${isShowPopUp.type === 'delete' ? 'Delete' : 'Duplicate'} this item?`}
        >
          This item will be {isShowPopUp.type === 'delete' ? 'permanently deleted' : 'duplicated'}.
        </NewModal>
      )}
      <EbqPdf ref={componentRef} pdf={pdf} />
    </>
  );
}

export default BOQ;
