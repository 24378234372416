/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faBriefcase } from '@fortawesome/fontawesome-free-solid';
import FilterField from './FilterField/FilterField';
import PieChart from './PieChart/PieChart';
import ProgressChart from './ProgressChart/ProgressChart';
import SemiCircleChart from './SemiCircleChart/SemiCircleChart';
import TrendWorkChart from './TrendWorkChart/TrendWorkChart';
import { getAnalyticsForDashboard, setDate } from '../../redux/features/Analytics/analyticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import './workOrderAnalytics.scss';
import Loader from '../loader/loader';

function WorkOrderAnalytics () {
  const dispatch = useDispatch();
  const {
    isAnalyticsLoading,
    analytics,
    dateWord,
    custom_start,
    custom_end,
    filter,
    site,
    location,
    staff,
    categoryFilter,
    typeFilter,
    customDate,
  } = useSelector(state => state.analytics);
  const [intervals, setIntervals] = useState('day');
  const [workCategory, setWorkCategory] = useState([0, 0]);
  const [totalWorkOrder, setTotalWorkOrder] = useState(0);
  const [totalCompletedTickets, setTotalCompletedTickets] = useState(0);
  const [openWorkOrder, setOpenWorkOrder] = useState(0);
  const [overDueWorkOrder, setOverDueWorkOrder] = useState(0);
  const [totalManHour, setTotalManHour] = useState('');
  const [workOrderCompletion, setWorkOrderCompletion] = useState(0);
  const [workOrderCompliance, setWorkOrderCompliance] = useState(0);
  const [openWork, setOpenWork] = useState([]);
  const [closeWork, setCloseWork] = useState([]);
  const trendWorkData = {
    open: [],
    close: [],
    workDates: [],
  };

  useEffect(() => {
    // if (dateWord === 'custom' || filter === 'custom') {
    // } else {
    //   dispatch(getAnalyticsForDashboard({filter, site, location, staff }));
    // }
    dispatch(
      getAnalyticsForDashboard({
        filter:
          filter?.value === 'custom' ? customDate : filter?.value ? filter?.value : 'last7days',
        site: site?.value || null,
        location: location?.value || null,
        staff: staff?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  }, [filter, custom_start, custom_end, site, location, staff, typeFilter, categoryFilter]);
  useEffect(() => {
    setWorkCategory([
      parseFloat(analytics?.tickets_based_on_category?.preventive),
      parseFloat(analytics?.tickets_based_on_category?.corrective, 10),
    ]);
    setTotalWorkOrder(analytics?.tickets_count?.total_tickets || 0);
    setTotalCompletedTickets(analytics?.tickets_count?.total_completed_tickets || 0);
    setOpenWorkOrder(analytics?.tickets_count?.total_open_tickets || 0);
    setOverDueWorkOrder(analytics?.tickets_count?.total_open_tickets_overdue || 0);
    setTotalManHour(analytics?.tickets_count?.total_man_hours);
    setWorkOrderCompletion(analytics?.ticket_completion?.completed || 0);
    setWorkOrderCompliance(analytics?.ticket_compliance?.compliance || 0);
    if (filter === null || filter?.value === 'last15days' || dateWord === 'custom') {
      setIntervals('day');
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);
    } else if (filter?.value === 'last30days') {
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);

      setIntervals('Day');
      // if (intervals === 'day') {
      //   setOpenWork(analytics?.tickets_by_status?.open_day);
      //   setCloseWork(analytics?.tickets_by_status?.close_day);
      // } else {
      //   setIntervals('week');
      //   setOpenWork(analytics?.tickets_by_status?.open_week);
      //   setCloseWork(analytics?.tickets_by_status?.close_week);
      // }
    } else if (filter?.value === 'last365days') {
      setOpenWork(analytics?.tickets_by_status?.open_month);
      setCloseWork(analytics?.tickets_by_status?.close_month);
      setIntervals('month');
      // if (intervals === 'month') {
      //   setOpenWork(analytics?.tickets_by_status?.open_month);
      //   setCloseWork(analytics?.tickets_by_status?.close_month);
      // } else {
      //   setIntervals('quarter');
      //   setOpenWork(analytics?.tickets_by_status?.open_quarter);
      //   setCloseWork(analytics?.tickets_by_status?.close_quarter);
      // }
    }
  }, [analytics, intervals, filter]);

  function handleWork () {
    openWork?.forEach(work => {
      trendWorkData.workDates.push(work[1].split('T')[0]);
      trendWorkData.open.push(work[0]);
    });
    closeWork?.forEach(work => {
      trendWorkData.close.push(work[0]);
    });
  }
  handleWork();
  const handleIntervals = e => {
    setIntervals(e.target.value);
  };
  return (
    <div className="row col-lg-10 col-12 mx-auto mt-3">
      <div className="col-12">
        <div className="mb-2">{/* <img src={trendWorkChartImg} alt="Chart" /> */}</div>
        <FilterField />
        {isAnalyticsLoading ? (
          <Loader />
        ) : (
          <>
            <div className="row col-lg-12 m-0 p-0">
              <div className="col-md-3 col-sm-6">
                <div className="card card-chart me-2">
                  <div className="card-header p-0">
                    <div className="info-box elevation-0 mb-0 col-12 info-box-styles" id="total">
                      <div className="info-box-content text-center">
                        <span className="info-box-text info-box-text-styles">
                          <span className="font-weight-bold">
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              size="lg"
                              style={{ color: '#E8811A', marginRight: '3px' }}
                            />{' '}
                            Total
                          </span>{' '}
                          Work Orders
                        </span>
                        <span
                          className="info-box-number"
                          style={{ fontSize: '20px', fontWeight: 'bold' }}
                        >
                          {totalWorkOrder}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card card-chart me-2">
                  <div className="card-header p-0">
                    <div
                      className="info-box elevation-0  mb-0 col-12 tab info-box-styles"
                      id="open"
                    >
                      <div className="info-box-content text-center">
                        <span className="info-box-text info-box-text-styles">
                          <span className="font-weight-bold">
                            {' '}
                            <FontAwesomeIcon
                              icon={faSpinner}
                              size="lg"
                              style={{ color: '#E8811A', marginRight: '3px' }}
                            />{' '}
                            Open
                          </span>{' '}
                          Work Orders
                        </span>
                        <span
                          className="info-box-number"
                          style={{ fontSize: '20px', fontWeight: 'bold' }}
                        >
                          {openWorkOrder}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card card-chart">
                  <div className="card-header p-0">
                    <div
                      className="info-box elevation-0  mb-0 col-12 tab info-box-styles"
                      id="close"
                    >
                      <div className="info-box-content text-center">
                        <span className="info-box-text info-box-text-styles">
                          <span className="font-weight-bold">
                            {' '}
                            <FontAwesomeIcon
                              icon={faClock}
                              size="lg"
                              style={{ color: '#E8811A', marginRight: '3px' }}
                            />{' '}
                            Overdue
                          </span>{' '}
                          Work Orders
                        </span>
                        <span
                          className="info-box-number"
                          style={{ fontSize: '20px', fontWeight: 'bold' }}
                        >
                          {overDueWorkOrder}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card card-chart">
                  <div className="card-header p-0">
                    <div
                      className="info-box elevation-0  mb-0 col-12 tab info-box-styles"
                      id="close"
                    >
                      <div className="info-box-content text-center">
                        <span className="info-box-text info-box-text-styles">
                          <span className="font-weight-bold">
                            {' '}
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="lg"
                              style={{ color: '#E8811A', marginRight: '3px' }}
                            />{' '}
                            Total
                          </span>{' '}
                          Completed Work Order
                        </span>
                        <span
                          className="info-box-number"
                          style={{ fontSize: '20px', fontWeight: 'bold' }}
                        >
                          {totalCompletedTickets}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Container of the chart*/}
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-body" style={{ paddingBottom: '0' }}>
                    <center>
                      <span className="info-box-text info-box-text-styles">
                        <span className="font-weight-bold">Work Orders by Category</span>
                      </span>
                      <div>
                        <div id="chart">
                          <PieChart data={workCategory} totalWorkOrder={totalWorkOrder} />
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-body" style={{ paddingBottom: '0px' }}>
                    <center>
                      <span className="info-box-text info-box-text-styles">
                        <span className="font-weight-bold">Work Order Completion</span>
                      </span>
                      <div>
                        <div id="chart">
                          <ProgressChart data={workOrderCompletion} />
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-body" style={{ paddingBottom: '0px' }}>
                    <center>
                      <span className="info-box-text info-box-text-styles">
                        <span className="font-weight-bold">Work Orders Compliant</span>
                      </span>

                      <div>
                        <div id="chart">
                          <SemiCircleChart data={workOrderCompliance} />
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <center>
                      <div className="">
                        <center>
                          <span className="info-box-text info-box-text-styles mt-2">
                            <span className="font-weight-bold">Trend of Work Orders</span>
                          </span>
                        </center>
                      </div>
                    </center>
                    <div className="" style={{ marginTop: '-30px' }}>
                      {/* {filter === 'last30days' && (
                        <>
                          <button
                            onClick={handleIntervals}
                            id="{{data.value}}_complition"
                            className="btn tertiary-btn float-right ml-2"
                            title="Time interval"
                            value="day"
                            type="button"
                          >
                            Day
                          </button>
                          <button
                            onClick={handleIntervals}
                            id="{{data.value}}_complition"
                            className="btn tertiary-btn float-right ml-2"
                            title="Time interval"
                            value="week"
                            type="button"
                          >
                            Week
                          </button>
                        </>
                      )} */}
                      {/* {filter === 'last365days' && (
                        <>
                          <button
                            onClick={handleIntervals}
                            id="{{data.value}}_complition"
                            className="btn tertiary-btn float-right ml-2"
                            title="Time interval"
                            value="month"
                            type="button"
                          >
                            Month
                          </button>
                          <button
                            onClick={handleIntervals}
                            id="{{data.value}}_complition"
                            className="btn tertiary-btn float-right ml-2"
                            title="Time interval"
                            value="quarter"
                            type="button"
                          >
                            Quarter
                          </button>
                        </>
                      )} */}
                    </div>
                    <center>
                      <div className="mt-5">
                        <div id="chart">
                          <TrendWorkChart trendWorkData={trendWorkData} intervals={intervals} />
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default WorkOrderAnalytics;
