import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';
import { smartTryCatch } from '../../../utils';

const initState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  department :[],
  open: true,
};

export const getUserProfile = createAsyncThunk('getUserProfile', async (data, thunkAPI) => {
  const get = await smartTryCatch(userService.getUserProfile, data, thunkAPI);
  return get;
});

export const updateUserProfile = createAsyncThunk('updateUserProfile', async (data, thunkAPI) => {
  const update = await smartTryCatch(userService.updateUserProfile, data, thunkAPI);
  return update;
});
export const updateUserProfileImg = createAsyncThunk('updateUserProfileImg', async (data, thunkAPI) => {
  const update = await smartTryCatch(userService.updateUserProfileImg, data, thunkAPI);
  return update;
});


export const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    setOPen: (state, action) => {
      state.open = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(updateUserProfileImg.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateUserProfileImg.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user.profile.image = action.payload;
      })
      .addCase(updateUserProfileImg.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
        state.user = action.payload;
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        localStorage.removeItem('userToken');
      });
  },
});

export const { 
  reset ,
  setOPen
} = userSlice.actions;

export default userSlice.reducer;
