/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import Bowser from "bowser";
import { useDispatch, useSelector } from 'react-redux';
// import { Col, Row } from 'react-bootstrap'; //01829297069
// import moment from 'moment';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Card from '../../Ui/Card';
import { getUserProfile } from '../../redux/features/user/userSlice';
import {
  createQRLoc,
  getQRLocById,
  removeFile,
  // resetQR,
  setDescription,
  setEmail,
  setName,
  setPhone,
  setQrCategory,
  setQrSubCategory,
  // setQrCategory,
  setTitle,
} from '../../redux/features/qrCode/qrCodeSlice';
import icons from '../../utils/icon';
import './locationQrCode.css';
import doneLoge from '../../assets/img/done.jpg';

import logo from '../../assets/img/FOX-Logo3.png';
import DeleteIcon from '../../assets/svg-icons/DeleteIcon';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import CorrectIcon from '../../assets/svg-icons/CorrectIcon';
import { SelectCategories } from '../../components/select/select';
// import { BASE_URL } from '../../utils/axios';
// import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';

function LocationQRCode() {
  const [submited, setSubmited] = useState(false);
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector(state => state.user);
  const {
    locationService,
    title,
    name,
    email,
    phone,
    description,
    category,
    subCategory,
    attachment,
    isQRCreateSuccess,
    isQRGetError,
  } = useSelector(state => state.QRService);
  // const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchPramId = query.get('uuid');

  const [labels, setLabels] = useState([])

  useEffect(() => {
    const lbs = locationService?.failure_code?.map((item, index) => {
      return { ...item, label: item.name, selected: false }
    })
    setLabels(lbs)
  }, [locationService])

  const uuid = searchPramId?.slice(0, searchPramId.length - 1);

  useEffect(() => {
    // dispatch(getWorkOrderCategoryByAuthentication());
    dispatch(getUserProfile());
    dispatch(getQRLocById(uuid));
  }, [dispatch]);

  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isQRGetError) {
      if (!searchPramId && user) {
        history.push('/');
      } else if (!searchPramId && !user) {
        history.push('/login');
      }
    }
  }, [searchPramId, user, isLoading, isQRGetError]);

  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };
  const handelName = e => {
    dispatch(setName(e.target.value));
  };
  const handelEmail = e => {
    dispatch(setEmail(e.target.value));
  };
  const handelPhone = e => {
    dispatch(setPhone(e.target.value));
  };
  const handelCategory = e => {
    dispatch(setQrCategory(e.target.value));
  };
  const handelSubCategory = e => {
    dispatch(setQrSubCategory(e.target.value));
  };
  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };

  // Attachments
  const [files, setFiles] = useState([]);
  const handleAttachmentFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleAttachmentDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const renderFilePreview = (file, index) => {
    const fileType = file.type;
    // Check if the file is an image
    if (fileType.startsWith("image/")) {
      return (
        <div className="attachment-preview" key={index}>
          <img
            src={URL.createObjectURL(file)}
            alt={`Preview ${index}`}
            className="preview-image"
          />
          <button type="button" className="delete-btn" onClick={() => handleAttachmentDelete(index)}>
            <DeleteIcon color="white" />
          </button>
        </div>
      );
    }
    // For document files like pdf, doc, etc.
    return (
      <div className="attachment-preview" key={index}>
        <div className="doc-preview">
          <span role="img" aria-label="document">
            📄
          </span>
          <p>{file.name}</p>
        </div>
        <button type="button" className="delete-btn" onClick={() => handleAttachmentDelete(index)}>
          <DeleteIcon color="white" />
        </button>
      </div>
    );
  };

  const handelSubmit = async e => {
    const selectedLabels = labels.filter(item => item.selected)
    const formdata = new FormData();
    formdata.append('title', title);
    formdata.append('name', name);
    formdata.append('email', email);
    formdata.append('phone', phone);
    formdata.append('category', category?.value || "");
    formdata.append('cust_type', subCategory?.value || "");
    formdata.append('description', description);
    if (files.length > 0) {
      formdata.append('attachment', files[0]);
    }
    if (selectedLabels.length > 0) {
      selectedLabels?.forEach((ele) => (
        formdata.append(`failure_code`, ele?.id)
      ))
    }
    formdata.append('uuid', uuid);
    e.preventDefault();
    // if (title && name && phone && email && searchPramId) {
    await dispatch(createQRLoc(formdata));
    setSubmited(true);
    // }
  };
  let locationArray = locationService?.location?.split(',').filter(e => e.trim() !== '');


  const [browserName, setBrowserName] = useState('');


  useEffect(() => {
    const getBrowserName = () => {
      const browser = Bowser.getParser(window.navigator.userAgent);
      setBrowserName(browser.getBrowserName());;
    };
    getBrowserName()
  })


  const pushToBrowserHomePage = () => {
    if (browserName === 'Microsoft Edge') {
      window.location.href = "https://ntp.msn.com/edge/ntp?locale=en-US&title=New%20tab&dsp=1&sp=Bing&startpage=1&PC=U531"
    }
    else {
      window.location.href = "https://www.google.com"
    }
  };


  const handleLabelClick = (id) => {
    const updatedItems = labels.map(item => {
      if (item.id === id) {
        return { ...item, selected: !item.selected }
      }
      return item
    })
    setLabels(updatedItems)
  }

  return (
    <>
      {!submited ? (
        <div style={{ backgroundColor: "#F9F9F9", marginTop: "-37px" }}>
          <section className="pt-3 sr-form" >
            {/* image title */}
            <section className="sr-form-title-wrapper">
              <div className="mb-3 sr-form-title-wrapper-image">
                <img
                  src={locationService?.company_logo}
                  alt="company logo"
                />
              </div>
              <div className="">
                <p
                  className='sr-form-title'
                >
                  Facility Service Request
                </p>
              </div>
              <div className="">
                <p
                  className='sr-form-p'
                >
                  We are happy to know about your concerns!
                </p>
              </div>
            </section>
            <form onSubmit={handelSubmit}>
              <section
                style={{
                  marginTop: '0',
                }}
              >
                <div>
                  <input
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Source Sans Pro',
                      fontWeight: 500,
                    }}
                    type="text"
                    value={`${locationService?.site} ${locationService.location ? locationArray : ''
                      }`}
                    disabled
                  />
                </div>
                <div className="mt-3">
                  <label className="font-weight-normal">Feedback or request title </label>
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Source Sans Pro',
                      fontWeight: 400,
                    }}
                    type="text"
                    onChange={handelTitle}
                    value={title}
                  />
                </div>
                <div className="mt-3">
                  <label className="font-weight-normal">Name </label>
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Source Sans Pro',
                      fontWeight: 400,
                    }}
                    type="text"
                    onChange={handelName}
                    value={name}
                  />
                </div>
                <div className="mt-3">
                  <label className="font-weight-normal">Email </label>
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Source Sans Pro',
                      fontWeight: 400,
                    }}
                    onChange={handelEmail}
                    type="email"
                    value={email}
                  />
                </div>
                <div className="mt-3">
                  <label className="font-weight-normal">Phone number </label>
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Source Sans Pro',
                      fontWeight: 400,
                    }}
                    onChange={handelPhone}
                    type="text"
                    value={phone}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-xs-12 mt-3">
                    <label className="font-weight-normal">
                      Category
                    </label>
                    <SelectCategories
                      dataArray={locationService?.category}
                      stateValue={category}
                      setState={setQrCategory}
                    />
                  </div>
                  <div className="col-lg-6 col-xs-12 mt-3">
                    <label className="font-weight-normal">Sub Category</label>
                    <SelectCategories
                      dataArray={locationService?.sub_category}
                      stateValue={subCategory}
                      setState={setQrSubCategory}
                    />
                  </div>
                </div>
                <div className="mt-3" >
                  <label className="font-weight-normal">Describe your concerns and location. Click suitable tags below. </label>
                  <div className="sr-form-textarea-wrapper">
                    <textarea
                      onChange={handelDescription}
                      placeholder='Your answer (eg. the light is blinking at level 1 lobby)'
                      value={description}
                    />
                    <div className='labels-wraper'>
                      {labels?.map((item, index) => (
                        <button
                          type="button"
                          className={item.selected ? 'selected' : ""}
                          onClick={() => handleLabelClick(item.id, index)}
                        >
                          {item.label} {item.selected ? <CorrectIcon color="#4472B8" /> : <PlusIcon />}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="pm-attach-container mt-3">
                  <p className="pm-attach-container-p">Attach your image here (only .jpg or .png -maximum size 5MB)</p>
                  <div className="attachments">
                    {/* {attachments?.length > 0 && attachments?.map((file) => renderResFilePreview(file))} */}
                    {files?.length > 0 && files?.map((file, index) => renderFilePreview(file, index))}
                    {files?.length === 0 && (
                      <div className="add-more">
                        <label htmlFor="file-upload">
                          <PlusIcon color="#9DA0A6" custom />
                          <input
                            type="file"
                            id="file-upload"
                            multiple
                            onChange={handleAttachmentFileChange}
                            style={{ display: "none" }}
                            accept="image/png, image/jpeg"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    className='sr-form-submit'
                    type="submit"
                    style={{
                      height: '40px',
                      backgroundColor: '#404040',
                      borderRadius: '8px',
                      padding: '10px 16px 10px 16px',
                      color: '#FFFFFF',
                      fontFamily: 'Source Sans Pro',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}

                  >
                    Submit
                  </button>
                </div>
              </section>
            </form>

            <div className="d-flex justify-content-center">
              <div
                style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 500,
                  lineHeight: '15px',
                  color: '#19191966',
                  marginTop: '30px',
                }}
              >
                <p style={{ fontSize: '16px' }} className="text-center">
                  Powered by FOX
                </p>
                <p style={{ fontSize: '14px' }} className="">
                  © Infinity Wave Sdn Bhd{' '}
                </p>
              </div>
            </div>
          </section>
        </div>
      ) : (
        isQRCreateSuccess ?
          <section
            style={{
              backgroundColor: '#F7F7F7',
              height: '100vh',

            }}
            className=" d-flex justify-content-center align-items-center"
          >
            <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
              <img src={doneLoge} alt="" />
              <p
                style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 600,
                  fontSize: '18px',
                  color: '#ADADAD',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                Thank you <br /> Submitted Successfully
              </p>
              <button
                type="button"
                style={{
                  backgroundColor: '#404040',
                  borderRadius: '8px',
                  padding: '10px 12px 10px 12px',
                  height: '40px',
                  width: '300px',
                  color: '#FFFFFFFF',
                  fontFamily: 'Source Sans Pro',
                }}
                onClick={pushToBrowserHomePage}
              >
                Done
              </button>
            </div>
          </section> :
          <section
            style={{
              backgroundColor: '#F7F7F7',
              height: '100vh',
            }}
            className=" d-flex justify-content-center align-items-center"
          >
            <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="300"
                height="300"
                fill="currentColor"
                className="bi bi-x-circle-fill"
                style={{ color: 'red', margin: 'auto' }}
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
              </svg>
              <p
                style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 600,
                  fontSize: '18px',
                  color: '#ADADAD',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                Error! <br /> Something wrong with your Work Order <br /> Try again?{' '}
              </p>
              <button
                type="button"
                style={{
                  backgroundColor: '#404040',
                  borderRadius: '8px',
                  padding: '10px 12px 10px 12px',
                  height: '40px',
                  width: '300px',
                  color: '#FFFFFFFF',
                  fontFamily: 'Source Sans Pro',
                }}
                onClick={() => {
                  setSubmited(false);
                  // dispatch(getQRLocById(uuid));
                  // dispatch(resetQR());
                }}
                className='m-small-font'
              >
                Back
              </button>
            </div>
          </section>
      )}
    </>
  );
}

export default LocationQRCode;
