/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useState } from 'react'
import { Drawer } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify"
import moment from "moment"
import "./EditMeterModal.css"
import { useDispatch, useSelector } from 'react-redux';
import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import {
    setTab, setTitle,
    setDescription, setAssignedTo, setCategory, setPriority,
    setDueFinish,
    setStatus,
    setLocation,
    setStartedDate,
    setCompletedDate,
    setDueStart,
    setInterval,
    setFrequency,
    setUnit,
    setCurrent,
    updateMeter,
    deleteMeter,
    getAttachment,
    setFiles,
    deleteMeterAttachment,
    setInitialReading,
    setReadingAttachmentFile,
    setReadingAttachment,
    openMeterModalDelete,
    closeMeterModalDelete,
    setIsScheduleEnabled
} from '../../../redux/features/meter/meterSlice';
import {
    closeTrigger, showTrigger, resetTrigger, setCondition, setIsEverytime, setOneTime,
    setTrAssignedTo, setTrCategory, setTrDescription, setTrPriority, setTrTitle, setTrValue,
    setIsEditing, getTriggersByAuthentication, createTrigger, deleteTrigger, getTriggerById,
    updateTrigger, setTriggerId
} from '../../../redux/features/workOrderTrigger/workOrderTriggerSlice';
import { SelectAsset, SelectLocation, SelectPersons, SelectSite } from '../../../components/select/select';
import formatNumber from '../../../utils/formatNumber';
import { getMeterMeasurementByAuthentication } from '../../../redux/features/meterMeasurement/meterMeasurementSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';

function EditMeterModal({ openModalUpdate, closeModalUpdate }) {
    const [showList, setShowList] = useState(false)
    const dispatch = useDispatch();
    const meterCategory = useSelector(state => state.meterCategory?.meterCategories)
    const teams = useSelector(state => state.teams?.teams?.results)
    const meterUnit = useSelector(state => state.meterMeasurement?.meterMeasurements)
    const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories)
    const { selectedSite, idOfSite, idOfLocation, idOfAsset, assetInfo } = useSelector(state => state.selectOptions)
    const { locations, sublocations } = useSelector(state => state.location)
    const assetListSearch = useSelector(state => state.asset?.assetListSearch)

    const allSubLocations = [];

    const {
        meter,
        tab,
        current,
        title,
        description,
        priority,
        category,
        due_finish,
        startService,
        assigned_to,
        interval,
        frequency,
        unit,
        attachments,
        files,
        reading_attachment,
        reading_attachment_file,
        initial_reading,
        isScheduleEnabled,
        meterModalDelete
    } = useSelector(state => state.meter)

    const {
        isTriggerGetSuccess,
        triggers,
        trigger,
        isShowTrigger,
        isEditing,
        triggerId,
        trValue,
        is_onetime,
        trTitle,
        trDescription,
        trPriority,
        is_everytime,
        condition,
        trCategory,
        trAssigned_to,
    } = useSelector(state => state.trigger)
    useEffect(() => {
        if (meter?.id) {
            dispatch(
                setAssignedTo(meter?.assigned_to?.length > 0 ?
                    meter?.assigned_to?.map((i) => ({
                        value: i?.id,
                        label: `${i?.name} (${i?.role === "CliAdm" ? "Admin" : i?.role})`,
                        image: i?.image
                    })) : [])
            )
            dispatch(setCategory(meter?.category?.id))
            dispatch(setDescription(meter?.description))
            dispatch(setLocation(meter?.location?.id))
            dispatch(setPriority(meter?.priority))
            dispatch(setTitle(meter?.title))
            dispatch(setStatus(meter?.status))
            dispatch(setIsScheduleEnabled(meter?.enable_interval))
            dispatch(setDueStart(moment.utc(meter?.service_start_date).format("YYYY-MM-DDTHH:mm")))
            dispatch(setDueFinish(moment.utc(meter?.service_end_date).format("YYYY-MM-DDTHH:mm")))
            dispatch(setStartedDate(meter?.start_date))
            dispatch(setCompletedDate(meter?.completed_date))
            dispatch(selectActions.setIdOfSite(meter?.location?.id))
            dispatch(selectActions.setIdOfLocation(meter?.sub_location?.id))
            dispatch(selectActions.setIdOfAsset(meter?.asset?.id))
            dispatch(setInterval(meter?.interval))
            dispatch(setFrequency(meter?.frequency))
            dispatch(setCurrent(meter?.current_reading))
            dispatch(setUnit(meter?.measurement_unit?.id))
            dispatch(setInitialReading(meter?.initial_reading))
            dispatch(setReadingAttachment(meter?.reading_attachment))
            dispatch(getAttachment(meter?.attachments))
            if (meter?.location?.id) {
                dispatch(selectActions.chooseSite({ id: meter?.location?.id, selected: meter?.location?.site }))
                if (meter?.sub_location?.id) {
                    dispatch(selectActions.chooseLocation({ id: meter?.sub_location?.id, selected: `${meter?.sub_location?.room && meter?.sub_location?.room}${meter?.sub_location?.suite && `, ` + meter?.sub_location?.suite}${meter?.sub_location?.level && `, ` + meter?.sub_location?.level}${meter?.sub_location?.building && `, ` + meter?.sub_location?.building}` }))
                }
            } if (meter?.asset?.id) {
                dispatch(selectActions.chooseAsset({ id: meter?.asset?.id, selected: `${meter?.asset?.asset_number} - ${meter?.asset?.name} ${meter?.asset?.location && `, ` + meter?.asset?.location} ${meter?.asset.sub_location ? `${meter?.asset?.sub_location?.room && `, ` + meter?.asset?.sub_location?.room}${meter?.asset?.sub_location?.suite && `, ` + meter?.asset?.sub_location?.suite}${meter?.asset?.sub_location?.level && `, ` + meter?.asset?.sub_location?.level}${meter?.asset?.sub_location?.building && `, ` + meter?.asset?.sub_location?.building}` : ""}` }))
            }
            dispatch(getTriggersByAuthentication(meter?.id))
            dispatch(getMeterMeasurementByAuthentication());
        }
    }, [meter])

    const handelTabs = (e) => {
        dispatch(setTab(e.target.id))
        dispatch(closeTrigger())
    }
    const handelCurrent = (e) => {
        const input = e.target;
        const { value } = input;
        const formattedValue = formatNumber(value)[0];
        dispatch(setCurrent(formattedValue))
    }

    const handelReadingAttachment = e => {
        dispatch(setReadingAttachmentFile(e.target.files[0]));
    };

    const handelTitle = (e) => {
        dispatch(setTitle(e.target.value))
    }

    const handelDescription = (e) => {
        dispatch(setDescription(e.target.value))
    }

    const handelPriority = (e) => {
        dispatch(setPriority(e.target.value))
    }

    const handelCategory = (e) => {
        dispatch(setCategory(e.target.value))
    }

    const handleUnit = (e) => {
        dispatch(setUnit(e.target.value))
    }


    const handelAttachments = (e) => {
        dispatch(setFiles([{ file: e.target.files[0] }]))
    }
    const deleteAttachment = async (attachId) => {
        await dispatch(deleteMeterAttachment(attachId))
        dispatch(getAttachment(attachments.filter(attach => attach.id !== attachId)))

    }


    // Delete 
    const handelOpenDeletePopup = () => {
        dispatch(openMeterModalDelete())
        setShowList(false);
    };

    const handleDelete = () => {
        dispatch(deleteMeter(meter?.id))
    }

    const payload = {
        "value": trValue,
        "is_onetime": is_onetime,
        "title": trTitle,
        "description": trDescription,
        "priority": trPriority,
        "is_everytime": is_everytime,
        "condition": condition,
        "category": trCategory,
        "assigned_to": trAssigned_to.length > 0 && trAssigned_to?.map(ele => ele?.value),
        "meter": meter?.id
    }
    const hadelTriggerSave = () => {
        if (trCategory && trValue && (is_onetime || is_everytime) && condition && trTitle) {
            if (!formatNumber(trValue)[1]) {
                toast.warning(`Please write a correct Number on Values (${meterUnit?.find(u => u?.id === +unit)?.name}) field (write the decimal part or remove decimal point)`, {
                    toastId: 'CreatedEventWarninggg',
                });
            } else {
                dispatch(createTrigger(payload))
            }
        } else {
            toast.warning('Please fill the required fields and submit again', {
                toastId: 'CreatedEventWarning',
            });
        }
    }
    const hadelTriggerCancel = () => {
        dispatch(resetTrigger())
        dispatch(closeTrigger())
    }

    useEffect(() => {
        if (isTriggerGetSuccess && isEditing) {
            dispatch(setCondition(trigger?.condition))
            dispatch(setTrValue(trigger?.value))
            dispatch(setTrTitle(trigger?.title))
            dispatch(setTrDescription(trigger?.description))
            dispatch(
                setTrAssignedTo(trigger?.assigned_to?.length > 0 ?
                    trigger?.assigned_to?.map((i) => ({
                        value: i?.id,
                        label: `${i?.name} (${i?.role === "CliAdm" ? "Admin" : i?.role})`,
                        image: i?.image
                    })) : [])
            )
            dispatch(setTrPriority(trigger?.priority))
            dispatch(setTrCategory(trigger?.category))
            dispatch(setIsEverytime(trigger?.is_everytime))
            dispatch(setOneTime(trigger?.is_onetime))
            dispatch(setTriggerId(trigger?.id))
        }
    }, [isTriggerGetSuccess, isEditing])


    const hadelTriggerUpdate = () => {
        if (trCategory && trValue && (is_onetime || is_everytime) && condition && trTitle) {
            if (!formatNumber(trValue)[1]) {
                toast.warning(`Please write a correct Number on Values (${meterUnit?.find(u => u?.id === +unit)?.name}) field (write the decimal part or remove decimal point)`, {
                    toastId: 'CreatedEventWarninggg',
                });
            } else {
                dispatch(updateTrigger({ ...payload, triggerId }))
            }
        }
        else {
            toast.warning('Please fill the required fields and submit again', {
                toastId: 'CreatedEventWarning',
            });
        }
    }

    const handelTriggerEdit = async (id) => {
        await dispatch(getTriggerById(id))
        dispatch(setIsEditing(true))
        dispatch(showTrigger())
    }

    const handelTriggerDelete = (id) => {
        dispatch(deleteTrigger(id))
    }

    const handelTriggerType = (e) => {
        if (e.target.id === "everytime") {
            dispatch(setIsEverytime(true))
            dispatch(setOneTime(false))

        } else if (e.target.id === "once") {
            dispatch(setIsEverytime(false))
            dispatch(setOneTime(true))
        }
    }

    const handelCondition = (e) => {
        dispatch(setCondition(e.target.value))
    }
    const handelHours = (e) => {
        const input = e.target;
        const { value } = input;
        const formattedValue = formatNumber(value)[0];
        dispatch(setTrValue(formattedValue))
    }
    const handelTriggerTitle = (e) => {
        dispatch(setTrTitle(e.target.value))
    }
    const handelTriggerDescription = (e) => {
        dispatch(setTrDescription(e.target.value))
    }

    const handelTriggerPriority = (e) => {
        dispatch(setTrPriority(e.target.value))
    }
    const handelTriggerCategory = (e) => {
        dispatch(setTrCategory(e.target.value))
    }




    const handelUpdate = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("id", meter?.id)
        if (title) {
            formData.append("title", title)
        }
        if (description) {
            formData.append("description", description)
        }
        if (priority) {
            formData.append("priority", priority)
        }
        if (category) {
            formData.append("category", category)
        }
        if (idOfSite) {
            formData.append("location", idOfSite)
        }
        if (idOfLocation) {
            formData.append("sub_location", idOfLocation)
        }
        if (idOfAsset) {
            formData.append("asset", idOfAsset)
        }
        if (assigned_to?.length > 0) {
            assigned_to?.forEach((ele) => (
                formData.append(`assigned_to`, ele?.value)
            ))
        }
        if (isScheduleEnabled) {
            formData.append("interval", interval)
            formData.append("frequency", frequency)
        }
        formData.append("measurement_unit", unit)
        formData.append("current_reading", current)
        if (reading_attachment_file) {
            formData.append('reading_attachment', reading_attachment_file);
        }
        formData.append('initial_reading', initial_reading);
        if (files?.length > 0) {
            for (let i = 0; i < files.length; i += 1) {
                if (files[i]?.file?.type?.includes("image")) {
                    formData.append(`attachments[${i}][photo]`, files[i].file);
                } else {
                    formData.append(`attachments[${i}][file]`, files[i].file);
                }
            }
        }
        if (title && due_finish && assigned_to?.length > 0 && category && unit) {
            if (!formatNumber(current)[1] && current) {
                toast.warning("Please write a correct Number on New Meter Reading field (write the decimal part or remove decimal point)", {
                    toastId: 'CreatedEventWarninggg',
                });
            } else {
                await dispatch(updateMeter({ id: meter?.id, formData }))
            }
        }
        else {
            toast.warning('Please fill the required fields and submit again', {
                toastId: 'CreatedEventWarning',
            });
        }
    }
    const { user } = useSelector((state) => state.user);
    let ellipsisBtn = <div className="dropdown" onClick={() => setShowList(!showList)}>
        {showList && <div className='tck-overlay' onClick={() => setShowList(!showList)}></div>}
        <button type='button' data-toggle="dropdown" id="dropdownMenu1" className="btn tertiary-btn ml-2 " title="View More">
            <div className="fas fa-ellipsis-h"></div>
        </button>
        <ul className={`dropdown-menu ${showList && "dropdown-menu-active"}`} role="menu" aria-labelledby="dropdownMenu1">
            <li role="presentation">
                <button type='button' className="font-danger"
                    data-toggle="modal" data-target="#modal-delete-meter"
                    title="Delete Meter" onClick={handelOpenDeletePopup}>  Delete </button>
            </li>
        </ul>
    </div>

    if ((user?.role === "Technical Team") ||
        (user?.role === "Manager" && meter?.abstract?.created_by?.role !== "Manager")) {
        ellipsisBtn = ""
    }


    return (
        <Drawer
            anchor="right"
            open={openModalUpdate}
            onClose={closeModalUpdate}
            PaperProps={{
                style: {
                    width: '100%',
                    maxWidth: '600px',
                    height: '100%',
                },
            }}
        >
            <form onSubmit={handelUpdate} className="h-100 w-100" >
                <div className="navbar col-lg-12 m-0 modal-header-background page-profile-header pr-0" >
                    <div className="col-12 mb-2 row px-2">
                        <div className="my-auto" >
                            <h5>
                                #{meter?.meter_number}
                            </h5>
                        </div>
                        <div className="row ml-auto">
                            {ellipsisBtn}
                            <div>
                                <button onClick={() => dispatch(closeModalUpdate())} type="button" className="btn secondary-btn ml-2" data-dismiss="modal" >
                                    <div className="fas fa-times"></div>
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className="row justify-content-start mt-auto col-lg-12 col-sm-12">
                        <ul className="nav nav-pills" id="custom-tabs-two-tab" role="tablist" >
                            <li className="nav-item">
                                <span onClick={handelTabs} className={`nav-link ${tab === "service-tab" && "active"} cursor-pointer`} id="service-tab" data-toggle="tab" href="#service-tab-edit" role="tab" aria-controls="custom-tabs-two-service" aria-selected="false">Overview</span>
                            </li>

                            <li className="nav-item">
                                <span onClick={handelTabs} className={`nav-link ${tab === "schedule-tab-edit" && "active"} cursor-pointer`} id="schedule-tab-edit" data-toggle="tab" href="#schedule-tab-edit" role="tab" aria-controls="custom-tabs-two-schedule" aria-selected="false">Meter Schedule</span>
                            </li>
                            <li className="nav-item">
                                <span onClick={handelTabs} className={`nav-link ${tab === "new-reading-tab" && "active"} cursor-pointer`} id="new-reading-tab" data-toggle="tab" href="#service-tab-edit" role="tab" aria-controls="custom-tabs-two-service" aria-selected="false">New Reading</span>
                            </li>
                            <li className="nav-item">
                                <span onClick={handelTabs} className={`nav-link ${tab === "workorder-trigger-tab" && "active"} cursor-pointer`} id="workorder-trigger-tab" data-toggle="tab" href="#workorder-trigger-edit" role="tab" aria-controls="custom-tabs-two-workorder" aria-selected="false">Work Order Trigger</span>
                            </li>
                            <li className="nav-item">
                                <span onClick={handelTabs} className={`nav-link ${tab === "activity-tab" && "active"} cursor-pointer`} id="activity-tab" data-toggle="tab" href="#activity-tab" role="tab" aria-controls="custom-tabs-two-activity" aria-selected="false">Reading History</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="tab-content col-lg-12 col-sm-12 pb-5" id="custom-tabs-two-tabContent">

                    {tab === "service-tab" && <div className="tab-pane fade active show" id="service-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-service-tab">
                        <div className="card-body row">
                            <div className="col-lg-12 col-sm-12">
                                <label className="font-weight-normal gcolor">Title <label className="font-danger m-0">*</label></label>
                                <input
                                    type="text"
                                    className="form-control mb-2 font-weight-normal"
                                    name="meter_name"
                                    id="meter_name"
                                    required
                                    onChange={handelTitle}
                                    value={title}
                                />
                            </div>
                            <div className="col-lg-12 col-sm-12">
                                <label className="font-weight-normal gcolor">Description</label>
                                <textarea
                                    className="form-control mb-2"
                                    name="description"
                                    id="meter_description"
                                    placeholder="Add description"
                                    onChange={handelDescription}
                                    value={description}
                                >
                                </textarea>
                            </div>
                            <div className="col-lg-12 col-sm-12 pt-3">
                                <div id="ifAsset" className="col-12 p-0">
                                    <label className="font-weight-normal">Assets</label>
                                    <SelectAsset width="100%" setAssignedTo={setAssignedTo} assetData={assetListSearch} />
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 pt-3">
                                <div id="ifLocation" className="col-12 p-0">
                                    <SelectSite locations={locations} assetInfo={assetInfo} width="100%" noStar />
                                    {selectedSite &&
                                        <div className="col-12 p-0" id="building_show">
                                            <SelectLocation
                                                assetInfo={assetInfo}
                                                subLocation={sublocations?.results}
                                                allSubLocations={allSubLocations}
                                                width="100%"
                                                noStar
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12">
                                <label className="font-weight-normal">Assigned to <span className="font-danger">*</span></label>
                                <div className="col-12 p-0">

                                    <SelectPersons teams={teams} assignedTo={assigned_to} onEdit setAssignedTo={setAssignedTo} />
                                </div>
                            </div>

                            <div className="col-lg-12 col-sm-12 pt-3">
                                <label className="font-weight-normal ">Priority</label>
                                <select className="form-control select-dash custom-select2 mb-2"
                                    name="priority2" id="priority2"
                                    onChange={handelPriority}
                                    value={priority}
                                >
                                    <option value="" disabled selected>--SELECT--</option>
                                    {['High', 'Medium', 'Low'].map(val => (
                                        <option value={val}>{val}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-lg-12 col-sm-12 pt-3">
                                <label className="font-weight-normal">Category<span className="font-danger">*</span></label>
                                <select className="form-control select-dash custom-select2 mb-2"
                                    name="category" id="category2"
                                    onChange={handelCategory}
                                    value={category}
                                >
                                    <option value="" disabled selected>--SELECT--</option>
                                    {meterCategory && meterCategory.length > 0
                                        && meterCategory.map(cat => (
                                            <option value={cat.id}>{cat.name}</option>
                                        ))}
                                </select>
                            </div>

                            <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 pb-1 pt-3" >
                                <div className="card-header m-0 mb-2 p-0 gcolor">
                                    <span >Attachments</span>
                                </div>
                                <div className="card-body m-0 p-0 col-12">
                                    <div style={{ background: "#f8f8f8" }}>
                                        <input type="file" id="file-input" onChange={handelAttachments} multiple name="file-input" />
                                        <label id="file-input-label" htmlFor="file-input">
                                            Choose Files
                                        </label>
                                    </div>
                                    {files?.length > 0 && <p style={{ marginBottom: "5px", fontSize: "20px" }}>Files Choosen</p>}
                                    <ul>
                                        {files?.map((file, index) => (
                                            <span style={{ marginRight: "10px" }} key={index}>{file?.file?.name}</span>
                                        ))}
                                    </ul>
                                    <div className='row'>
                                        {attachments?.length > 0 && attachments?.map(file => (
                                            <div className="container col-2 m-0 my-2 text-center" title={file.name} >
                                                <button type='button' className="image p-2 photo" style={{ height: "100%", width: "100%" }}>
                                                    {file?.type === "photo" ?
                                                        <a target="_blank" rel="noopener noreferrer" href={file?.photo}>
                                                            <img id="myImg" alt="file" style={{ height: "100%", width: "100%" }}
                                                                src={file?.photo} data-toggle="modal" data-target="#myModal" />
                                                        </a> :
                                                        <a style={{ fontSize: "12px" }}
                                                            target="_blank" rel="noopener noreferrer"
                                                            href={file?.file} title={file?.name} >
                                                            {file?.name.slice(0, 10)}
                                                        </a>
                                                    }
                                                </button>
                                                <div onClick={() => deleteAttachment(file.id)} className="middle" style={{ opacity: 1 }}>
                                                    <span
                                                        className="text font-danger"
                                                        data-toggle="modal" data-target="#modal-attachment"
                                                        title="Delete Attachment" >
                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {meter?.asset && <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 p-3" style={{ borderRadius: "8px !important" }} >
                                <div className="card-header m-0 p-0 gcolor">
                                    <span >Asset Details</span>
                                </div>
                                <div className="card-body row p-1">
                                    <div className="col-12 medium-title mb-3">
                                        <Link to={`/assets/${meter?.asset?.uuid}`} title="More Asset Details">
                                            <span className="col-4"> {meter?.asset?.asset_number}</span>
                                        </Link>
                                        <span className="col-8"> {meter?.asset?.name}</span>
                                    </div>

                                    <div className="col-6">
                                        <span className="gcolor col-12">Type </span>
                                        <span className=""> {meter?.asset?.asset_type}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="gcolor col-12">Category</span>
                                        <span className=""> {meter?.asset?.category}</span>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <span className="gcolor col-12">Location</span>
                                        <span className="">
                                            {meter?.asset?.location}
                                        </span>
                                    </div>
                                </div>
                            </div>}


                            {meter?.location !== null && <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 location-details " >
                                <div className="card-header"><span >Location details</span>
                                </div>
                                <div className="m-0 p-0 gcolor" id="L-view">
                                    <h6 className="title" style={{ color: "red", paddingLeft: "5px" }}>
                                        Location
                                        <div style={{ color: "black" }}>{meter?.location?.site}</div>
                                    </h6>
                                    {meter?.sub_location !== null &&
                                        <ul className="wtree">
                                            {meter?.sub_location?.building && <ul >
                                                <li>
                                                    <span style={{ marginRight: "50%" }}>
                                                        Building
                                                        <div style={{ color: "black" }}>{meter?.sub_location?.building}</div>
                                                    </span>
                                                </li>
                                            </ul>}
                                            {meter?.sub_location?.level && <ul  >
                                                <li >
                                                    <span style={{ marginRight: "50%" }}>
                                                        Level
                                                        <div style={{ color: "black" }}>{meter?.sub_location?.level}</div>
                                                    </span>
                                                </li>
                                            </ul>}
                                            {meter?.sub_location?.suite && <ul >
                                                <li >
                                                    <span style={{ marginRight: "50%" }}>
                                                        Suite
                                                        <div style={{ color: "black" }}>{meter?.sub_location?.suite}</div>
                                                    </span>
                                                </li>
                                            </ul>}
                                            {meter?.sub_location?.room && <ul >
                                                <li >
                                                    <span style={{ marginRight: "50%" }}>
                                                        Room
                                                        <div style={{ color: "black" }}>{meter?.sub_location?.room}</div>
                                                    </span>
                                                </li>
                                            </ul>}
                                        </ul>
                                    }
                                </div>

                            </div>
                            }





                            <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 mt-3 p-3" >
                                <div className="card-header m-0 p-0 gcolor">
                                    <span >Requestor Details</span>
                                </div>
                                <div className="card-body m-0 p-0">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12 row">
                                            <b className="col-12">{meter?.abstract?.name}</b>
                                            <a href={`mailto: ${meter?.abstract?.email}`} className="col-12" style={{ color: "#F63854" }}>{meter?.abstract?.email}</a>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 row">
                                            <div className="col-12">Phone</div>
                                            {meter?.abstract?.phone ? <a href={`tel: ${meter?.abstract?.phone}`}
                                                className="col-12" style={{ color: "#F63854" }}>{meter?.abstract?.phone}</a> :
                                                <span className="col-12" style={{ color: "#F63854" }}>None</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 my-auto">
                                    <span ><b>Issued on</b> {moment(meter?.created_at).format('MMMM Do YYYY, h:mm a')}</span>
                                </div>
                            </div>


                        </div>
                    </div>}


                    {tab === "schedule-tab-edit" &&
                        <div id="schedule-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-schedule-tab">
                            <div className="card-body ">
                                <div className="row">
                                    {isScheduleEnabled && <><div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Start Date  <label className="font-danger m-0">*</label></label>
                                        <input
                                            type="datetime-local"
                                            className="form-control mb-2"
                                            name="service_date"
                                            id="meter_service_date"
                                            placeholder="Start Date"
                                            required
                                            value={startService}
                                            readOnly
                                        />
                                    </div>
                                        <div className=" col-lg-6 col-md-6 col-sm-12">
                                            <label className="font-weight-normal">End Date  <label className="font-danger m-0">*</label></label>
                                            <input
                                                type="datetime-local"
                                                className="form-control mb-2"
                                                name="service_date"
                                                id="meter_service_date"
                                                placeholder="Start Date"
                                                required
                                                value={due_finish}
                                                readOnly
                                            />
                                        </div>
                                        <div className=" col-lg-3 col-md-3 col-sm-4">
                                            <label className="font-weight-normal" >Interval  <label className="font-danger m-0">*</label>
                                                <i className="far fa-question-circle small-font gcolor" >
                                                    <span className=" tooltiptext" >
                                                        Meter Interval
                                                    </span>
                                                </i>
                                            </label>
                                            <input name='interval' type="number" className="form-control mb-2"
                                                placeholder="Every" id="meter_interval" min="0"
                                                value={interval}
                                                readOnly
                                                required />
                                            <div className="col-lg-12 col-sm-12 m-0"><span className="font-danger" id="meter_error"></span></div>

                                        </div>

                                        <div className=" col-lg-9 col-md-9 col-sm-8">
                                            <label className="font-weight-normal">Frequency  <label className="font-danger m-0">*</label></label>
                                            <select className=" mb-2 select-meter" name="frequency"
                                                value={frequency}
                                                disabled
                                                id="meter_frequency" required>
                                                <option value="">Select a Frequency</option>
                                                {['day', 'week', 'month', 'year'].map(val => (
                                                    <option value={val}>{val}(s)</option>
                                                ))}


                                            </select>
                                        </div> </>}
                                    <div className=" col-lg-12 col-sm-12 pt-3">
                                        <label className="font-weight-normal">Unit of Measurement<label className="font-danger m-0">*</label></label>
                                        <select className=" mb-2 select-meter" name="measurement_unit"
                                            id="measurement_unit" required
                                            value={unit}
                                            onChange={handleUnit}
                                        >
                                            <option value="">--SELECT--</option>
                                            {meterUnit && meterUnit?.length > 0
                                                && meterUnit?.map(u => (
                                                    <option value={u?.id}>{u?.name}</option>
                                                ))}

                                        </select>
                                    </div>
                                </div>
                            </div>


                        </div>}

                    {tab === "new-reading-tab" && <div id="new-reading-tab" role="tabpanel" aria-labelledby="custom-tabs-two-activity-tab">
                        <div className="card-body pb-2">
                            <div className="col-lg-12 col-sm-12">
                                <label className="font-weight-normal gcolor">New Meter Reading ({meterUnit?.find(u => u.id === +unit)?.name}) <label className="font-danger m-0">*</label></label>
                                <input
                                    type="text"
                                    className="form-control mb-2 font-weight-normal"
                                    name="meter_reading"
                                    id="meter_reading"
                                    onChange={handelCurrent}
                                    placeholder='New Meter Reading'
                                    value={current || null}
                                    required
                                />
                            </div>
                            <div className="col-lg-12 col-sm-12 pt-2 pb-2" >
                                <div className="pt-2 my-auto">
                                    <span ><b>Last reading : </b>
                                        {meter?.previous_reading ? `${meter?.previous_reading} ${meterUnit?.find(u => u.id === +unit)?.name}` :
                                            `there is no previous reading `}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 pt-2">
                                <label className="font-weight-normal">Reading Attachment</label>
                                <div style={{ background: '#f8f8f8' }}>
                                    <input
                                        type="file"
                                        onChange={handelReadingAttachment}
                                        accept="image/*"
                                    />
                                </div>
                            </div>
                            {reading_attachment &&
                                <div className='row pl-2'>
                                    <div className="container col-2 m-0 my-2 text-center" >
                                        <button type='button' className="image p-2 photo" style={{ height: "100%", width: "100%" }}>
                                            <a target="_blank" rel="noopener noreferrer" href={reading_attachment?.attachment}>
                                                <img id="myImg" alt="file" style={{ height: "100%", width: "100%" }}
                                                    src={reading_attachment?.attachment} data-toggle="modal" data-target="#myModal" />
                                            </a>
                                        </button>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    }
                    {tab === "workorder-trigger-tab" && <div id="workorder-trigger-edit" role="tabpanel" aria-labelledby="custom-tabs-two-workorder-trigger-tab">
                        {!isShowTrigger && <div className="card-body">
                            <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 p-0" id="CL-view" style={{ display: "block" }}>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 px-0 mb-3">
                                    <label className="">Work Order Trigger</label>
                                </div>
                                <table className="table table-borderless mb-0" style={{ background: "transparent", border: "none" }}>
                                    <thead>
                                        <tr>
                                            <th >Title</th>
                                            <th >Conditions</th>
                                            <th ></th>
                                        </tr>
                                    </thead>
                                    <tbody id="dialog-append-part">


                                        {
                                            triggers && triggers.length > 0 && triggers.map(ele => {
                                                let deleteAndEdit = true;
                                                if ((user?.role === "Technical Team" && ele?.created_by?.role !== "Technical Team") ||
                                                    (user?.role === "Manager" && ele?.created_by?.role !== "Manager")) {
                                                    deleteAndEdit = false;
                                                }
                                                return <tr id="meter-trigger-trigger.id">
                                                    <td style={{ textAlign: "left" }}>{ele.title}</td>
                                                    <td>{`${ele.condition} ${ele.value} ${meterUnit?.find(u => u?.id === +unit)?.name}`}</td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-6" title="Edit">
                                                                    <button disabled={!deleteAndEdit} type="button" onClick={() => handelTriggerEdit(ele?.id)} className="btn tertiary-btn"
                                                                    ><div className="fa fa-edit" aria-hidden="true"></div></button>
                                                                </div>


                                                                <div className="col-6" title="Delete">
                                                                    <button disabled={!deleteAndEdit} type="button" onClick={() => handelTriggerDelete(ele?.id)} className="btn tertiary-btn" style={{ verticalAlign: "middle" }}>
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </div>


                                                            </div>
                                                        </div>

                                                    </td>

                                                </tr>
                                            })
                                        }


                                    </tbody>
                                </table>

                                <br />
                                <div className="col col-lg-12 col-sm-8 pt-2 my-auto" >
                                    <button
                                        type="button"
                                        className="btn primary-btn"
                                        data-toggle="dropdown"
                                    >
                                        <div onClick={() => {
                                            dispatch(setIsEditing(false))
                                            dispatch(resetTrigger())
                                            dispatch(showTrigger())
                                        }} aria-hidden="true">Add Trigger</div>
                                    </button>

                                </div>


                            </div>

                        </div>
                        }

                        {isShowTrigger && <form><div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 p-0 " id="CL-edit">
                            <div className="card-body">

                                <div className="row pb-5">

                                    <div className=" col-lg-12 col-sm-12 row">
                                        <label className="font-weight-normal col-12">Select Type: <label className="font-danger m-0">*</label></label>
                                        <div className="col-6"><input className="mr-2" type="radio" checked={is_everytime} onChange={handelTriggerType} name="trigger_type" id="everytime" />Trigger Every Time</div>
                                        <div className="col-6"><input className="mr-2" type="radio" checked={is_onetime} onChange={handelTriggerType} name="trigger_type" id="once" />One-Time Trigger </div>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-6 pt-3">
                                        <label className="font-weight-normal">When Meter Reading  <label className="font-danger m-0">*</label></label>
                                        <select className=" mb-2 select-meter"
                                            onChange={handelCondition}
                                            value={condition}
                                            name="trigger_condition" id="trigger_condition" >

                                            <option value="" selected disabled>Select a Condition</option>
                                            {['Is Greater Than', 'Is Less Than', 'Is Equal To', 'Reaches Every'].map(opt => (
                                                <option key={opt} value={opt} >{opt}</option>
                                            ))}


                                        </select>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-6 pt-3">
                                        <label className="font-weight-normal" >Values ({meterUnit?.find(u => u?.id === +unit)?.name})  <label className="font-danger m-0">*</label>
                                        </label>
                                        <input name='trigger_value' type="text" className="form-control mb-2"
                                            onChange={handelHours}
                                            value={trValue}
                                            placeholder={meterUnit?.find(u => u?.id === +unit)?.name} id="trigger_value"
                                        />

                                    </div>


                                    <div className="col-lg-12 col-sm-12 pt-3">
                                        <label className="font-weight-normal">Work  Order Title <label className="font-danger m-0">*</label></label>
                                        <input name='workorder_title' type="text" className="form-control mb-2"
                                            placeholder="Title" id="workorder_title"
                                            onChange={handelTriggerTitle}
                                            value={trTitle}
                                        />
                                    </div>

                                    <div className="col-lg-12 col-sm-12 pt-3">
                                        <label className="font-weight-normal">Description</label>
                                        <textarea name='trigger_description' className="form-control mb-2"
                                            placeholder="Description" id="trigger_description"
                                            onChange={handelTriggerDescription}
                                            value={trDescription}
                                        ></textarea>

                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <label className="font-weight-normal">Assigned to <span className="font-danger">*</span></label>
                                        {/* <div className="col-12 p-0">
                                            <select className="form-control select-meter custom-select2 mb-2" 
                                            name="assigned_to" id="assigned_to" 
                                            style={{width: "100%"}} required
                                            onChange={handelTriggerAssignTo} 
                                            value={trAssigned_to}
                                            >
                                                <option value="" disabled selected>--SELECT--</option>
                                                {teams && teams.length > 0 && teams.map(team =>{
                                                    if (team.role !== "Requester") {
                                                        return <option 
                                                            key={team.id}
                                                            value={team.id}>
                                                        {`${team.first_name} ${team.last_name} (${team.role === "CliAdm"  ? "Admin": team.role})`}
                                                        </option>
                                                    }
                                                    })}
                                            </select>
                                            </div> */}
                                        <SelectPersons teams={teams} assignedTo={trAssigned_to} setAssignedTo={setTrAssignedTo} />
                                    </div>

                                    <div className="col-lg-12 col-sm-12 pt-3">
                                        <label className="font-weight-normal ">Priority</label>
                                        <select className="form-control select-dash custom-select2 mb-2"
                                            name="priority2" id="priority2"
                                            onChange={handelTriggerPriority}
                                            value={trPriority}
                                        >
                                            <option value="" disabled selected>--SELECT--</option>
                                            {['High', 'Medium', 'Low'].map(val => (
                                                <option value={val}>{val}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-lg-12 col-sm-12 pt-3">
                                        <label className="font-weight-normal">Category <span className="font-danger">*</span></label>
                                        <select required className="form-control select-dash custom-select2 mb-2"
                                            name="category" id="category2"
                                            onChange={handelTriggerCategory}
                                            value={trCategory}
                                        >
                                            <option value="" disabled selected>--SELECT--</option>
                                            {workOrderCategory && workOrderCategory.length > 0
                                                && workOrderCategory.map(cat => (
                                                    <option value={cat.id}>{cat.name}</option>
                                                ))}


                                        </select>
                                    </div>
                                    {isShowTrigger && <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3"  >
                                        <div className='trigger-div'>
                                            {!isEditing && <button type='button' title="Save" id="trigger-save" className="btn primary-btn ml-auto mr-2" onClick={hadelTriggerSave}>
                                                Save Trigger
                                            </button>}
                                            {isEditing && <button type='button' title="Save" id="trigger-save" className="btn primary-btn ml-auto mr-2"
                                                onClick={hadelTriggerUpdate}>
                                                Update Trigger
                                            </button>
                                            }
                                            <button type='button' title="Cancel" id="trigger-cancel" className=" btn primary-btn ml-auto" onClick={hadelTriggerCancel}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <p className="font-danger float-center" id="code_error"></p>
                            </div>
                        </div>
                        </form>}
                    </div>
                    }

                    {tab === "activity-tab" && <div id="activity-tab" role="tabpanel" aria-labelledby="custom-tabs-two-activity-tab">
                        <div className="card-body">
                            <div id="activity_comment_div">
                                <div>
                                    {/* <div className="row px-2 gcolor">
                                                {meter.meter_history && meter.meter_history.length > 0 
                                                && meter.meter_history.map(m =>(
                                                    <div className="gcolor ml-2" > {m?.description}</div>
                                                ))}
                                            </div> */}
                                    <table className='act-table'>
                                        <thead>
                                            <tr>
                                                <td>Date</td>
                                                <td>Person</td>
                                                <td>Action</td>
                                                <td>New Reading</td>
                                                <td>Attachment</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {meter.meter_history && meter.meter_history.length > 0
                                                && meter.meter_history.map(item => {
                                                    return <tr key={item.id}>
                                                        <td>{moment(item.created_at).format('YYYY-MM-DD HH:mm')}</td>
                                                        <td > <img className='assignedImage' src={item?.updated_by.image} alt="" title={item?.updated_by.name} /> </td>
                                                        <td>{item.action}</td>
                                                        <td>{item.reading}</td>
                                                        <td>{item.attachment ? <a target="_blank" rel="noopener noreferrer" href={item.attachment?.attachment}>
                                                            <img id="myImg" alt="file" style={{ height: "60px", width: "100px" }}
                                                                src={item.attachment?.attachment} data-toggle="modal" data-target="#myModal" />
                                                        </a> : <div>No Changes Detected</div>}
                                                        </td>
                                                    </tr>
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>}



                </div>



                <div className=" row m-0 p-2 page-profile-footer" >
                    <button
                        className="btn btn-primary"
                        type="submit"
                    >
                        <span className="button__text">Save</span>
                    </button>
                    <div className="pl-2 my-auto gcolor">
                        <span >Last Saved on {moment(meter?.modified_at).format('MMMM Do YYYY, h:mm a')}</span>
                    </div>
                </div>
            </form>


            {meterModalDelete && (
                <PopUpDeleteModal
                    showModal={meterModalDelete}
                    handleClose={() => dispatch(closeMeterModalDelete())}
                    handleConfirm={handleDelete}
                    modalTitle="Delete meter"
                    modalBodyText="Are you sure you want to delete this meter?"
                />
            )}



        </Drawer>

    )
}

export default EditMeterModal



