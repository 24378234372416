/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import deleteIcon from "../../../assets/img/delete.png"
import editIcon from "../../../assets/img/edit.png"

import {
    getWorkOrderCategoryByAuthentication,
    closeWorkOrderCategoryModalCreate,
    closeWorkOrderCategoryModalDelete,
    closeWorkOrderCategoryModalUpdate,
    createWorkOrderCategories,
    deleteWorkOrderCategories,
    openWorkOrderCategoryModalCreate,
    openWorkOrderCategoryModalDelete,
    openWorkOrderCategoryModalUpdate,
    updateWorkOrderCategories,
    setWorkOrderCategory,
    reset as resetWorkOrderCategory,
} from '../../../redux/features/workOrderCategory/workOrderCategorySlice';



import PopUpDeleteModal from '../../popUpModals/editCategory/popUpDeleteModal';
import PopUpEditModal from '../../popUpModals/editCategory/popUpEditModal';
import PopUpAddModal from '../../popUpModals/editCategory/popUpAddModal';
import { setCategoryType } from '../../../redux/features/editCategory/editCategorySlice';
import {
    getWorkOrderTypeByAuthentication,
    closeWorkOrderTypeModalCreate,
    closeWorkOrderTypeModalDelete,
    closeWorkOrderTypeModalUpdate,
    createWorkOrderTypes,
    deleteWorkOrderTypes,
    openWorkOrderTypeModalCreate,
    openWorkOrderTypeModalDelete,
    openWorkOrderTypeModalUpdate,
    updateWorkOrderTypes,
    setWorkOrderType,
    reset as resetWorkOrderType,
} from '../../../redux/features/workOrderType/workOrderTypeSlice';
import {
    setValidatorLocation,
    setValidatorPerson,
    setValidatorActive,
    createWorkOrderValidators,
    getWorkOrderValidatorByAuthentication,
    reset as resetWorkOrderValidator,
    resetWorkOrderVaildatorField,
    closeWorkOrderValidatorModalDelete,
    openWorkOrderValidatorModalDelete,
    setWorkOrderValidator,
    deleteWorkOrderValidators
} from '../../../redux/features/workOrderValidator/workOrderValidatorSlice';
import { SelectLocationFilter, SelectStaff } from '../../select/select';
import { getLocationsByAuthentication } from '../../../redux/features/location/locationSlice';
import { getTeamsByAuthentication } from '../../../redux/features/teams/teamSlice';
import ValidatorTable from './ValidatorTable';
import { closeWorkOrderServiceRequestModalCreate, closeWorkOrderServiceRequestModalDelete, closeWorkOrderServiceRequestModalUpdate, createWorkOrderServiceRequests, deleteWorkOrderServiceRequests, getWorkOrderServiceRequestByAuthentication, openWorkOrderServiceRequestModalCreate, openWorkOrderServiceRequestModalDelete, openWorkOrderServiceRequestModalUpdate, reset as resetWorkOrderServiceRequest, setWorkOrderServiceRequest, updateWorkOrderServiceRequests } from '../../../redux/features/workOrderServiceRequest/workOrderServiceRequestSlice';

export default function WorkOrderTab() {

    const {
        workOrderCategories,
        workOrderCategoryModalCreate,
        workOrderCategoryModalDelete,
        workOrderCategoryModalUpdate,
        workOrderCategory,
        isWorkOrderCategoryCreateLoading,
        isWorkOrderCategoryCreateSuccess,
        isWorkOrderCategoryUpdateLoading,
        isWorkOrderCategoryUpdateSuccess,
        isWorkOrderCategoryDeleteLoading,
        isWorkOrderCategoryDeleteSuccess,
    } = useSelector(state => state.workOrderCategory);

    const {
        workOrderTypes,
        workOrderTypeModalCreate,
        workOrderTypeModalDelete,
        workOrderTypeModalUpdate,
        workOrderType,
        isWorkOrderTypeCreateLoading,
        isWorkOrderTypeCreateSuccess,
        isWorkOrderTypeUpdateLoading,
        isWorkOrderTypeUpdateSuccess,
        isWorkOrderTypeDeleteLoading,
        isWorkOrderTypeDeleteSuccess,
    } = useSelector(state => state.workOrderType);

    const {
        workOrderServiceRequests,
        workOrderServiceRequestModalCreate,
        workOrderServiceRequestModalDelete,
        workOrderServiceRequestModalUpdate,
        workOrderServiceRequest,
        isWorkOrderServiceRequestCreateLoading,
        isWorkOrderServiceRequestCreateSuccess,
        isWorkOrderServiceRequestUpdateLoading,
        isWorkOrderServiceRequestUpdateSuccess,
        isWorkOrderServiceRequestDeleteLoading,
        isWorkOrderServiceRequestDeleteSuccess,
    } = useSelector(state => state.workOrderServiceRequest);

    const {
        workOrderValidators,
        validatorLocation,
        validatorPerson,
        validatorActive,
        isWorkOrderValidatorUpdateLoading,
        isWorkOrderValidatorCreateLoading,
        isWorkOrderValidatorDeleteLoading,
        isWorkOrderValidatorCreateSuccess,
        isWorkOrderValidatorDeleteSuccess,
        isWorkOrderValidatorUpdateSuccess,
        workOrderValidatorModalDelete,
        workOrderValidator
    } = useSelector(state => state.workOrderValidator);

    const dispatch = useDispatch();


    // const ValidatorData = [
    //     { id: 1, location: "Cairo", pin: "Hazem", active: true },
    //     { id: 2, location: "Alexandria", pin: "Nadia", active: false },
    //     { id: 3, location: "Giza", pin: "Omar", active: true },
    //     { id: 4, location: "Luxor", pin: "Mona", active: true },
    //     { id: 5, location: "Aswan", pin: "Karim", active: false },
    //     { id: 6, location: "Hurghada", pin: "Dina", active: true },
    //     { id: 7, location: "Sharm El-Sheikh", pin: "Tarek", active: true },
    //     { id: 8, location: "Faiyum", pin: "Salma", active: false },
    //     { id: 9, location: "Suez", pin: "Hassan", active: true },
    //     { id: 10, location: "Port Said", pin: "Laila", active: false }
    // ];

    const [isShowAdd, setIsShowAdd] = useState(false)
    const { teams } = useSelector(state => state.teams);
    const { locations } = useSelector(state => state.location);
    const { categoryType } = useSelector(state => state.editCategory);
    const [workOrderData, setWorkOrderData] = useState(null);
    const [workOrderTypeData, setWorkOrderTypeData] = useState(null);
    const [workOrderServiceRequestData, setWorkOrderServiceRequestData] = useState(null);
    useEffect(() => {
        dispatch(getWorkOrderCategoryByAuthentication());
        dispatch(getWorkOrderTypeByAuthentication());
        dispatch(getWorkOrderServiceRequestByAuthentication());
        dispatch(resetWorkOrderCategory());
        dispatch(resetWorkOrderType());
        dispatch(resetWorkOrderServiceRequest());
        dispatch(getLocationsByAuthentication())
        dispatch(getTeamsByAuthentication())
        dispatch(getWorkOrderValidatorByAuthentication())
    }, [dispatch]);

    const handleConfirm = (id, type) => {
        if (type === 'workOrder') {
            dispatch(openWorkOrderCategoryModalDelete());
            dispatch(setWorkOrderCategory(id));
        }
        if (type === 'workOrder-type') {
            dispatch(openWorkOrderTypeModalDelete());
            dispatch(setWorkOrderType(id));
        }
        if (type === 'workOrder-service-request') {
            dispatch(openWorkOrderServiceRequestModalDelete());
            dispatch(setWorkOrderServiceRequest(id));
        }
        if (type === 'workOrder-vaildator') {
            dispatch(openWorkOrderValidatorModalDelete());
            dispatch(setWorkOrderValidator(id));
        }
    };
    const handleDelete = () => {
        if (categoryType === 'workOrder') {
            dispatch(deleteWorkOrderCategories(workOrderCategory));
            dispatch(closeWorkOrderCategoryModalDelete());
        } else if (categoryType === 'workOrder-type') {
            dispatch(deleteWorkOrderTypes(workOrderType));
            dispatch(closeWorkOrderTypeModalDelete());
        } else if (categoryType === 'workOrder-service-request') {
            dispatch(deleteWorkOrderServiceRequests(workOrderServiceRequest));
            dispatch(closeWorkOrderServiceRequestModalDelete());
        } else if (categoryType === 'workOrder-vaildator') {
            dispatch(deleteWorkOrderValidators(workOrderValidator));
            dispatch(closeWorkOrderTypeModalDelete());
        }
    };
    const handleEdit = (data, type) => {
        if (type === 'workOrder') {
            dispatch(openWorkOrderCategoryModalUpdate());
            dispatch(setWorkOrderCategory(data.id));
            setWorkOrderData(data);
        }
        if (type === 'workOrder-type') {
            dispatch(openWorkOrderTypeModalUpdate());
            dispatch(setWorkOrderType(data.id));
            setWorkOrderTypeData(data);
        }
        if (type === 'workOrder-service-request') {
            dispatch(openWorkOrderServiceRequestModalUpdate());
            dispatch(setWorkOrderServiceRequest(data.id));
            setWorkOrderServiceRequestData(data);
        }
    };

    const handleEditSave = () => {
        if (categoryType === 'workOrder') {
            dispatch(
                updateWorkOrderCategories({
                    id: workOrderCategory,
                    name: workOrderData.name,
                }),
            );
            dispatch(closeWorkOrderCategoryModalUpdate());
        }
        if (categoryType === 'workOrder-type') {
            dispatch(
                updateWorkOrderTypes({
                    id: workOrderType,
                    name: workOrderTypeData.name,
                }),
            );
            dispatch(closeWorkOrderTypeModalUpdate());
        }

        if (categoryType === 'workOrder-service-request') {
            dispatch(
                updateWorkOrderServiceRequests({
                    id: workOrderServiceRequest,
                    name: workOrderServiceRequestData.name,
                }),
            );
            dispatch(closeWorkOrderServiceRequestModalUpdate());
        }
    };

    const handleCreate = type => {
        if (type === 'workOrder') {
            dispatch(openWorkOrderCategoryModalCreate());
        } else if (type === 'workOrder-type') {
            dispatch(openWorkOrderTypeModalCreate());
        } else if (type === 'workOrder-service-request') {
            dispatch(openWorkOrderServiceRequestModalCreate());
        }
    };

    const handleCreateSave = () => {
        if (categoryType === 'workOrder') {
            dispatch(
                createWorkOrderCategories({
                    name: workOrderData.name,
                }),
            );
            dispatch(closeWorkOrderCategoryModalCreate());
            dispatch(resetWorkOrderCategory());
        }
        if (categoryType === 'workOrder-type') {
            dispatch(
                createWorkOrderTypes({
                    name: workOrderTypeData.name,
                }),
            );
            dispatch(closeWorkOrderTypeModalCreate());
            dispatch(resetWorkOrderType());
        }
        if (categoryType === 'workOrder-service-request') {
            dispatch(
                createWorkOrderServiceRequests({
                    name: workOrderServiceRequestData.name,
                }),
            );
            dispatch(closeWorkOrderServiceRequestModalCreate());
            dispatch(resetWorkOrderServiceRequest());
        }
    };

    function displayToast() {
        if (isWorkOrderCategoryCreateSuccess) {
            toast.success('Work Order Category Created Successfully', {
                toastId: 'workOrderCategoryCreateSuccess',
            });
            dispatch(resetWorkOrderCategory());
        }
        if (isWorkOrderCategoryUpdateSuccess) {
            toast.success('Work Order Category Updated Successfully', {
                toastId: 'workOrderCategoryUpdateSuccess',
            });
            dispatch(resetWorkOrderCategory());
        }
        if (isWorkOrderCategoryDeleteSuccess) {
            toast.success('Work Order Category Deleted Successfully', {
                toastId: 'workOrderCategoryDeleteSuccess',
            });
            dispatch(resetWorkOrderCategory());
        }
        if (isWorkOrderTypeCreateSuccess) {
            toast.success('Work Order Sub Category Created Successfully', {
                toastId: 'workOrderTypeCreateSuccess',
            });
            dispatch(resetWorkOrderType());
        }
        if (isWorkOrderTypeUpdateSuccess) {
            toast.success('Work Order Sub Category Updated Successfully', {
                toastId: 'workOrderTypeUpdateSuccess',
            });
            dispatch(resetWorkOrderType());
        }
        if (isWorkOrderTypeDeleteSuccess) {
            toast.success('Work Order Sub Category Deleted Successfully', {
                toastId: 'workOrderTypeDeleteSuccess',
            });
            dispatch(resetWorkOrderType());
        }
        if (isWorkOrderServiceRequestCreateSuccess) {
            toast.success('Failure Code Created Successfully', {
                toastId: 'workOrderServiceRequestCreateSuccess',
            });
            dispatch(resetWorkOrderServiceRequest());
        }
        if (isWorkOrderServiceRequestUpdateSuccess) {
            toast.success('Failure Code Updated Successfully', {
                toastId: 'workOrderServiceRequestUpdateSuccess',
            });
            dispatch(resetWorkOrderServiceRequest());
        }
        if (isWorkOrderServiceRequestDeleteSuccess) {
            toast.success('Failure Code Deleted Successfully', {
                toastId: 'workOrderServiceRequestDeleteSuccess',
            });
            dispatch(resetWorkOrderServiceRequest());
        }
        if (isWorkOrderValidatorCreateSuccess) {
            toast.success('Work Order validator Created Successfully', {
                toastId: 'workOrderTypeDeleteSuccess',
            });
            dispatch(resetWorkOrderValidator());
            dispatch(resetWorkOrderVaildatorField());
            setIsShowAdd(false)
        }
        if (isWorkOrderValidatorDeleteSuccess) {
            toast.success('Work Order Validator Deleted Successfully', {
                toastId: 'workOrderTypeDeleteSuccess',
            });
            dispatch(resetWorkOrderValidator());
        }
        if (isWorkOrderValidatorUpdateSuccess) {
            toast.success('Work Order validator Updated Successfully', {
                toastId: 'workOrderTypeDeleteSuccess',
            });
            dispatch(resetWorkOrderValidator());
        }
    }



    const checkLocationExist = (loc) => {
        const locationIds = workOrderValidators?.map(item => item.location?.id)
        const isExist = locationIds?.includes(loc)
        return isExist
    }


    const handelAddValidator = async () => {
        const validatorObj = {
            location: validatorLocation?.value,
            user: validatorPerson?.value,
            is_active: validatorActive,
        }
        if (validatorLocation && validatorPerson) {
            if (checkLocationExist(validatorLocation?.value)) {
                toast.warning('This location already have authorized person ', {
                    toastId: 'WorkOrder vaildator',
                });
                return;
            }
            await dispatch(createWorkOrderValidators(validatorObj))
        }
        else {
            toast.warning('Please fill the location and pic name and try again', {
                toastId: 'WorkOrder vaildator',
            });
        }
    }

    return isWorkOrderCategoryCreateLoading ||
        isWorkOrderCategoryDeleteLoading ||
        isWorkOrderCategoryUpdateLoading ||
        isWorkOrderTypeCreateLoading ||
        isWorkOrderTypeDeleteLoading ||
        isWorkOrderTypeUpdateLoading ||
        isWorkOrderServiceRequestCreateLoading ||
        isWorkOrderServiceRequestDeleteLoading ||
        isWorkOrderServiceRequestUpdateLoading ||
        isWorkOrderValidatorUpdateLoading ||
        isWorkOrderValidatorCreateLoading ||
        isWorkOrderValidatorDeleteLoading ? (
        <div id="loader" />
    ) : (
        <>
            {displayToast()}
            {workOrderCategoryModalCreate && (
                <PopUpAddModal
                    showModal={workOrderCategoryModalCreate}
                    handleCreateSave={handleCreateSave}
                    handleClose={() => dispatch(closeWorkOrderCategoryModalCreate())}
                    modalTitle="Add work order category"
                    primaryButtonText="Create work order category"
                    value={workOrderData && workOrderData.name}
                    onChange={e => {
                        setWorkOrderData({
                            ...workOrderData,
                            name: e.target.value,
                        });
                    }}
                >
                    <div className="col">
                        <div className="row">
                            <input type="hidden" id="inventory_id" value="" />
                            <span className="font-weight-normal">
                                Name <span className="font-danger m-0">*</span>
                            </span>
                            <input
                                name="inventory_name"
                                type="text"
                                className="form-control mb-2"
                                placeholder="Enter category name"
                                // value={workOrderData?.name}
                                onChange={e =>
                                    setWorkOrderData({
                                        ...workOrderData,
                                        name: e.target.value,
                                    })
                                }
                            />

                            <div className="col-lg-12 col-sm-12 m-0">
                                <span className="font-danger" id="inventory_name_error" />
                            </div>
                            <div className="col-lg-12 col-sm-12 m-0">
                                <span className="font-danger" id="inventory_error_msg" />
                            </div>
                        </div>
                    </div>
                </PopUpAddModal>
            )}
            {workOrderCategoryModalUpdate && (
                <PopUpEditModal
                    handleClose={() => dispatch(closeWorkOrderCategoryModalUpdate())}
                    showModal={workOrderCategoryModalUpdate}
                    handleSave={handleEditSave}
                    modalTitle="Edit work order category"
                    primaryButtonText="Save Changes"
                    modalBodyTextInput={workOrderData.name}
                    value={workOrderData && workOrderData.name}
                    onChange={e => {
                        setWorkOrderData({
                            ...workOrderData,
                            name: e.target.value,
                        });
                    }}
                />
            )}
            {workOrderCategoryModalDelete && (
                <PopUpDeleteModal
                    showModal={workOrderCategoryModalDelete}
                    handleClose={() => dispatch(closeWorkOrderCategoryModalDelete())}
                    handleConfirm={handleDelete}
                    modalTitle="Delete work order category"
                    modalBodyText="Are you sure you want to delete this work order category?"
                />
            )}
            {workOrderTypeModalCreate && (
                <PopUpAddModal
                    showModal={workOrderTypeModalCreate}
                    handleCreateSave={handleCreateSave}
                    handleClose={() => dispatch(closeWorkOrderTypeModalCreate())}
                    modalTitle="Add work order sub category"
                    primaryButtonText="Create work order sub category"
                    value={workOrderTypeData && workOrderTypeData.name}
                    onChange={e => {
                        setWorkOrderTypeData({
                            ...workOrderTypeData,
                            name: e.target.value,
                        });
                    }}
                >
                    <div className="col">
                        <div className="row">
                            <input type="hidden" id="inventory_id" value="" />
                            <span className="font-weight-normal">
                                Name <span className="font-danger m-0">*</span>
                            </span>
                            <input
                                name="inventory_name"
                                type="text"
                                className="form-control mb-2"
                                placeholder="Enter sub category name"
                                // value={workOrderData?.name}
                                onChange={e =>
                                    setWorkOrderTypeData({
                                        ...workOrderTypeData,
                                        name: e.target.value,
                                    })
                                }
                            />

                            <div className="col-lg-12 col-sm-12 m-0">
                                <span className="font-danger" id="inventory_name_error" />
                            </div>
                            <div className="col-lg-12 col-sm-12 m-0">
                                <span className="font-danger" id="inventory_error_msg" />
                            </div>
                        </div>
                    </div>
                </PopUpAddModal>
            )}
            {workOrderTypeModalUpdate && (
                <PopUpEditModal
                    handleClose={() => dispatch(closeWorkOrderTypeModalUpdate())}
                    showModal={workOrderTypeModalUpdate}
                    handleSave={handleEditSave}
                    modalTitle="Edit work order sub category"
                    primaryButtonText="Save Changes"
                    modalBodyTextInput={workOrderTypeData.name}
                    value={workOrderTypeData && workOrderTypeData.name}
                    onChange={e => {
                        setWorkOrderTypeData({
                            ...workOrderTypeData,
                            name: e.target.value,
                        });
                    }}
                />
            )}
            {workOrderTypeModalDelete && (
                <PopUpDeleteModal
                    showModal={workOrderTypeModalDelete}
                    handleClose={() => dispatch(closeWorkOrderTypeModalDelete())}
                    handleConfirm={handleDelete}
                    modalTitle="Delete work order sub category"
                    modalBodyText="Are you sure you want to delete this work order sub category?"
                />
            )}
            {workOrderServiceRequestModalCreate && (
                <PopUpAddModal
                    showModal={workOrderServiceRequestModalCreate}
                    handleCreateSave={handleCreateSave}
                    handleClose={() => dispatch(closeWorkOrderServiceRequestModalCreate())}
                    modalTitle="Add Failure Code"
                    primaryButtonText="Create Failure Code"
                    value={workOrderServiceRequestData && workOrderServiceRequestData.name}
                    onChange={e => {
                        setWorkOrderServiceRequestData({
                            ...workOrderServiceRequestData,
                            name: e.target.value,
                        });
                    }}
                >
                    <div className="col">
                        <div className="row">
                            <input type="hidden" id="inventory_id" value="" />
                            <span className="font-weight-normal">
                                Failure Code <span className="font-danger m-0">*</span>
                            </span>
                            <input
                                name="inventory_name"
                                type="text"
                                className="form-control mb-2"
                                placeholder="Enter Failure Code"
                                // value={workOrderData?.name}
                                onChange={e =>
                                    setWorkOrderServiceRequestData({
                                        ...workOrderServiceRequestData,
                                        name: e.target.value,
                                    })
                                }
                            />

                            <div className="col-lg-12 col-sm-12 m-0">
                                <span className="font-danger" id="inventory_name_error" />
                            </div>
                            <div className="col-lg-12 col-sm-12 m-0">
                                <span className="font-danger" id="inventory_error_msg" />
                            </div>
                        </div>
                    </div>
                </PopUpAddModal>
            )}
            {workOrderServiceRequestModalUpdate && (
                <PopUpEditModal
                    handleClose={() => dispatch(closeWorkOrderServiceRequestModalUpdate())}
                    showModal={workOrderServiceRequestModalUpdate}
                    handleSave={handleEditSave}
                    modalTitle="Edit Failure Code"
                    primaryButtonText="Save Changes"
                    modalBodyTextInput={workOrderServiceRequestData?.name}
                    value={workOrderServiceRequestData && workOrderServiceRequestData?.name}
                    onChange={e => {
                        setWorkOrderServiceRequestData({
                            ...workOrderServiceRequestData,
                            name: e.target.value,
                        });
                    }}
                />
            )}
            {workOrderServiceRequestModalDelete && (
                <PopUpDeleteModal
                    showModal={workOrderServiceRequestModalDelete}
                    handleClose={() => dispatch(closeWorkOrderServiceRequestModalDelete())}
                    handleConfirm={handleDelete}
                    modalTitle="Delete Failure Code"
                    modalBodyText="Are you sure you want to delete this Failure Code?"
                />
            )}
            <div className="p-0 m-0">
                <div className="Work-order-validator">
                    <div className='Work-order-validator-left'>
                        <h5>Work order categories</h5>
                        <p>Classify the different types of work orders; this will help you
                            group similar requests and make it easier to assign and track them.</p>
                    </div>
                    <div className='Work-order-validator-right'>
                        <div className="col-lg-12 col-sm-12 ">
                            <div className="p-0 ">
                                <div className="category-header">
                                    <h4>Category Name</h4>
                                    <button
                                        type="button"
                                        className="mr-2 cursor_pointer"
                                        onClick={() => {
                                            dispatch(setCategoryType('workOrder'));
                                            handleCreate('workOrder');
                                        }}
                                    >
                                        Add new
                                    </button>
                                </div>
                                <div className="p-0" id="wocategory-block">
                                    {workOrderCategories && workOrderCategories.length > 0 ? (
                                        workOrderCategories.map(category => (
                                            <div
                                            >
                                                <div className="category-body">
                                                    <span className="">
                                                        <span title="{{category.category}}">
                                                            <h6 id="wocategory-value-{{category.id}}">{category.name}</h6>
                                                        </span>
                                                    </span>
                                                    <div className="card-tools">
                                                        {category?.is_changeable ? (
                                                            <span>
                                                                <button type='button' className='Work-Order-cutomization-button mr-2'
                                                                    onClick={() => {
                                                                        dispatch(setCategoryType('workOrder'));
                                                                        handleEdit(category, 'workOrder');
                                                                    }}
                                                                >
                                                                    <img src={editIcon} alt="Edit Icon" />
                                                                </button>
                                                                <button type='button' title="Edit Category"
                                                                    className='Work-Order-cutomization-button'
                                                                    onClick={() => {
                                                                        dispatch(setCategoryType('workOrder'));
                                                                        handleConfirm(category?.id, 'workOrder');
                                                                    }}
                                                                >
                                                                    <img src={deleteIcon} alt="Delete Icon" />
                                                                </button>
                                                            </span>
                                                        ) : (
                                                            <button type='button' className='Work-Order-cutomization-button'>
                                                                <i className="fa fa-lock text-black" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="there-no-data">
                                            There is no data yet
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="Work-order-validator">
                    <div className='Work-order-validator-left'>
                        <h5>Work order sub-category</h5>
                        <p>Provide more detailed work order categories for better organization,
                            ensuring that specific types of requests are handled appropriately.</p>
                    </div>
                    <div className="Work-order-validator-right">
                        <div className="col-lg-12 col-sm-12">
                            <div className="p-0">
                                <div className="category-header">
                                    <h4>Category Name</h4>
                                    <button
                                        type="button"
                                        className="mr-2 cursor_pointer"
                                        onClick={() => {
                                            dispatch(setCategoryType('workOrder-type'));
                                            handleCreate('workOrder-type');
                                        }}
                                    >
                                        Add new
                                    </button>
                                </div>
                                <div className="p-0" id="wocategory-block">
                                    {workOrderTypes && workOrderTypes.length > 0 ? (
                                        workOrderTypes.map(category => (
                                            <div>
                                                <div className="category-body">
                                                    <span>
                                                        <span >
                                                            <h6>{category.name}</h6>
                                                        </span>
                                                    </span>
                                                    <div className="card-tools">
                                                        {category?.is_changeable ? (

                                                            <span>
                                                                <button type='button' className='Work-Order-cutomization-button mr-2'
                                                                    title="Edit Category"
                                                                    onClick={() => {
                                                                        dispatch(setCategoryType('workOrder-type'));
                                                                        handleEdit(category, 'workOrder-type');
                                                                    }}
                                                                >
                                                                    <img src={editIcon} alt="Edit Icon" />
                                                                </button>
                                                                <button type='button' title="Edit Category"
                                                                    className='Work-Order-cutomization-button'
                                                                    onClick={() => {
                                                                        dispatch(setCategoryType('workOrder-type'));
                                                                        handleConfirm(category?.id, 'workOrder-type');
                                                                    }}
                                                                >
                                                                    <img src={deleteIcon} alt="Delete Icon" />
                                                                </button>
                                                            </span>
                                                        ) : (
                                                            <i className="fa fa-lock text-black" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="there-no-data">
                                            There is no data yet
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="Work-order-validator">
                    <div className='Work-order-validator-left'>
                        <h5>Failure Code</h5>
                        <p>A predefined label used in maintenance systems to categorize and
                            describe the root cause of equipment or process failures, helping to streamline troubleshooting,
                            track recurring issues, and improve maintenance strategies. (maximum 10 failure codes)
                        </p>
                    </div>
                    <div className="Work-order-validator-right">
                        <div className="col-lg-12 col-sm-12">
                            <div className="p-0">
                                <div className="category-header">
                                    <h4>List of Failure Code</h4>
                                    {workOrderServiceRequests?.length <= 9 && <button
                                        type="button"
                                        className="mr-2 cursor_pointer"
                                        onClick={() => {
                                            dispatch(setCategoryType('workOrder-service-request'));
                                            handleCreate('workOrder-service-request');
                                        }}
                                    >
                                        Add new
                                    </button>}
                                </div>
                                <div className="p-0" id="wocategory-block">
                                    {workOrderServiceRequests && workOrderServiceRequests.length > 0 ? (
                                        workOrderServiceRequests.map(category => (
                                            <div>
                                                <div className="category-body">
                                                    <span>
                                                        <span >
                                                            <h6>{category.name}</h6>
                                                        </span>
                                                    </span>
                                                    <div className="card-tools">
                                                        {category?.is_changeable ? (
                                                            <span>
                                                                <button type='button' className='Work-Order-cutomization-button mr-2'
                                                                    title="Edit Category"
                                                                    onClick={() => {
                                                                        dispatch(setCategoryType('workOrder-service-request'));
                                                                        handleEdit(category, 'workOrder-service-request');
                                                                    }}
                                                                >
                                                                    <img src={editIcon} alt="Edit Icon" />
                                                                </button>
                                                                <button type='button' title="Edit Category"
                                                                    className='Work-Order-cutomization-button'
                                                                    onClick={() => {
                                                                        dispatch(setCategoryType('workOrder-service-request'));
                                                                        handleConfirm(category?.id, 'workOrder-service-request');
                                                                    }}
                                                                >
                                                                    <img src={deleteIcon} alt="Delete Icon" />
                                                                </button>
                                                            </span>
                                                        ) : (
                                                            <i className="fa fa-lock text-black" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="there-no-data">
                                            There is no data yet
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='Work-order-validator'>
                    <div className='Work-order-validator-left'>
                        <h5>Work order validator</h5>
                        <p>Assign an authorized person to approve and validate the completion of work order requests.
                            Note: One Person in Charge (PIC) per location.</p>
                    </div>
                    <div className='Work-order-validator-right'>
                        <table>
                            <thead>
                                <tr>
                                    <td>Location</td>
                                    <td>PIC name</td>
                                    <td>Active</td>
                                    <td className='Work-order-validator-actions'>
                                        <span onClick={() => setIsShowAdd(!isShowAdd)}>Add new</span>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {isShowAdd && <tr>
                                    <td>
                                        <SelectLocationFilter locations={locations} site={validatorLocation} setFilterSite={setValidatorLocation} validator />
                                    </td>
                                    <td>
                                        <SelectStaff teams={teams?.results} staff={validatorPerson} setStaff={setValidatorPerson} validator />
                                    </td>
                                    <td>
                                        <div className="col-lg-12 col-sm-12 pt-2">
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span>
                                                    <input type="checkbox" className='cost-status'
                                                        onChange={() => dispatch(setValidatorActive(!validatorActive))} checked={validatorActive} />
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <button type='button' className='mr-2' onClick={handelAddValidator}>Save</button>
                                        <button type='button' onClick={() => setIsShowAdd(false)}>X</button>
                                    </td>
                                </tr>}


                                {workOrderValidators?.length !== 0 ? workOrderValidators?.map(item => {
                                    return <ValidatorTable checkLocationExist={checkLocationExist} key={item?.id} item={item} handleConfirm={handleConfirm} />
                                })
                                    :
                                    <tr style={{ textAlign: "center", }}>
                                        <td colSpan="4" style={{ display: "table-cell", width: "100%" }} className="there-no-data">
                                            There is no data yet
                                        </td>
                                    </tr>

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {workOrderValidatorModalDelete && (
                    <PopUpDeleteModal
                        showModal={workOrderValidatorModalDelete}
                        handleClose={() => dispatch(closeWorkOrderValidatorModalDelete())}
                        handleConfirm={handleDelete}
                        modalTitle="Delete work order validator"
                        modalBodyText="Are you sure you want to delete this work order validator?"
                    />
                )}
            </div>
        </>
    );
}