/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Redirect } from 'react-router-dom';
import './NotFound.css'; // External CSS for better styling
import { useSelector } from 'react-redux';

function NotFoundPage() {
  const { profile } = useSelector((state) => state.company);
  const PSD = profile?.name === 'TNB PROPERTY SERVICES DEPARTMENT';
    
  return <Redirect to={PSD ? '/ebq' : '/'} />;
}

export default NotFoundPage;

// const { profile } = useSelector((state) => state.company);
// const PSD = profile?.name === 'TNB PROPERTY SERVICES DEPARTMENT';

// return (
//   <div className="not-found-container">
//     <h1 className="not-found-title">404</h1>
//     <p className="not-found-subtitle">Oops! The page you're looking for doesn't exist.</p>
//     <p className="not-found-description">
//       It might have been removed, had its name changed, or is temporarily unavailable.
//     </p>
//     <Link to={PSD ? '/ebq' : '/'} className="not-found-link">
//       Go Back to Home
//     </Link>
//   </div>
