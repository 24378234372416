/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable new-cap */

import React, { useState, useEffect } from 'react';
import FilterField from './FilterField/FilterField';
import './AnalyticDashboard.scss';
import assetBanner from '../../assets/img/analyticBanner.jpg';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticMainPage from './AnalyticMainPage/AnalyticMainPage';
import commingSoon from '../../assets/img/coming_soon.png';
import { getAnalyticsForDashboard } from '../../redux/features/Analytics/analyticsSlice';
import { format } from 'date-fns';


function AnalyticDashboard() {
  const [activeButton, setActiveButton] = useState(1);
  const { user } = useSelector(state => state.user);
  const backgroundImageUrl = `url(${assetBanner})`;

  const {
    analytics,
    dateWord,
    custom_start,
    custom_end,
    filter,
    site,
    location,
    staff,
    categoryFilter,
    typeFilter,
    customDate,
    dateFilterState
  } = useSelector(state => state.analytics);

  const [intervals, setIntervals] = useState('day');
  const [workCategory, setWorkCategory] = useState([0, 0]);
  const [totalWorkOrder, setTotalWorkOrder] = useState(0);
  const [summaryData, setSummaryData] = useState({});
  const [overallData, setOverallData] = useState({});
  const [comparingData, setComparingData] = useState({});
  const [openWork, setOpenWork] = useState([]);
  const [closeWork, setCloseWork] = useState([]);
  const trendWorkData = {
    open: [],
    close: [],
    workDates: [],
  };
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      getAnalyticsForDashboard({
        filter: `custom&custom_start=${format(dateFilterState[0].startDate, 'yyyy-MM-dd')}&custom_end=${format(
          dateFilterState[0].endDate,
          'yyyy-MM-dd',
        )}`,
        site: site?.value || null,
        location: location?.value || null,
        staff: staff?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  }, []);
  // filter, custom_start, custom_end, site, location, staff, typeFilter, categoryFilter


  useEffect(() => {
    setWorkCategory([
      parseFloat(analytics?.tickets_based_on_category?.preventive),
      parseFloat(analytics?.tickets_based_on_category?.corrective, 10),
    ]);
    setOverallData(analytics?.overall_progress)
    setTotalWorkOrder(analytics?.tickets_count?.total_tickets || 0);
    setSummaryData(analytics?.ticket_count_by_category);
    setComparingData(analytics?.comparing_data)
    if (filter === null || filter?.value === 'last15days' || dateWord === 'custom') {
      setIntervals('day');
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);
    } else if (filter?.value === 'last30days') {
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);

      setIntervals('Day');
      // if (intervals === 'day') {
      //   setOpenWork(analytics?.tickets_by_status?.open_day);
      //   setCloseWork(analytics?.tickets_by_status?.close_day);
      // } else {
      //   setIntervals('week');
      //   setOpenWork(analytics?.tickets_by_status?.open_week);
      //   setCloseWork(analytics?.tickets_by_status?.close_week);
      // }
    } else if (filter?.value === 'last365days') {
      setOpenWork(analytics?.tickets_by_status?.open_month);
      setCloseWork(analytics?.tickets_by_status?.close_month);
      setIntervals('month');
      // if (intervals === 'month') {
      //   setOpenWork(analytics?.tickets_by_status?.open_month);
      //   setCloseWork(analytics?.tickets_by_status?.close_month);
      // } else {
      //   setIntervals('quarter');
      //   setOpenWork(analytics?.tickets_by_status?.open_quarter);
      //   setCloseWork(analytics?.tickets_by_status?.close_quarter);
      // }
    }
  }, [analytics, intervals, filter]);
  function handleWork() {
    openWork?.forEach(work => {
      trendWorkData.workDates.push(work[1].split('T')[0]);
      trendWorkData.open.push(work[0]);
    });
    closeWork?.forEach(work => {
      trendWorkData.close.push(work[0]);
    });
  }
  handleWork();
  const handleButtonClick = buttonIndex => {
    setActiveButton(buttonIndex);
  };

  return (
    <div
      style={{
        backgroundColor: 'White',
        marginLeft: '10px',
        marginRight: '10px',
        paddingTop: '10px',
        borderRadius: '5px',
        marginBottom: '20px',
      }}
    >
      <div className="row col-lg-12 col-12 mx-auto mt-3">
        <div className="col-12">
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0.8) ), ${backgroundImageUrl}`,
              // backgroundSize: 'contain', // or '150%' for a custom zoom-out level
              backgroundSize: '100%',
              backgroundPosition: 'center 60%',
              height: '240px',
              borderRadius: '10px',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end'
            }}
          >
          </div>
        </div>
      </div>
      <div className="row col-lg-12 col-12  mt-3">
        <div
          className="col-12 ms-3"
          style={{
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '32px',
            marginBottom: "34px",
          }}
        >
          Welcome {user?.first_name} {user?.last_name}
        </div>
        {activeButton === 1 ? (
          <>
            <div className="col-12 ms-3" style={{ marginBottom: "24px" }}>
              <FilterField />
            </div>
            <div className="col-12">
              <AnalyticMainPage />
            </div>
          </>
        ) : (
          <div style={{ height: '100vh', marginLeft: "auto", marginRight: "auto" }}>
            <div>
              <img src={commingSoon} alt="Kola" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AnalyticDashboard;
