import React from 'react'

function ReportIcon() {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 200 200"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0,200) scale(0.1,-0.1)"
                fill="none"
                stroke="var(--grey-content)"
                strokeWidth="200"
                strokeLinecap="round"
            >
                <path d="M935 1781 c-22 -10 -50 -29 -61 -42 -36 -40 -746 -1278 -756 -1318 -20 -80 40 -185 120 -209 29 -9 243 -12 776 -12 786 0 776 -1 829 48 53 48 73 152 41 215 -38 74 -728 1264 -743 1281 -23 27 -90 56 -131 56 -19 0 -53 -9 -75 -19z m173 -427 c15 -10 22 -25 22 -47 -1 -67 -79 -599 -90 -613 -16 -18 -64 -18 -80 1 -11 14 -90 546 -90 612 0 49 28 63 130 63 61 0 92 -4 108 -16z m-54 -815 c21 -17 26 -29 26 -65 0 -101 -117 -132 -155 -41 -37 88 55 164 129 106z" />
            </g>
        </svg>
    )
}

export default ReportIcon