/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ButtonBase, IconButton, Modal } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { getPagination, setDateFilterState, setFilterAdvanceStatus, setFilterEndDate, setFilterStartDate, setFilterStatus, setStaff, setWOFilterAsset, setWOFilterCategory, setWOFilterSite, setWOFilterSublocation, setWOFiltertype } from '../../redux/features/workOrder/workOrderSlice';
import { SelectAssetFilter, SelectLocationFilter, SelectStaff, SelectSublocationFilter, SelectTypesAndCategories } from '../../components/select/select';
import "./WOFilter.scss"
// import IconVerticalBox from './icons/IconVerticalBox';
// import IconDownload from './icons/IconDownload';
import classes from '../../components/assetsTable/assetsTable.module.css';
import ExportWO from './Componets/ExportWO';
import IconVerticalBox from '../../assets/svg-icons/IconVerticalBox';
import IconDownload from '../../assets/svg-icons/IconDownload';
import ReloadIcon from '../../assets/svg-icons/ReloadIcon';
import DateRangeFilter from '../viewMaintenance/Componets/DateRangeFilter';


const WOFilter = ({
  setISShowFilter,
  iSShowFilter,
  allColumns,
  setIsLoading,
  getDataRows
}) => {
  const dispatch = useDispatch();
  const [iSShowExportPopup, setISShowExportPopup] = useState(false)
  const [locationsData, setLocations] = useState([]);
  const { locations } = useSelector((state) => state.location);
  const { workOrderCategories } = useSelector((state) => state.workOrderCategory);
  const { workOrderTypes } = useSelector((state) => state.workOrderType);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const { teams } = useSelector((state) => state.teams);
  const { user } = useSelector((state) => state.user);
  const { assetListSearch } = useSelector((state) => state.asset);

  const statusData = [
    { id: 1, name: 'Open' },
    { id: 2, name: 'In-Progress' },
    { id: 3, name: 'On-Hold' },
    { id: 4, name: 'Completed' },
  ]
  const
    {
      rowPerPage,
      currentPage,
      searchValue,
      site,
      sublocation,
      filterCategory,
      type,
      staff,
      filterAdvanceStatus,
      filterAsset,
      filterStatus,
      dateFilterState,
      filterStartDate,
      filterEndDate,
    } = useSelector(state => state.workOrder)

  const resetState = () => {
    dispatch(setWOFilterSite(null));
    dispatch(setWOFilterSublocation(null));
    dispatch(setWOFilterCategory(null));
    dispatch(setWOFiltertype(null));
    dispatch(setFilterAdvanceStatus([
      { label: "Open", selected: false },
      { label: "In-Progress", selected: false },
      { label: "On-Hold", selected: false },
      { label: "Completed", selected: false }
    ]));
    dispatch(setFilterStatus(null))
    dispatch(setStaff(null));
    dispatch(setWOFilterAsset(null));
  }



  const handleEraserFilterClick = async () => {
    setLocations([]);
    // reset the state and store
    dispatch(setWOFilterSite(null));
    dispatch(setWOFilterSublocation(null));

    const queryString = filterAdvanceStatus
      .filter(status => status.selected) // Only keep selected items
      .map(status => `status=${status.label}`)
      .join('&');
    setIsLoading(true)
    const filters =
    {
      rowPerPage,
      currentPage,
      searchValue,
      site:  null,
      sublocation:  null,
      filterCategory: filterCategory?.value || null,
      type: type?.value || null,
      staff: staff?.value || null,
      status: filterStatus?.label && filterStatus?.label !== "All" ? filterStatus?.label : null,
      statusAdvance: queryString,
      asset: filterAsset?.value || null,
      startDate: dateFilterState?.find(item => item.startDate)?.startDate ? moment(dateFilterState?.find(item => item.startDate)?.startDate).format("YYYY-MM-DD") : "",
      endDate: dateFilterState?.find(item => item.startDate)?.startDate ? moment(dateFilterState?.find(item => item.endDate)?.endDate).format("YYYY-MM-DD") : "",
    }
    await dispatch(getPagination(filters))
    setIsLoading(false)
  };


  const handleEraserAdvancedFilterClick = ()=>{
    setLocations([]);
    resetState()
  }

  useEffect(() => {
    setLocations(sublocations || [])
  }, [sublocations])

  // Advance filter dialog start
  const [openAdvFilter, setOpenAdvFilter] = useState(false)

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true)
  }

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false)
  }
  // Advance filter dialog end

  const buttonDisabledStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    backgroundColor: '#D9314A',
    borderRadius: '8px',
  };

  const buttonStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#36383C',
    backgroundColor: '#F8F9F9',
    border: '1px solid #E1E1E1',
    borderRadius: '8px',
  };


  const handelFetchFilters = () => {
    getDataRows()
  }


  const handleActivateAdvFilter = () => {
    getDataRows()
  }

  const handelStatusChange = (item) => {
    const updatedStatus = filterAdvanceStatus.map(ele => {
      if (ele.label === item.label) {
        return { ...ele, selected: !item.selected }
      }
      return ele
    })
    dispatch(setFilterAdvanceStatus(updatedStatus))
  }


  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleUpdate = async () => {
    setShow(false);
    dispatch(setFilterStartDate(dateFilterState?.find(item => item.startDate)?.startDate))
    dispatch(setFilterEndDate(dateFilterState?.find(item => item.endDate)?.endDate))
  };

  return (
    // <div className="filters-wrapper">
    <div className="wo-filters-wrapper" style={{ marginBottom: '8px' }}>
      <div style={{ display: 'flex', gap: '16px' }}>
        <div className="filter-wrapper" style={{ width: '220px' }}>
          {/* <label style={{ color: '#d63c3c', fontWeight: 500 }}>Location </label> */}
          <label style={{ color: '#36383C', fontWeight: 500 }}>Location </label>
          <SelectLocationFilter
            locations={locations}
            site={site}
            setFilterSite={setWOFilterSite}
            setFilterSublocation={setWOFilterSublocation}
            setLocations={setLocations}
          />
        </div>
        <div className="filter-wrapper" style={{ width: '220px' }}>
          {/* <label style={{ color: '#d63c3c', fontWeight: 500 }}>Sublocation</label> */}
          <label style={{ color: '#36383C', fontWeight: 500 }}>Sublocation</label>
          <SelectSublocationFilter sublocationsData={locationsData} sublocation={sublocation} setFilterSublocation={setWOFilterSublocation} site={site} />
        </div>
        <button style={{ alignSelf: "flex-end", padding: ".375rem .7rem", }} className='new-btn btn' onClick={handelFetchFilters} title="Reload">
          {/* <ReloadIcon /> */}
          <FontAwesomeIcon icon={faRotate} style={{ color: 'black' }} />
        </button>
        <button style={{ alignSelf: "flex-end", marginLeft: "-10px" }} className='new-btn btn' onClick={handleEraserFilterClick} title="Reset">
          <ReloadIcon />
        </button>
      </div>

      <Modal
        open={openAdvFilter}
        onClose={handleCloseAdvFilter}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '480px',
            padding: '12px 16px',
            backgroundColor: '#F8F9F9',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              fontWeight: '600',
              fontSize: '21px',
              marginBottom: '20px',
            }}
          >
            Advance filter
          </div>
          <div className='wo-filter-wrapper' style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div className="filter-wrapper">
              <div className='filters-left-wrapper-period'>
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Due Date </label>
                <div className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => setShow(!show)}>
                  {filterStartDate ? <><span>{moment(filterStartDate).format("DD MMM YYYY")}</span> - <span>{moment(filterEndDate).format("DD MMM YYYY")}</span> </> : "All time"}
                </div>
                {
                  show && <>
                    <div className="tck-overlay" onClick={() => setShow(!show)} />
                    <DateRangeFilter
                      show={show}
                      handleUpdate={handleUpdate}
                      handleClose={handleClose}
                      state={dateFilterState}
                      setState={setDateFilterState}
                      advance
                    />
                  </>
                }
              </div>
            </div>
            {/* todo: submitted date */}
            <div className="filter-wrapper">
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Assigned To</label>
              <SelectStaff staff={staff} setStaff={setStaff} teams={teams?.results} />
            </div>
            {/* todo: choose assets */}
            <div className="filter-wrapper">
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Choose assets </label>
              <SelectAssetFilter filterAsset={filterAsset} setFilterAsset={setWOFilterAsset} assets={assetListSearch} />
            </div>
            <div>
              <div className="filter-wrapper" style={{ width: '100%', display: 'flex', gap: '16px' }}>
                <div style={{ flex: 1 }}>
                  <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Category</label>
                  <SelectTypesAndCategories dataArray={workOrderCategories} stateValue={filterCategory} setState={setWOFilterCategory} />
                </div>
                <div style={{ flex: 1 }}>
                  <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Sub Category</label>
                  <SelectTypesAndCategories dataArray={workOrderTypes} stateValue={type} setState={setWOFiltertype} />
                </div>
              </div>
              {/* <div className="filter-wrapper">
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Sub Category</label>
                <SelectTypesAndCategories dataArray={workOrderTypes} stateValue={type} setState={setWOFiltertype} />
              </div> */}
            </div>
            {/* <div className="filter-wrapper">
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Status</label>
              <SelectTypesAndCategories dataArray={statusData} stateValue={filterStatus} setState={setFilterStatus} />
            </div> */}
            <div>
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Status</label>
              <div style={{ marginBottom: "16px", width: '100%', display: 'flex', gap: '16px', flexWrap: "wrap" }}>
                {filterAdvanceStatus.map(item => (
                  <div className='filter-wrapper-status'>
                    <label className="weekly-frequency-switch black-switch">
                      <input type="checkbox"
                        onChange={() => handelStatusChange(item)}
                        checked={item.selected}
                        disabled={filterStatus?.label === "Pending" || filterStatus?.label === "Declined"}
                      />
                      <span className="weekly-frequency-slider" />
                    </label>
                    <span className="weekly-frequency-label">{item.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ButtonBase autoCapitalize="none" onClick={handleEraserAdvancedFilterClick} style={buttonStyle}>
              Reset
            </ButtonBase>
            <div style={{ display: 'flex', gap: '12px' }}>
              <ButtonBase onClick={handleCloseAdvFilter} style={buttonStyle}>
                Close
              </ButtonBase>
              <ButtonBase onClick={handleActivateAdvFilter} style={buttonDisabledStyle}>
                Activate
              </ButtonBase>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ display: 'flex', gap: '12px' }}>
        <Button variant="contained" style={{
          backgroundColor: '#36383C',
          color: '#F8F9F9',
          fontSize: '14px',
          fontWeight: '600',
          padding: '8px 16px',
          borderRadius: '8px'
        }}
          onClick={handleOpenAdvFilter}
          className='adv-button'
        >
          Advance filter
        </Button>
        <div style={{ position: "relative" }}>
          <IconButton
            style={{
              backgroundColor: '#F8F9F9',
              borderRadius: '8px',
              border: '1px solid #E1E1E1'
            }}
            variant="contained"
            title="Column selection"
            className='outlined-button'
            onClick={() => setISShowFilter(!iSShowFilter)}
          ><IconVerticalBox />
          </IconButton>
          {iSShowFilter && (
            <>
              <div className="tck-overlay" onClick={() => setISShowFilter(!iSShowFilter)} />
              <div className={classes.newFilterTable}>
                <div className={classes.newFilterTableCheckboxs}>
                  <p className='weekly-frequency-label ml-0'>Filter column</p>
                  {/* Loop through columns data to create checkbox */}
                  {allColumns.map(column => {
                    if (column.id !== 'delete-table-column') {
                      return (
                        <div key={column.id} className={classes.newFilterTableCheckboxs}>
                          <label className="weekly-frequency-switch black-switch">
                            <input type="checkbox"  {...column.getToggleHiddenProps()} />
                            <span className="weekly-frequency-slider" />
                          </label>
                          <span className="weekly-frequency-label">{column.Header}</span>
                        </div>
                      );
                    }
                  })}
                  <br />
                </div>
              </div>
            </>
          )}
        </div>
        {user?.role !== "Technical Team" &&
          <>
            <IconButton title='Download' variant="contained"
              style={{
                backgroundColor: '#F8F9F9',
                borderRadius: '8px',
                border: '1px solid #E1E1E1'
              }}
              onClick={() => setISShowExportPopup(!iSShowExportPopup)}
              className='outlined-button'>
              <IconDownload />
            </IconButton>
            <ExportWO
              iSShowExportPopup={iSShowExportPopup}
              setISShowExportPopup={setISShowExportPopup}
            />
          </>
        }
      </div>
    </div>
  );
};

export default WOFilter;
