/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from 'react';
import './FilterField.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamsByAuthentication } from '../../../redux/features/teams/teamSlice';
import {
  getLocationsByAuthentication,
} from '../../../redux/features/location/locationSlice';

import {
  getAnalyticsForDashboard,
  setCategoryFilter,
  setCustomDate,
  setCustomEnd,
  setCustomStart,
  setDate,
  setDateFilterState,
  setFilterEndDate,
  setFilterStartDate,
  setLocation,
  setSite,
  setStaff,
  setType,
  setdateWord,
} from '../../../redux/features/Analytics/analyticsSlice';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import format from 'date-fns/format';
import { addDays, subDays } from 'date-fns';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { getWorkOrderTypeByAuthentication } from '../../../redux/features/workOrderType/workOrderTypeSlice';
import { getWorkOrderCategoryByAuthentication } from '../../../redux/features/workOrderCategory/workOrderCategorySlice';
import {
  SelectLocationFilter,
  SelectStaff,
  SelectSublocationFilter,
  SelectTypesAndCategories,
} from '../../select/select';
import moment from 'moment';
import DateRangeFilter from '../../../pages/viewMaintenance/Componets/DateRangeFilter';
import ReloadIcon from '../../../assets/svg-icons/ReloadIcon';

const FilterField = () => {
  const dispatch = useDispatch();
  const { workOrderCategories } = useSelector((state) => state.workOrderCategory);
  const { workOrderTypes } = useSelector((state) => state.workOrderType);
  const [locationsData, setLocations] = useState([]);
  const [show, setShow] = useState(false);
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const [dateState, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const { teams } = useSelector((state) => state.teams);
  const { locations } = useSelector((state) => state.location);
  const {
    site,
    location: loc,
    staff: staf,
    categoryFilter,
    typeFilter,
    filter,
    customDate,
    dateFilterState,
    filterStartDate,
    filterEndDate,
  } = useSelector((state) => state.analytics);

  const resetState = () => {
    const today = new Date();
    dispatch(setDate(null));
    dispatch(setSite(null));
    dispatch(setLocation(null));
    dispatch(setType(''));
    dispatch(setCategoryFilter(''));
    dispatch(setStaff(null));
    dispatch(setFilterStartDate(subDays(today, 7)));
    dispatch(setFilterEndDate(today));
    dispatch(
      setDateFilterState([
        {
          startDate: subDays(today, 7),
          endDate: today,
          key: 'selection',
        },
      ]),
    );
  };

  useEffect(() => {
    dispatch(getTeamsByAuthentication());
    dispatch(getLocationsByAuthentication());
    dispatch(getWorkOrderTypeByAuthentication());
    dispatch(getWorkOrderCategoryByAuthentication());
  }, []);

  useEffect(() => {
    setLocations(sublocations || []);
  }, [sublocations]);

  const handleClose = () => {
    dispatch(setDate(null));
    setShow(false);
    setShowDate(false);
  };
  const handleUpdate = () => {
    setShow(false);
    dispatch(setdateWord('custom'));
    dispatch(
      setCustomDate(
        `custom&custom_start=${format(dateState[0].startDate, 'yyyy-MM-dd')}&custom_end=${format(
          dateState[0].endDate,
          'yyyy-MM-dd',
        )}`,
      ),
    );
    dispatch(setCustomStart(format(dateState[0].startDate, 'yyyy-MM-dd')));
    dispatch(setCustomEnd(format(dateState[0].endDate, 'yyyy-MM-dd')));
  };

  const handleShow = () => setShow(!show);
  const handleDate = (e) => {
    const word = e.value;
    if (word === '') {
      dispatch(setDate(null));
    } else {
      dispatch(setDate(e));
    }
    if (word === 'custom') {
      handleShow();
      dispatch(setDate(e));
      setShowDate(true);
    } else {
      setShowDate(false);
    }
  };

  const handleCustomDateUpdate = async () => {
    setShowCustomDate(false);
    dispatch(setFilterStartDate(dateFilterState?.find((item) => item.startDate)?.startDate));
    dispatch(setFilterEndDate(dateFilterState?.find((item) => item.endDate)?.endDate));
  };
  const handleCustomDateClose = async () => {
    setShowCustomDate(false);
  };

  const handleEraserFilterClick = () => {
    setShowDate(false);
    setLocations([]);
    // reset the state as needed
    resetState();
    setIsReseted(!isReseted);
  };

  useEffect(() => {
    dispatch(
      getAnalyticsForDashboard({
        filter: `custom&custom_start=${format(
          dateFilterState[0].startDate,
          'yyyy-MM-dd',
        )}&custom_end=${format(dateFilterState[0].endDate, 'yyyy-MM-dd')}`,
        site: site?.value || null,
        location: loc?.value || null,
        staff: staf?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  }, [isReseted]);

  const handleFetchFilterClick = () => {
    dispatch(
      getAnalyticsForDashboard({
        filter: `custom&custom_start=${format(
          dateFilterState[0].startDate,
          'yyyy-MM-dd',
        )}&custom_end=${format(dateFilterState[0].endDate, 'yyyy-MM-dd')}`,
        site: site?.value || null,
        location: loc?.value || null,
        staff: staf?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  };
  const dateOptions = [
    { value: '', label: 'Last 7 days' },
    { value: 'last15days', label: 'Last 15 days' },
    { value: 'last30days', label: 'Last 30 days' },
    { value: 'last365days', label: 'Last 365 days' },
    { value: 'custom', label: 'Custom' },
  ];

  const downArrow = (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="css-tj5bde-Svg"
      style={{ fill: '#cccccc' }}
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
    </svg>
  );
  return (
    <div className="filters-wrapper">
      <div className="filter-wrapper" style={{ width: '210px' }}>
        <div className="filters-left-wrapper-period">
          <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Date </label>
          <div
            className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md "
            style={{
              fontSize: '14px',
              cursor: 'pointer',
              width: '210px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            onClick={() => setShowCustomDate(!showCustomDate)}
          >
            <div>
              {filterStartDate ? (
                <>
                  <span>{moment(filterStartDate).format('DD MMM YYYY')}</span> -{' '}
                  <span>{moment(filterEndDate).format('DD MMM YYYY')}</span>{' '}
                </>
              ) : (
                'All time'
              )}
            </div>
            <div style={{ borderLeft: '1px solid #cccccc', paddingLeft: '7px' }}>{downArrow}</div>
          </div>
          {showCustomDate && (
            <>
              <div className="tck-overlay" onClick={() => setShowCustomDate(!showCustomDate)} />
              <DateRangeFilter
                show={showCustomDate}
                handleUpdate={handleCustomDateUpdate}
                handleClose={handleCustomDateClose}
                state={dateFilterState}
                setState={setDateFilterState}
              />
            </>
          )}
        </div>
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Location </label>
        <SelectLocationFilter
          locations={locations}
          site={site}
          setFilterSite={setSite}
          setFilterSublocation={setLocation}
          setLocations={setLocations}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Sublocation</label>
        <SelectSublocationFilter
          sublocationsData={locationsData}
          sublocation={loc}
          setFilterSublocation={setLocation}
          site={site}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Category</label>
        <SelectTypesAndCategories
          dataArray={workOrderCategories}
          stateValue={categoryFilter}
          setState={setCategoryFilter}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Sub Category</label>
        <SelectTypesAndCategories
          dataArray={workOrderTypes}
          stateValue={typeFilter}
          setState={setType}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Staff</label>
        <SelectStaff staff={staf} setStaff={setStaff} teams={teams?.results} />
      </div>
      <button
        type="button"
        style={{
          alignSelf: 'flex-end',
          color: 'black',
          padding: '.375rem .7rem',
          display: 'flex',
          alignItems: 'center',
        }}
        className="new-btn btn"
        onClick={handleEraserFilterClick}
        title="Reset"
      >
        <ReloadIcon /> <span className="ml-2">Reset</span>
      </button>
      <button
        type="button"
        style={{
          alignSelf: 'flex-end',
          padding: '.375rem .7rem',
          color: 'black',
          marginLeft: '-10px',
        }}
        className="new-btn btn"
        onClick={handleFetchFilterClick}
        title="Reload"
      >
        <FontAwesomeIcon icon={faRotate} style={{ color: 'black' }} />{' '}
        <span className="ml-2">Reload</span>
      </button>
      <div className="px-2">
        <CustomDatePicker
          show={show}
          handleUpdate={handleUpdate}
          handleClose={handleClose}
          state={dateState}
          setState={setState}
        />
      </div>
    </div>
  );
};

export default FilterField;
