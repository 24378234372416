/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import inventoryService from './inventoryService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // inventorys
  inventorys: [],
  inventory: {},
  inventoryHistory: [],
  fileExport: [],
  fileImport: null,
  template: null,

  // inventory history
  isInventoryHistoryGetLoading: false,
  isInventoryHistoryGetSuccess: false,
  isInventoryHistoryGetError: false,
  // inventory Adjustmenthistory
  isInventoryAdjustmentHistoryGetLoading: false,
  isInventoryAdjustmentHistoryGetSuccess: false,
  isInventoryAdjustmentHistoryGetError: false,
  // modals
  InventoryModalDelete: false,
  InventoryModalUpdate: false,
  InventoryModalCreate: false,
  InventoryAdjustmentModal: false,
  // inventory states
  isInventoryExported: false,
  isInventoryImported: false,

  // inventory adjust quantity
  inventoryAdjustQuantityImg: '',
  inventoryAdjustQuantityPhoto: '',

  // inventory fields
  searchValue: '',
  inventoryName: '',
  inventorySerialNumber: '',
  inventoryIdNumber: '',
  inventoryDescription: '',
  inventoryAssignedTo: [],
  inventoryPrice: '',
  inventoryQuantity: '',
  inventoryMinimumQuantity: '',
  inventoryLocation: '',
  inventoryVendor: '',
  inventoryPurchaseOrder: '',
  inventorySite: '',
  inventoryStock: false,
  inventoryType: '',
  inventoryCategory: '',
  inventoryImage: null,
  inventoryPhoto: null,
  inventoryAttach: null,
  department: '',
  inventoryDetails: '',

  filterSite: null,
  filterSublocation: null,
  filterCategory: null,
  filtertype: null,

  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // success states
  isInventoryGetSuccess: false,
  isInventoriesGetSuccess: false,
  isInventoryCreateSuccess: false,
  isInventoryDeleteSuccess: false,
  isInventoryUpdateSuccess: false,
  isInventoryExportSuccess: false,
  isInventoryImportSuccess: false,

  // loading states
  isInventoryCreateLoading: false,
  isInventoryGetLoading: false,
  isInventoryDeleteLoading: false,
  isInventoryUpdateLoading: false,
  isInventoryExportLoading: false,
  isInventoryImportLoading: false,

  // error states
  isInventoryGetError: false,
  isInventoryCreateError: false,
  isInventoryDeleteError: false,
  isInventoryUpdateError: false,
  isInventoryExportError: false,
  isInventoryImportError: false,
};

export const createInventory = createAsyncThunk('createInventory', async (data, thunkAPI) => {
  const createInventoryRes = await smartTryCatch(inventoryService.createInventory, data, thunkAPI);
  return createInventoryRes;
});

export const getInventorysByAuthentication = createAsyncThunk(
  'getInventorysByAuthentication',
  async (data, thunkAPI) => {
    const getInventorys = await smartTryCatch(
      inventoryService.getInventoryByAuthentication,
      data,
      thunkAPI,
    );
    return getInventorys;
  },
);

export const getInventoryServiceById = createAsyncThunk(
  'getInventoryServiceById',
  async (data, thunkAPI) => {
    const getInventoryService = await smartTryCatch(
      inventoryService.getInventoryServiceById,
      data,
      thunkAPI,
    );

    return getInventoryService;
  },
);

export const getInventoryById = createAsyncThunk('getInventoryById', async (data, thunkAPI) => {
  const getInventory = await smartTryCatch(inventoryService.getInventoryById, data, thunkAPI);
  return getInventory;
});
export const getInventoryHistoryById = createAsyncThunk(
  'getInventoryHistoryById',
  async (data, thunkAPI) => {
    const getInventory = await smartTryCatch(
      inventoryService.getInventoryHistoryById,
      data,
      thunkAPI,
    );
    return getInventory;
  },
);
export const getInventoryAdjustmentHistoryById = createAsyncThunk(
  'getInventoryAdjustmentHistoryById',
  async (data, thunkAPI) => {
    const getInventoryAdjustment = await smartTryCatch(
      inventoryService.getInventoryAdjustmentHistoryById,
      data,
      thunkAPI,
    );
    return getInventoryAdjustment;
  },
);

export const deleteInventory = createAsyncThunk('deleteInventory', async (data, thunkAPI) => {
  const deleteInventoryRes = await smartTryCatch(inventoryService.deleteInventory, data, thunkAPI);
  return deleteInventoryRes;
});

export const updateInventory = createAsyncThunk('updateInventory', async (data, thunkAPI) => {
  const updateInventoryRes = await smartTryCatch(inventoryService.updateInventory, data, thunkAPI);
  return updateInventoryRes;
});

export const updateInventoryQuantity = createAsyncThunk(
  'updateInventoryQuantity',
  async (data, thunkAPI) => {
    const updateInventoryQuantityRes = await smartTryCatch(
      inventoryService.updateInventoryQuantity,
      data,
      thunkAPI,
    );
    return updateInventoryQuantityRes;
  },
);

export const exportInventory = createAsyncThunk('exportInventory', async (data, thunkAPI) => {
  const exportInventoryRes = await smartTryCatch(inventoryService.exportInventory, data, thunkAPI);
  return exportInventoryRes;
});

export const importInventory = createAsyncThunk('importInventory', async (data, thunkAPI) => {
  const importInventoryRes = await smartTryCatch(inventoryService.importInventory, data, thunkAPI);
  return importInventoryRes;
});

export const downloadTemplate = createAsyncThunk(
  'downloadInventoryTemplate',
  async (data, thunkAPI) => {
    const downloadTemplatetRes = await smartTryCatch(
      inventoryService.downloadTemplate,
      data,
      thunkAPI,
    );
    return downloadTemplatetRes;
  },
);
export const getPagination = createAsyncThunk('getPaginationForinv', async (data, thunkAPI) => {
  const getInv = await smartTryCatch(inventoryService.getPagination, data, thunkAPI);
  return getInv;
});

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState: initState,
  reducers: {
    reset: state => {
      state.inventoryAdjustQuantityImg= '';
      state.inventoryAdjustQuantityPhoto = '';
      state.isInventoryCreateError = false;
      state.isInventoryCreateSuccess = false;
      state.isInventoryCreateLoading = false;
      state.isInventoryGetError = false;
      state.isInventoryGetSuccess = false;
      state.isInventoriesGetSuccess = false;
      state.isInventoryGetLoading = false;
      state.isInventoryDeleteError = false;
      state.isInventoryDeleteSuccess = false;
      state.isInventoryDeleteLoading = false;
      state.isInventoryUpdateError = false;
      state.isInventoryUpdateSuccess = false;
      state.isInventoryUpdateLoading = false;
      state.isInventoryHistoryGetLoading = false;
      state.isInventoryHistoryGetSuccess = false;
      state.isInventoryHistoryGetError = false;
      // state.inventory = null;
      state.isInventoryExported = false;
      state.fileExport = [];
      state.isInventoryImported = false;
      state.isInventoryImportLoading = false;
      state.isInventoryCreateError = false;
      state.InventoryAdjustmentModal = false;
      state.template = null;
    },
    openInventoryModalCreate: state => {
      state.InventoryModalCreate = true;
    },
    closeInventoryModalCreate: state => {
      state.InventoryModalCreate = false;
    },
    openInventoryModalUpdate: state => {
      state.InventoryModalUpdate = true;
    },
    closeInventoryModalUpdate: state => {
      state.InventoryModalUpdate = false;
    },
    openInventoryModalDelete: state => {
      state.InventoryModalDelete = true;
    },
    closeInventoryModalDelete: state => {
      state.InventoryModalDelete = false;
    },
    closeInventoryAdjustmentModal: state => {
      state.InventoryAdjustmentModal = false;
    },
    openInventoryAdjustmentModal: state => {
      state.InventoryAdjustmentModal = true;
    },
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
    setInventoryName: (state, action) => {
      state.inventoryName = action.payload;
    },
    setInventorySerialNumber: (state, action) => {
      state.inventorySerialNumber = action.payload;
    },
    setInventoryIdNumber: (state, action) => {
      state.inventoryIdNumber = action.payload;
    },
    setInventoryDescription: (state, action) => {
      state.inventoryDescription = action.payload;
    },
    setInventoryAssignedTo: (state, action) => {
      state.inventoryAssignedTo = action.payload;
    },
    setInventoryPrice: (state, action) => {
      state.inventoryPrice = action.payload;
    },
    setInventoryQuantity: (state, action) => {
      state.inventoryQuantity = action.payload;
    },
    setInventoryMinimumQuantity: (state, action) => {
      state.inventoryMinimumQuantity = action.payload;
    },
    setInventoryLocation: (state, action) => {
      state.inventoryLocation = action.payload;
    },
    setInventoryVendor: (state, action) => {
      state.inventoryVendor = action.payload;
    },
    setInventoryType: (state, action) => {
      state.inventoryType = action.payload;
    },
    setInventoryStock: (state, action) => {
      state.inventoryStock = action.payload;
    },
    setinventoryDetails: (state, action) => {
      state.inventoryDetails = action.payload;
    },
    setInventorySite: (state, action) => {
      state.inventorySite = action.payload;
    },
    setInventoryPurchaseOrder: (state, action) => {
      state.inventoryPurchaseOrder = action.payload;
    },
    setInventoryCategory: (state, action) => {
      state.inventoryCategory = action.payload;
    },
    setInventoryImage: (state, action) => {
      state.inventoryImage = action.payload;
    },
    setInventoryPhoto: (state, action) => {
      state.inventoryPhoto = action.payload;
    },
    setInventoryAttach: (state, action) => {
      state.inventoryAttach = action.payload;
    },
    setImportFile: (state, action) => {
      state.fileImport = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setDepartment: (state, action) => {
      state.department = action.payload;
    },
    setINVFilterCategory: (state, action) => {
      state.filterCategory = action.payload;
    },
    setINVFilterSite: (state, action) => {
      state.filterSite = action.payload;
    },
    setINVFilterSublocation: (state, action) => {
      state.filterSublocation = action.payload;
    },
    setInventoryQuantityImg: (state, action) => {
      state.inventoryAdjustQuantityImg = action.payload;
    },
    setInventoryQuantityPhoto: (state, action) => {
      state.inventoryAdjustQuantityPhoto = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    resetInventory: state => {
      state.inventoryName = '';
      state.inventorySerialNumber = '';
      state.inventoryIdNumber = '';
      state.inventoryDescription = '';
      state.inventoryAssignedTo = null;
      state.inventoryPrice = '';
      state.inventoryQuantity = '';
      state.inventoryMinimumQuantity = '';
      state.inventoryLocation = '';
      state.inventoryVendor = '';
      state.inventoryPurchaseOrder = '';
      state.inventorySite = '';
      state.inventoryStock = '';
      state.inventoryType = '';
      state.inventoryCategory = '';
      state.inventoryPhoto = null;
      state.inventoryAttach = null;
      state.inventoryImage = null;
      state.searchValue = '';
      state.department = '';
      state.inventoryDetails = '';
      state.inventoryAdjustQuantityImg = '';
      state.inventoryAdjustQuantityPhoto = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createInventory.pending, state => {
        state.isInventoryCreateLoading = true;
        state.isInventoryCreateSuccess = false;
        state.isInventoryCreateError = false;
      })
      .addCase(createInventory.fulfilled, (state, action) => {
        state.isInventoryCreateLoading = false;
        state.isInventoryCreateSuccess = true;
        state.isInventoryCreateError = false;
        state.inventorys.results =  [action.payload , ...state.inventorys.results];
      })
      .addCase(createInventory.rejected, state => {
        state.isInventoryCreateLoading = false;
        state.isInventoryCreateSuccess = false;
        state.isInventoryCreateError = true;
      })
      .addCase(getInventorysByAuthentication.pending, state => {
        state.isInventoryGetLoading = true;
        state.isInventoriesGetSuccess = false;
        state.isInventoryGetError = false;
      })
      .addCase(getInventorysByAuthentication.fulfilled, (state, action) => {
        state.isInventoryGetLoading = false;
        state.isInventoriesGetSuccess = true;
        state.isInventoryGetError = false;
        state.inventorys = action.payload;
      })
      .addCase(getInventorysByAuthentication.rejected, state => {
        state.isInventoryGetLoading = false;
        state.isInventoriesGetSuccess = false;
        state.isInventoryGetError = true;
      })
      .addCase(getPagination.pending, state => {
        state.isInventoryGetLoading = true;
        state.isInventoriesGetSuccess = false;
        state.isInventoryGetError = false;
      })
      .addCase(getPagination.fulfilled, (state, action) => {
        state.isInventoryGetLoading = false;
        state.isInventoriesGetSuccess = true;
        state.isInventoryGetError = false;
        state.inventorys = action.payload;
      })
      .addCase(getPagination.rejected, state => {
        state.isInventoryGetLoading = false;
        state.isInventoriesGetSuccess = false;
        state.isInventoryGetError = true;
      })
      .addCase(getInventoryById.pending, state => {
        state.isInventoryGetLoading = true;
        state.isInventoryGetSuccess = false;
        state.isInventoryGetError = false;
      })
      .addCase(getInventoryById.fulfilled, (state, action) => {
        state.isInventoryGetLoading = false;
        state.isInventoryGetSuccess = true;
        state.isInventoryGetError = false;
        state.inventory = action.payload;
      })
      .addCase(getInventoryById.rejected, state => {
        state.isInventoryGetLoading = false;
        state.isInventoryGetSuccess = false;
        state.isInventoryGetError = true;
      })
      .addCase(getInventoryServiceById.fulfilled, (state, action) => {
        state.inventory = action.payload;
      })
      .addCase(getInventoryHistoryById.pending, state => {
        state.isInventoryHistoryGetLoading = true;
        state.isInventoryHistoryGetSuccess = false;
        state.isInventoryHistoryGetError = false;
      })
      .addCase(getInventoryHistoryById.fulfilled, (state, action) => {
        state.isInventoryHistoryGetLoading = false;
        state.isInventoryHistoryGetSuccess = true;
        state.isInventoryHistoryGetError = false;
        state.inventoryHistory = action.payload;
      })
      .addCase(getInventoryHistoryById.rejected, state => {
        state.isInventoryHistoryGetLoading = false;
        state.isInventoryHistoryGetSuccess = false;
        state.isInventoryHistoryGetError = true;
      })
      .addCase(getInventoryAdjustmentHistoryById.pending, state => {
        state.isInventoryAdjustmentHistoryGetLoading = true;
        state.isInventoryAdjustmentHistoryGetSuccess = false;
        state.isInventoryAdjustmentHistoryGetError = false;
      })
      .addCase(getInventoryAdjustmentHistoryById.fulfilled, (state, action) => {
        state.isInventoryAdjustmentHistoryGetLoading = false;
        state.isInventoryAdjustmentHistoryGetSuccess = true;
        state.isInventoryAdjustmentHistoryGetError = false;
        state.inventoryAdjustmentHistory = action.payload;
      })
      .addCase(getInventoryAdjustmentHistoryById.rejected, state => {
        state.isInventoryAdjustmentHistoryGetLoading = false;
        state.isInventoryAdjustmentHistoryGetSuccess = false;
        state.isInventoryAdjustmentHistoryGetError = true;
      })
      .addCase(deleteInventory.pending, state => {
        state.isInventoryDeleteLoading = true;
        state.isInventoryDeleteSuccess = false;
        state.isInventoryDeleteError = false;
      })
      .addCase(deleteInventory.fulfilled, (state, action) => {
        state.isInventoryDeleteLoading = false;
        state.isInventoryDeleteSuccess = true;
        state.isInventoryDeleteError = false;
        state.inventorys.results = state.inventorys?.results.filter(
          inventory => inventory.id !== action.payload,
        );
      })
      .addCase(deleteInventory.rejected, state => {
        state.isInventoryDeleteLoading = false;
        state.isInventoryDeleteSuccess = false;
        state.isInventoryDeleteError = true;
      })
      .addCase(updateInventory.pending, state => {
        state.isInventoryUpdateLoading = true;
        state.isInventoryUpdateSuccess = false;
        state.isInventoryUpdateError = false;
      })
      .addCase(updateInventory.fulfilled, (state, action) => {
        state.isInventoryUpdateLoading = false;
        state.isInventoryUpdateSuccess = true;
        state.isInventoryUpdateError = false;
        state.inventorys.results = state.inventorys?.results.map(inventory =>
          inventory.id === action.payload.id ? action.payload : inventory,
        );
      })
      .addCase(updateInventory.rejected, state => {
        state.isInventoryUpdateLoading = false;
        state.isInventoryUpdateSuccess = false;
        state.isInventoryUpdateError = true;
      })
      .addCase(updateInventoryQuantity.pending, state => {
        state.isInventoryUpdateLoading = true;
        state.isInventoryUpdateSuccess = false;
        state.isInventoryUpdateError = false;
      })
      .addCase(updateInventoryQuantity.fulfilled, (state, action) => {
        state.isInventoryUpdateLoading = false;
        state.isInventoryUpdateSuccess = true;
        state.isInventoryUpdateError = false;
        if (state.inventory.id === action.payload.inventory) {
          state.inventory.total = action.payload.new_quantity;
        }
        state.inventorys.results = state.inventorys?.results.map(inven =>
          inven.id === action.payload.inventory ?state.inventory : inven,
        )
      })
      .addCase(updateInventoryQuantity.rejected, state => {
        state.isInventoryUpdateLoading = false;
        state.isInventoryUpdateSuccess = false;
        state.isInventoryUpdateError = true;
      })
      .addCase(exportInventory.pending, state => {
        state.isInventoryExported = false;
      })
      .addCase(exportInventory.fulfilled, (state, action) => {
        state.isInventoryExported = true;
        state.fileExport = action.payload;
      })
      .addCase(exportInventory.rejected, state => {
        state.isInventoryExported = false;
      })
      .addCase(importInventory.pending, state => {
        state.isInventoryImported = false;
        state.isInventoryImportLoading = true;
        state.isInventoryImportError = false;
      })
      .addCase(importInventory.fulfilled, (state, action) => {
        state.isInventoryImportLoading = false;
        state.isInventoryImported = true;
        state.isInventoryImportError = false;
        state.fileImport = action.payload;
      })
      .addCase(importInventory.rejected, state => {
        state.isInventoryImported = false;
        state.isInventoryImportLoading = false;
        state.isInventoryImportError = true;
      })
      .addCase(downloadTemplate.pending, state => {
        state.isAssetDownloadLoading = true;
        state.isAssetDownloadSuccess = false;
        state.isAssetDownloadError = false;
      })
      .addCase(downloadTemplate.fulfilled, (state, action) => {
        state.isAssetDownloadLoading = false;
        state.isAssetDownloadSuccess = true;
        state.isAssetDownloadError = false;
        state.template = action.payload.split(',');
      })
      .addCase(downloadTemplate.rejected, state => {
        state.isAssetDownloadLoading = false;
        state.isAssetDownloadSuccess = false;
        state.isAssetDownloadError = true;
      });
  },
});

export const {
  reset,
  openInventoryModalCreate,
  closeInventoryModalCreate,
  openInventoryModalUpdate,
  closeInventoryModalUpdate,
  openInventoryModalDelete,
  openInventoryAdjustmentModal,
  closeInventoryAdjustmentModal,
  setInventory,
  setInventoryDetails,
  closeInventoryModalDelete,
  setInventoryName,
  setInventorySerialNumber,
  setInventoryIdNumber,
  setInventoryDescription,
  setinventoryDetails,
  setInventoryAssignedTo,
  setInventoryPrice,
  setInventoryQuantity,
  setInventoryMinimumQuantity,
  setInventoryLocation,
  setInventoryVendor,
  setInventoryPurchaseOrder,
  setInventorySite,
  setInventoryStock,
  setInventoryType,
  setInventoryCategory,
  setInventoryImage,
  setInventoryPhoto,
  setInventoryAttach,
  resetInventory,
  setImportFile,
  setSearchValue,
  setDepartment,
  setINVFilterSublocation,
  setINVFilterSite,
  setINVFilterCategory,
  setInventoryQuantityImg,
  setInventoryQuantityPhoto,
  setCurrentPage,
  setRowPerPage 
} = inventorySlice.actions;

export default inventorySlice.reducer;
