/* eslint-disable no-unneeded-ternary */

import React from "react";
import ProgressChart from "./ProgressChart";

export default function SummaryItemProgress({ color, progress, width }) {  
  return (
    <div
      style={{
        width: width ? "52px" :"56px",
        height: width ? "52px" :"56px",
        marginRight: '6px'
        // border: '1px solid black'
      }}
    >
      <ProgressChart
        label="Hello"
        color={color ? color : "purple"}
        percentage={progress}
      />
    </div>
  );
}
