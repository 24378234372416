import React from 'react'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { getBillWorkOrderType, setBillWorkData, setSummaryBillWorkData } from '../../redux/features/SOR/SORSlice'

const inputStyle = "block w-full px-2 py-2 bg-white border border-gray-300 rounded-md mb-2"
function WorkData({ billSummary }) {
    const { billWorkData, summaryBillWorkData } = useSelector(state => state.SOR)
    const dispatch = useDispatch()

    
    const handleInputChange = (e) => {
        dispatch(setBillWorkData({ ...billWorkData, [e.target.name]: e.target.value }))
        if (e.target.name === "orderType") {
            dispatch(getBillWorkOrderType(e.target.value))
        }
    }

    const handleSummaryInputChange = (e) => {
        dispatch(setSummaryBillWorkData({ ...summaryBillWorkData, [e.target.name]: e.target.value }))
    }
    const handleSummaryDateInputChange = (e) => {
        const { value, name } = e.target;
        const newDate = new Date(value);
        // Validate dates if the changed field is startDate or endDate
        if (name === "startDate" || name === "endDate") {
            const otherDate = name === "startDate"
                ? summaryBillWorkData.endDate
                : summaryBillWorkData.startDate;

            const otherDateObj = otherDate ? new Date(otherDate) : null;

            // Validation for date ranges
            if (name === "startDate" && otherDateObj && newDate > otherDateObj) {
                toast.warning('Start date cannot be after the end date', { toastId: 'StartDateWarning' });
                return;
            }

            if (name === "endDate" && otherDateObj && newDate < otherDateObj) {
                toast.warning('End date cannot be before the start date', { toastId: 'EndDateWarning' });
                return;
            }
            dispatch(setSummaryBillWorkData({ ...summaryBillWorkData, [name]: value }))
        }
    }


    return (
        <section className='work-info row p-3'>
            {billSummary ?
                <>
                    <div className="col-lg-12 col-sm-12">
                        <label style={{ fontSize: "14px" }} className="font-weight-normal">
                            Work title / Tajuk kerja <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="title"
                            type="text"
                            className={inputStyle}
                            value={summaryBillWorkData.title}
                            onChange={handleSummaryInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Technician name / Nama juruteknik 
                        </label>
                        <input
                            required
                            name="technicianName"
                            type="text"
                            className={inputStyle}
                            value={summaryBillWorkData.technicianName}
                            onChange={handleSummaryInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Contractor name / Nama kontraktor 
                        </label>
                        <input
                            required
                            name="contractorName"
                            type="text"
                            className={inputStyle}
                            value={summaryBillWorkData.contractorName}
                            onChange={handleSummaryInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            No vendor 
                        </label>
                        <input
                            required
                            name="noVendor"
                            type="text"
                            className={inputStyle}
                            value={summaryBillWorkData.noVendor}
                            onChange={handleSummaryInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Period 
                        </label>
                        <div className="col-lg-12 col-sm-12 row">
                            <div className="col-lg-6 col-sm-6">
                                <input
                                    name='startDate'
                                    className={inputStyle}
                                    type='date'
                                    value={summaryBillWorkData.startDate}
                                    onChange={handleSummaryDateInputChange}
                                />
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <input
                                    name='endDate'
                                    className={inputStyle}
                                    type='date'
                                    value={summaryBillWorkData.endDate}
                                    onChange={handleSummaryDateInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Job no <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="jobNo"
                            type="text"
                            className={inputStyle}
                            value={summaryBillWorkData.jobNo}
                            onChange={handleSummaryInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Purchase order number / No pesanan belian <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="purchaseOrderNumber"
                            type="text"
                            className={inputStyle}
                            value={summaryBillWorkData.purchaseOrderNumber}
                            onChange={handleSummaryInputChange}
                        />
                    </div>
                </>
                :
                <>
                    <div className="col-lg-12 col-sm-12">
                        <label style={{ fontSize: "14px" }} className="font-weight-normal">
                            Work title / Tajuk kerja <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="title"
                            type="text"
                            className={inputStyle}
                            value={billWorkData.title}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-12 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Work description / Keterangan kerja <label className="font-danger m-0">*</label>
                        </label>
                        <textarea
                            required
                            name="description"
                            type="text"
                            className={inputStyle}
                            value={billWorkData.description}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Outline agreement no <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="agreementNo"
                            type="text"
                            className={inputStyle}
                            value={billWorkData.agreementNo}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Job num / No job <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="jobNum"
                            type="text"
                            className={inputStyle}
                            value={billWorkData.jobNum}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Work order (CMMS) / Perintah kerja <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="order"
                            type="text"
                            className={inputStyle}
                            value={billWorkData.order}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Work order type <label className="font-danger m-0">*</label>
                        </label>
                        <select className={inputStyle}
                            name="orderType"
                            value={billWorkData.orderType}
                            onChange={handleInputChange}
                        >
                            <option value="" selected disabled>--SELECT--</option>
                            <option value='sla'>
                                SLA
                            </option>
                            <option value='non-sla'>
                                NON-SLA
                            </option>
                        </select>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                            Location / Lokasi <label className="font-danger m-0">*</label>
                        </label>
                        <input
                            required
                            name="location"
                            type="text"
                            className={inputStyle}
                            value={billWorkData.location}
                            onChange={handleInputChange}
                        />
                    </div>
                </>}
        </section>
    )
}

export default WorkData