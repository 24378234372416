/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable use-isnan */

import React from "react";
import SummaryItemProgress from "./SummaryItemProgress";
import IconInformation from "./icons/IconInformation";
import Tooltip from "./Tooltip";

export default function SummaryItem({
  n,
  np,
  status,
  statusColor,
  disableChart,
  tooltipText,
  tooltipLabel,
  tooltipLabelWithgraph,
  progress
}) {  
  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        // backgroundColor: "#F8F9F9",
        backgroundColor: "white",
        borderRadius: "12px",
        justifyContent: "space-between",
        boxShadow: '0px 1px 2px 0px #919EAB29',
        border: '1px solid #919EAB29',
        minWidth: '179px'
      }}
    >
      <div style={{ marginLeft: "16px", marginBottom: "20px" }}>
        <div
          style={{
            fontSize: "48px",
            fontWeight: "700",
            color: "#4A4C50",
          }}
        >
          {n}
        </div>
        <div
          style={{
            color: np > 0 ? "#20BB7D" : np < 0 ? "#D9314A" : "#4A4C50",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {np > 0 ? `+${np}%` : np === 0 ? `No Change` : `${np}%`}
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#4A4C50",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              backgroundColor: statusColor,
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "4px",
            }}
          ></span>{" "}
          {status}
        </div>
      </div>
      <div
        style={{
          marginRight: "16px",
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <Tooltip icon={<IconInformation color="#727478" />}>
          <div>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#F8F9F9",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "8px"
            }}>
              {tooltipLabel}
              <div
              style={{
                width: "35%",
                alignSelf: "flex-end",
                textAlign: "center"
              }}
              > <b>{n}</b>  <br /> <span
                style={{
                  color: np > 0 ? "#20BB7D" : np < 0 ? "#D9314A" : "#4A4C50",
                  fontSize: "12px",
                  fontWeight: "400",
                }}>{np > 0 ? `+${np}%` : np === 0 ? `No Change` : `${np}%`}</span></div>
            </div>
            {tooltipLabelWithgraph && <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#F8F9F9",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "8px"
            }}>
              {tooltipLabelWithgraph}
              <div><SummaryItemProgress width color={statusColor} progress={progress} /></div>
            </div>}
            {tooltipText}
          </div>
        </Tooltip>
        {/* <span class="tooltip-item-text">This is a custom tooltip message!</span> */}
        <div style={{ marginTop: "20px" }}>
          {!disableChart && <SummaryItemProgress color={statusColor} progress={progress} />}
        </div>
      </div>
    </div>
  );
}