/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

import React, { useState } from 'react';
import SectionTitle from './SectionTitle';
import IconInformation from './icons/IconInformation';
import Tooltip from "./Tooltip";


export default function NewOverallProgress({ overallData, pmData, woData }) {
  return (
    <div style={{ marginTop: '24px' }}>
      <SectionTitle title="Overall progress" />
      <div
        style={{
          marginTop: '12px',
          // backgroundColor: "#F8F9F9",
          backgroundColor: 'white',
          border: '1px solid #919EAB29',
          borderRadius: '12px',
          padding: '15px 15px 28px',
          boxShadow: '0px 1px 2px 0px #919EAB29',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Tooltip icon={<IconInformation color="#727478" />}>
            <p style={{ fontSize: "12px", color: "#727478", marginBottom: "0px" }}>
              Overall progress for Work Order and Preventive Maintenance. The percentage of progress takes account the period of date selected.
            </p>
          </Tooltip>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            marginTop: '18px',
            marginRight: '4px',
          }}
        >
          <ProgressItem percent={woData ?? 0} title="Work order" color="#D9314AA3" />
          <ProgressItem percent={pmData ?? 0} title="Preventive maintenance" color="#727478A3" />
          <ProgressItem percent={overallData ?? 0} title="Overall" color="#6678D1A3" />
        </div>
      </div>
    </div>
  );
}

function ProgressItem({ color, title, percent }) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '170px', marginLeft: '25px', marginRight: '18px' }}>
        <span
          style={{
            backgroundColor: color,
            width: '9px',
            height: '9px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '8px',
          }}
        ></span>{' '}
        <span style={{ fontWeight: '600', fontSize: '14px', color: '#4A4C50' }}>{title}</span>
      </div>
      <OPBar percentDue={percent ?? 0} fillColor={color} />
    </div>
  );
}

function OPBar({ percentDue, fillColor }) {
  const progressBarStyle = {
    height: '12px',
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '200px',
    overflow: 'hidden',
    marginTop: '4px',
  };

  const progressFillStyle = {
    height: '100%',
    width: `${percentDue}%`,
    backgroundColor: fillColor ?? 'purple',
    transition: 'width 0.3s ease',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
      <div style={progressBarStyle}>
        <div style={progressFillStyle} />
      </div>
      <div
        style={{
          width: '40px',
          marginLeft: '8px',
          marginRight: '12px',
          textAlign: 'end',
          fontWeight: '600',
          fontSize: '14px',
          color: '#4A4C50',
          marginTop: '2px',
          // border: '1px solid black'
        }}
      >
        {percentDue}%
      </div>
    </div>
  );
}
