/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import { format, parseISO } from 'date-fns';
import React from 'react';
import Chart from 'react-apexcharts';
import './TrendWOChart.css';

export default function TrendWOChart({ trendWorkData, intervals }) {
  // Filter out dates that do not have data
  const filteredData = trendWorkData?.workDates.map((date, index) => {
    const hasData = trendWorkData?.open[index] > 0 || trendWorkData?.close[index] > 0;
    return hasData ? { date, open: trendWorkData.open[index], close: trendWorkData.close[index] } : null;
  }).filter(Boolean); // Remove null values

  // Separate the filtered data into individual arrays for charting
  const filteredDates = filteredData.map(item => item.date);
  const filteredOpenData = filteredData.map(item => item.open);
  const filteredCloseData = filteredData.map(item => item.close);

  const series = [
    {
      name: 'Work Order Open',
      data: filteredOpenData,
    },
    {
      name: 'Work Order Close',
      data: filteredCloseData,
    },
  ];

  const options = {
    colors: ['#71A4D0', '#20BB7D'], // Define base colors for each series
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const workOrderOpen = w.config.series[0].data[dataPointIndex];
        const workOrderClosed = w.config.series[1].data[dataPointIndex];
        const workload = workOrderOpen
          ? ((workOrderOpen - workOrderClosed) / workOrderOpen) * 100
          : 0;
        const workloadPercentage = workload.toFixed(1) + '%';

        // Access the corresponding date from the filtered data
        const date = filteredDates[dataPointIndex];
        const formattedDate = format(parseISO(date), 'eeee, MMM d, yyyy'); // Format the date

        return `
          <div class="tc-wrapper">
            <div class="tc-item">
              <div>Date</div>
              <div class="tc-side">${formattedDate}</div>
            </div>
            <div class="tc-item">
              <div>Work order open</div>
              <div class="tc-side">${workOrderOpen}</div>
            </div>
            <div class="tc-item">
              <div>Work order closed</div>
              <div class="tc-side">${workOrderClosed}</div>
            </div>
            <div class="tc-item">
              <div>Workload</div>
              <div class="tc-side">${workloadPercentage}</div>
            </div>
          </div>
        `;
      },
    },
    stroke: {
      width: 2,
      curve: 'smooth', // Use 'smooth' for smooth curves
    },
    fill: {
      type: 'gradient', // Gradient fill for both series
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.8,
        opacityTo: 0.1,
        stops: [0, 100],
      },
    },
    markers: {
      size: 0,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: filteredDates, // Use filtered dates
      tickAmount: 6,
      // title: {
      //   text: intervals,
      // },
      labels: {
        formatter: function (value) {
          const date = new Date(value);
          const month = date.toLocaleString('default', { month: 'short' });
          const year = date.toLocaleString('default', { year: 'numeric' });
          const day = date.getDate();
          const formattedDate = `${day} ${month} ${year}`;
          return formattedDate;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div id="trendChart">
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
}
