import React from 'react'
import "./epq.css"
import { useSelector } from 'react-redux'
import Tooltip from '../../components/tooltip/tooltip'

/* eslint-disable no-unsafe-optional-chaining */

export default function Summary({ pdf }) {
    const { subTotal, penaltyTotalPrice } = useSelector(state => state.SOR)
    return (
        <div style={pdf ? { breakInside: 'avoid' } : {}}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h5 className='mb-0'>Summary</h5>
            </div >
            <table className='bills-table' style={{ width: "100%", padding: "16px" }}>
                <thead>
                    <tr>
                        <td />
                        <td style={{ borderLeft: "1px solid #72747814", borderBottom: "1px solid #72747814" }}>
                            Purchase order/ <br />
                            <span style={{ color: "#727478" }}>Pesanan belian</span>
                        </td>
                        <td />
                        <td style={{ borderLeft: "1px solid #72747814", borderBottom: "1px solid #72747814" }} >
                            Vendor claim/ <br />
                            <span style={{ color: "#727478" }}>Tuntutan kontraktor</span>
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td >
                            Total penalty/ <br />
                            <span style={{ color: "#727478" }}>Total penalti</span>
                        </td>
                        <td style={{ borderLeft: "1px solid #72747814" }}>
                            Sub total
                        </td>
                        <td style={{ borderTop: "1px solid #72747814" }}>
                            <div style={{ display: "flex" }}>
                                {!pdf && <Tooltip  color="black" content={<span>Grand total = Sub total</span>} />}
                                <span className='ml-2'>
                                    Grand total/ <br />
                                </span>
                            </div>
                            <span style={{ color: "#727478" }}>Jumlah keseluruhan</span>
                        </td>
                        <td style={{ borderLeft: "1px solid #72747814" }}>
                            Sub total
                        </td>
                        <td style={{ borderTop: "1px solid #72747814" }}>
                            <div style={{ display: "flex" }}>
                                {!pdf && <Tooltip  color="black" content={<span>Grand total = Sub total - Total penalty</span>} />}
                                <span className='ml-2'>
                                    Grand total/ <br />
                                </span>
                            </div>
                            <span style={{ color: "#727478" }}>Jumlah keseluruhan</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ color: "#D9314A", padding: "20px 10px" }}>
                            -RM{parseFloat(penaltyTotalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                        <td style={{ padding: "20px 10px" }}>
                            RM{parseFloat(subTotal?.subTotalOrder).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                        <td style={{ padding: "20px 10px" }}>
                            RM{parseFloat(subTotal?.subTotalOrder).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                        <td style={{ padding: "20px 10px" }}>
                            RM{parseFloat(subTotal?.subTotalVendor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                        <td style={{ padding: "20px 10px" }}>
                            RM{parseFloat(subTotal?.subTotalVendor - penaltyTotalPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
