/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  setTeam,
  createTeam,
  deleteTeam,
  activateTeam,
  deactivateTeam,
  reset,
  setSearchValue,
  getPagination,
  getDepartmentManagers,
  setCurrentPage,
  setRowPerPage,
} from '../../redux/features/teams/teamSlice';
import Table, {
  AvatarCell,
  SelectColumnFilter,
  StatusPill,
} from '../../components/crudTable/crudTable';
import PopUpActivateModel from '../../components/popUpModals/editTeam/popUpActivateModel';
import profilePicture from '../../assets/img/profile.png';
import SearchHeader from '../../components/searchHeader/searchHeader';
import ManegerPopUp from '../../components/managerform/manaerpopup';
import TechTeamPopUp from '../../components/techTeampop/techTeampop';
import RequestPopup from '../../components/requestorform/requestPopup';
import PopUpDeleteModel from '../../components/popUpModals/editTeam/popUpDeleteModel';
import './teams.scss';
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';

export default function Teams () {
  const {
    profile,
    isLoading: compLoading,
    isError,
    isSuccess,
  } = useSelector(state => state.company);
  const [userNum, setUserNum] = useState(0);

  useEffect(() => {
    setUserNum(profile?.subscription?.allowed_accounts);
  }, [profile]);

  // const { departments } = useSelector((state) => state.department);
  const dispatch = useDispatch();
  const {
    teams,
    team,
    // success
    isTeamCreateSuccess,
    isTeamDeleteSuccess,
    isTeamActivateSuccess,
    isTeamDeactivateSuccess,
    isTeamUpdateSuccess,
    // loading
    isTeamCreateLoading,
    isTeamGetLoading,
    isTeamDeleteLoading,
    isTeamUpdateLoading,
    isTeamActivateLoading,
    isTeamDeactivateLoading,

    // error
    isTeamGetError,
    isTeamCreateError,
    isTeamDeleteError,
    isTeamUpdateError,
    isTeamActivateError,
    isTeamDeactivateError,
    searchValue,
    departmentManagers,
    currentPage, 
    rowPerPage
  } = useSelector(state => state.teams);
  const teamsDataCount = useSelector(state => state.teams?.teams?.count);
  const departments = useSelector(state => state.department?.departments);

  const [isLoading, setIsLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);

  // useEffect(() => {
  //   if (searchValue !== '') {
  //     setCurrentPage(1);
  //   }
  // }, [searchValue]);

  useEffect(() => {
    dispatch(getDepartmentsByAuthentication());
    dispatch(getDepartmentManagers());
  }, []);

  useEffect(() => {
    const getDataRows = async () => {
      setIsLoading(true);
      await dispatch(getPagination({ rowPerPage, currentPage, searchValue }));
      setIsLoading(false);
    };
    getDataRows();
  }, [rowPerPage, currentPage, searchValue]);
  useEffect(() => {
    dispatch(reset());
  }, [
    profile,
    isTeamCreateSuccess,
    isTeamDeleteSuccess,
    isTeamActivateSuccess,
    isTeamDeactivateSuccess,
    isTeamUpdateSuccess,
    // loading
    isTeamCreateLoading,
    isTeamGetLoading,
    isTeamDeleteLoading,
    isTeamUpdateLoading,
    isTeamActivateLoading,
    isTeamDeactivateLoading,

    // error
    isTeamGetError,
    isTeamCreateError,
    isTeamDeleteError,
    isTeamUpdateError,
    isTeamActivateError,
    isTeamDeactivateError,
  ]);
  function displayToast () {
    if (isTeamGetError) {
      toast.error('Something went wrong Getting teams info', {
        toastId: 'FetchingteamError',
      });
    } else if (isTeamCreateError) {
      toast.error('Something went wrong Creating team', {
        toastId: 'TeamCreateError',
      });
    } else if (isTeamDeleteError) {
      toast.error('Something went wrong with deleting the team', {
        toastId: 'teamDeleteError',
      });
    } else if (isTeamUpdateError) {
      toast.error('Something went wrong with updating the team', {
        toastId: 'teamupdateError',
      });
    } else if (isTeamActivateError) {
      toast.error('Something went wrong with activate the team', {
        toastId: 'teamactivateError',
      });
    } else if (isTeamDeactivateError) {
      toast.error('Something went wrong with deactivate the team', {
        toastId: 'teamdeactivateError',
      });
    } else if (isTeamCreateSuccess) {
      toast.success('Team Created Successfully', {
        toastId: 'team Created Success',
      });
    } else if (isTeamDeleteSuccess) {
      toast.success('Team Deleted Successfully', {
        toastId: 'team Deleted Success',
      });
    } else if (isTeamActivateSuccess) {
      toast.success('Team Activated Successfully', {
        toastId: 'team Activated Success',
      });
    } else if (isTeamDeactivateSuccess) {
      toast.success('Team Deactivated Successfully', {
        toastId: 'team Deactivated Success',
      });
    } else if (isTeamUpdateSuccess) {
      toast.success('Team Updated Successfully', {
        toastId: 'team Updated Success',
      });
    }
  }

  const { user } = useSelector(state => state.user);
  const statuses = [true, false];
  const [status, setStatus] = useState(user.status);
  const [teamData, setTeamData] = useState(team || {});
  const [data, setData] = useState(teams.results || []);
  // TODO: Change this to a state variable in the teams slice
  const [showPopupAdd, setShowPopupAdd] = useState(false);
  const [showPopupActivate, setShowPopupActivate] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [lineM, setLineM] = useState('');
  const [department, setDepartment] = useState(null);
  const [lineManagers, setLineManagers] = useState([]);
  const handelDepartmentChange = e => {
    setDepartment(e.target.value);
    const departmentName = departments?.find(item => item?.id === +e.target.value).name;
    setLineManagers(
      departmentManagers?.find(item => item?.department_name === departmentName)?.managers,
    );
  };
  const handelOpenAddPopup = () => {
    setShowPopupAdd(true);
    setDepartment(null);
    setLineManagers([]);
    setLineM('');
    setRole('');
    setRole('');
  };
  const handleCloseAdd = () => {
    setShowPopupAdd(false);
    setEdit(false);
    setDepartment(null);
    setLineManagers([]);
    setLineM('');
    setRole('');
    setRole('');
  };
  const handleCloseEdit = () => {
    setShowPopupEdit(false);
    setEdit(false);
  };
  const handleCloseActivate = () => {
    setShowPopupActivate(false);
    setEdit(false);
  };
  const handleCloseDelte = () => {
    setShowPopupDelete(false);
    setEdit(false);
  };

  useEffect(() => {
    setData(teams?.results);
    // setShowPopupActivate(false)
    setShowPopupAdd(false);
    // setShowPopupDelete(false)
    setShowPopupEdit(false);
  }, [teams]);
  // useEffect(() => {
  //   setData(newdata);
  // }, [newdata]);
  const handleDeleteTeam = () => {
    dispatch(deleteTeam(team?.id));
    setShowPopupDelete(false);
  };
  // const handleUpdateTeam=()=>{

  // }
  const handleAcetivation = () => {
    if (team.is_active === 'True') {
      dispatch(deactivateTeam(team.id));
    } else {
      dispatch(activateTeam(team.id));
    }
    setShowPopupActivate(false);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'first_name',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} Names`,
        Cell: ({ row }) =>
          row.original ? `${row.original.first_name}  ${row.original.last_name}` : row.groupByVal,
      },

      {
        Header: 'Email',
        emailAccessor: 'email',
        Cell: AvatarCell,
        imgAccessor: 'imgUrl',
      },
      {
        Header: 'Phone Number',

        Cell: ({ row }) =>
          row.original.profile.phone_number !== null ? row.original.profile.phone_number : '—',
      },
      {
        Header: 'Position',
        Cell: ({ row }) =>
          row?.original?.role === 'Manager'
            ? row.original.manager?.position !== null
              ? row.original.manager?.position?.name
              : '—'
            : row?.original?.role === 'Technical Team'
            ? row.original.technicalteam?.position !== null
              ? row.original.technicalteam?.position?.name
              : '—'
            : row.original?.profile?.job_title,
      },
      {
        Header: 'Line Manager',
        Cell: ({ row }) =>
          row?.original?.role === 'Technical Team' && row.original?.technicalteam?.tech_manager
            ? row.original?.technicalteam?.tech_manager?.name
            : '—',
      },
      {
        Header: 'Department',
        Cell: ({ row }) =>
          row.original.profile.department !== null ? row.original.profile.department?.name : '—',
      },
      {
        Header: 'Account Type',
        Cell: ({ row }) => (row.original?.role === 'CliAdm' ? 'Admin' : row.original?.role),
        Filter: SelectColumnFilter, // new
        filter: 'includes',
      },
      {
        Header: 'Active',
        accessor: `is_active`,
        Cell: StatusPill,
      },
    ],
    [],
  );
  const AddTeamMember = e => {
    e.preventDefault();
    if (role !== 'Technical Team') {
      const userInfo = {
        email: email,
        role: role,
        department: department,
        technicalteam: {
          tech_manager: parseInt(lineM, 10) || 0,
        },
      };
      dispatch(createTeam(userInfo));
    } else {
      const userInfo = {
        email: email,
        role: role,
        department: department || null,
        technicalteam: {
          tech_manager: parseInt(lineM, 10) || 0,
        },
      };
      dispatch(createTeam(userInfo));
    }
    // handleCloseAdd();
  };
  return isTeamCreateLoading ||
    isTeamDeleteLoading ||
    isTeamUpdateLoading ||
    isTeamActivateLoading ||
    isTeamDeactivateLoading ? (
    <Loader />
  ) : (
    <>
      {displayToast()}
      <div className="assets">
        <PopUpActivateModel
          // modalTitle="Are you sure you want to activate this Technician account?"
          showModal={showPopupActivate}
          handleClose={handleCloseActivate}
          data={team}
          handleSave={handleAcetivation}
          modelType={team.is_active}
        />
        <PopUpDeleteModel
          showModal={showPopupDelete}
          handleClose={handleCloseDelte}
          data={team}
          handleSave={handleDeleteTeam}
        />
        <SearchHeader
          pageName="Teams"
          includeFilterButton={false}
          includeEllipsisButton={false}
          includeAddButton
          addButtonTitle="Add Team"
          pagePath="/teams"
        >
          <div className="row searc-head">
            <div className="row info-users">
              <p className="my-auto">
                {teams.count} / {userNum}
              </p>
              <FontAwesomeIcon icon={faUsers} />
            </div>

            <input
              placeholder="Search"
              value={searchValue}
              className="rgfloat search-bar float-right "
              onChange={e => dispatch(setSearchValue(e.target.value))}
            />

            <button
              type="button"
              title="Add team"
              className="btn primary-btn ml-2 "
              onClick={handelOpenAddPopup}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </SearchHeader>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            data={data || []}
            isLoading={isLoading}
            onRowClick={rowData => {
              dispatch(setTeam(rowData));
              setShowPopupEdit(true);
              setEdit(true);
            }}
          >
            <Pagination
              totalRows={teamsDataCount}
              rowPerPage={rowPerPage}
              pageChangeHandler={setCurrentPage}
              changeRowsPerPage={setRowPerPage}
              currentPage={currentPage}
            />
            {/* // Add your custom components here, it will be displayed inside of the popup window */}
            {user?.role === 'CliAdm' && showPopupAdd && (
              <Drawer
                anchor="right"
                open={showPopupAdd}
                onClose={handleCloseAdd}
                PaperProps={{ style: { width: '25%' } }}
              >
                <div
                  className="modal-dialog addnew"
                  id="modal-dialog"
                  style={{ maxWidth: '600px !important' }}
                >
                  <div className="modal-content" id="modal-content">
                    <div className="modal-body p-0" id="modal-body">
                      <form
                        action="{{action}}"
                        className="h-1000"
                        style={{ width: '600px' }}
                        method="POST"
                        onSubmit="return check_validation()"
                      >
                        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header">
                          <div className="col-12 row px-2">
                            <div className="my-auto">
                              <b>Invite User</b>
                            </div>

                            <div className="row ml-auto">
                              <button
                                type="button"
                                className="btn tertiary-btn ml-2"
                                data-dismiss="modal"
                                onClick={handleCloseAdd}
                              >
                                <div className="fas fa-times" />
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 row"> </div>

                          <div className="row justify-content-start mt-2 col-lg-12 col-sm-12 ">
                            <ul
                              className="nav nav-pills justify-content-center"
                              id="custom-tabs-two-tab"
                              role="tablist"
                            >
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  id="custom-tabs-two-user-tab"
                                  data-toggle="tab"
                                  href="#user-tab"
                                  role="tab"
                                  aria-controls="custom-tabs-two-user"
                                  aria-selected="false"
                                >
                                  User Details
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          className="card-body mx-auto row col-lg-12"
                          style={{ paddingBottom: '0rem !important' }}
                        >
                          <div className="col col-lg-12 col-sm-12 mr-2">
                            <div className="row mb-0">
                              <div className=" col-lg-12 col-sm-12">
                                <div className="row mb-0">
                                  <div className=" col-lg-6 col-sm-12">
                                    {' '}
                                    <span className="font-weight-normal">
                                      Email <span className="font-danger m-0">*</span>
                                    </span>
                                    <input
                                      name="username"
                                      type="email"
                                      className="form-control mb-2"
                                      required
                                      placeholder="Email"
                                      id="username"
                                      onChange={e => setEmail(e.target.value)}
                                    />
                                  </div>
                                  <div className=" col-lg-6 col-sm-12">
                                    <span className="font-weight-normal">
                                      Account Type <span className="font-danger m-0">*</span>
                                    </span>

                                    <select
                                      required
                                      className=" mb-2"
                                      name="role"
                                      id="role"
                                      onChange={e => setRole(e.target.value)}
                                    >
                                      <option selected>--SELECT--</option>

                                      {teams?.count < userNum ? (
                                        <>
                                          <option value="Administrator">Administrator</option>
                                          <option value="Manager">Manager</option>
                                          <option value="Technical Team">Technical Team</option>
                                          <option value="Requester">Requester</option>
                                        </>
                                      ) : (
                                        <option value="Requester">Requester</option>
                                      )}
                                    </select>

                                    <div className="col-lg-12 col-sm-12 m-0">
                                      <span className="font-danger" id="role_error" />
                                    </div>
                                  </div>
                                  {(role === 'Technical Team' || role === 'Manager') && (
                                    <div className=" col-lg-12 col-sm-12">
                                      <span className="font-weight-normal">
                                        Department <span className="font-danger m-0">*</span>
                                      </span>
                                      <select
                                        required
                                        className="mb-2"
                                        name="role"
                                        id="role"
                                        onChange={handelDepartmentChange}
                                      >
                                        <option selected>--SELECT--</option>
                                        {departments?.map(e => {
                                          return (
                                            <option key={e.id} value={e.id}>
                                              {e.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      <div className="col-lg-12 col-sm-12 m-0">
                                        <span className="font-danger" id="role_error" />
                                      </div>
                                    </div>
                                  )}
                                  {role === 'Technical Team' && (
                                    <div className=" col-lg-12 col-sm-12">
                                      <span className="font-weight-normal">
                                        Line Manager <span className="font-danger m-0">*</span>
                                      </span>
                                      <select
                                        required
                                        className="mb-2"
                                        name="role"
                                        id="role"
                                        onChange={e => setLineM(e.target.value)}
                                      >
                                        <option value="" selected>
                                          --SELECT--
                                        </option>
                                        {lineManagers?.length > 0
                                          ? lineManagers?.map(e => {
                                              return (
                                                <option key={e.id} value={e.id}>
                                                  {e.first_name} {e.last_name}
                                                </option>
                                              );
                                            })
                                          : ''}
                                      </select>
                                      <div className="col-lg-12 col-sm-12 m-0">
                                        <span className="font-danger" id="role_error" />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div
                        className=" row col-lg-12 m-0 p-2 page-profile-footer"
                        style={{ position: 'absolute' }}
                      >
                        <button className="btn primary-btn" type="submit" onClick={AddTeamMember}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            )}
            {(user?.role === 'CliAdm' || user?.role === 'Manager') && showPopupEdit && (
              <Drawer anchor="right" open={showPopupEdit} onClose={handleCloseEdit}>
                <div className="modal-dialog modal-lg" id="modal-dialog">
                  <div className="modal-content" id="modal-content">
                    <div className="modal-body p-0" id="modal-body">
                      <form
                        action="{{action}}"
                        className="h-1000"
                        style={{ width: '600px' }}
                        method="POST"
                        onSubmit="return check_validation()"
                      >
                        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header">
                          <div className="col-12 row px-2">
                            <div className="row ml-auto">
                              {team.role !== 'CliAdm' && (
                                <>
                                  {team.is_active === 'False' ? (
                                    <button
                                      onClick={() => {
                                        setShowPopupActivate(true);
                                        setShowPopupEdit(false);
                                      }}
                                      className="btn activate-btn"
                                      data-toggle="modal"
                                      data-target="#modal-status"
                                      title="Activate"
                                      type="button"
                                    >
                                      Activate
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setShowPopupActivate(true);
                                        setShowPopupEdit(false);
                                      }}
                                      className="btn btn deactivate-btn"
                                      data-toggle="modal"
                                      data-target="#modal-status"
                                      title="Activate"
                                      type="button"
                                    >
                                      Deactivate
                                    </button>
                                  )}
                                  <button
                                    // href="#"
                                    type="button"
                                    onClick={() => {
                                      setShowPopupDelete(true);
                                      setShowPopupEdit(false);
                                    }}
                                    title="Delete Team Member"
                                    className="btn secondary-btn ml-2"
                                    data-toggle="modal"
                                    data-target="#modal-delete"
                                  >
                                    <i className="fa fa-trash" aria-hidden="true">
                                      {' '}
                                    </i>
                                  </button>
                                </>
                              )}
                              <button
                                type="button"
                                className="btn tertiary-btn ml-2"
                                data-dismiss="modal"
                                onClick={handleCloseEdit}
                              >
                                <div className="fas fa-times" />
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 row"> </div>

                          <div className="col-lg-12 col-sm-12 row">
                            {isEdit ? (
                              <>
                                <div className="col-12 text-center flex justify-content-center">
                                  {user.profile.image ? (
                                    <img
                                      className="prof_img img-circle"
                                      height="100"
                                      alt="profile"
                                      width="100"
                                      src={team.profile.image}
                                      style={{width: "100px", height: "100px", aspectRatio: "auto 25 / 25"}}
                                    />
                                  ) : (
                                    <img
                                      className="prof_img img-circle"
                                      height="100"
                                      width="100"
                                      alt="profile"
                                      src={profilePicture}
                                      style={{width: "100px", height: "100px", aspectRatio: "auto 25 / 25"}}
                                    />
                                  )}
                                </div>
                                <div className="col-12 text-center">
                                  <div className="large">
                                    {team.first_name || team.last_name ? (
                                      <span>
                                        {team.first_name} {team.last_name}
                                      </span>
                                    ) : (team.role === 'CliAdm' && team.first_name) ||
                                      team.last_name ? (
                                      <span>
                                        {team.first_name} {team.last_name}
                                      </span>
                                    ) : null}
                                  </div>
                                  <div className="col-12 text-center">
                                    <div className="medium-title">
                                      <span>{team.role === 'CliAdm' ? 'Admin' : team.role}</span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className="row justify-content-start mt-2 col-lg-12 col-sm-12 ">
                              <ul
                                className="nav nav-pills justify-content-center"
                                id="custom-tabs-two-tab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    id="custom-tabs-two-user-tab"
                                    data-toggle="tab"
                                    href="#user-tab"
                                    role="tab"
                                    aria-controls="custom-tabs-two-user"
                                    aria-selected="false"
                                  >
                                    User Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {team.role === 'CliAdm' ? (
                          <>
                            <div
                              className="card-body mx-auto row col-lg-12 pb-0"
                              style={{ paddingBottom: '0rem !important' }}
                            >
                              <div className="col col-lg-12 col-sm-12 mr-2">
                                <div className="row mb-0">
                                  <div className=" col-lg-12 col-sm-12">
                                    <div className="row mb-0">
                                      <div className="row mb-0">
                                        <div className=" col-lg-6 col-sm-12">
                                          {' '}
                                          <span className="font-weight-normal">
                                            Email <span className="font-danger m-0">*</span>
                                          </span>
                                          <input
                                            name="email"
                                            type="email"
                                            className="form-control mb-2"
                                            required
                                            placeholder="Email"
                                            id="username"
                                            onChange={e =>
                                              setTeamData({ ...teamData, email: e.target.value })
                                            }
                                            value={team.email}
                                            disabled
                                          />
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          <span className="font-weight-normal">
                                            Account Type <span className="font-danger m-0">*</span>
                                          </span>

                                          <select
                                            required
                                            className="mb-2"
                                            name="role"
                                            id="role"
                                            onChange={e =>
                                              setTeamData({ ...teamData, role: e.target.value })
                                            }
                                            disabled
                                          >
                                            <option selected>
                                              {team?.role === 'CliAdm'
                                                ? 'Administrator'
                                                : team?.role}
                                            </option>
                                          </select>

                                          <div className="col-lg-12 col-sm-12 m-0">
                                            <span className="font-danger" id="role_error" />
                                          </div>
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          {' '}
                                          <span className="font-weight-normal">
                                            First Name <span className="font-danger m-0">*</span>
                                          </span>
                                          <input
                                            name="firstname"
                                            type="text"
                                            className="form-control mb-2"
                                            required
                                            placeholder="firstname"
                                            id="username"
                                            onChange={e =>
                                              setTeamData({
                                                ...teamData,
                                                first_name: e.target.value,
                                              })
                                            }
                                            disabled
                                            value={team.first_name}
                                          />
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          {' '}
                                          <span className="font-weight-normal">
                                            Last Name <span className="font-danger m-0">*</span>
                                          </span>
                                          <input
                                            name="lastname"
                                            type="text"
                                            className="form-control mb-2"
                                            required
                                            placeholder="last Name"
                                            id="username"
                                            onChange={e =>
                                              setTeamData({
                                                ...teamData,
                                                last_name: e.target.value,
                                              })
                                            }
                                            value={team.last_name}
                                            disabled={user.type !== 'CliAdm'}
                                          />
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          <span className="font-weight-normal">
                                            Department <span className="font-danger m-0">*</span>
                                          </span>
                                          <select
                                            required
                                            className="mb-2"
                                            name="role"
                                            id="role"
                                            onChange={e =>
                                              setTeamData({
                                                ...teamData,
                                                last_name: e.target.value,
                                              })
                                            }
                                            disabled
                                          >
                                            <option selected>
                                              {team.profile.department?.name === undefined
                                                ? '---SELECT---'
                                                : team.profile.department?.name}
                                            </option>
                                          </select>

                                          <div className="col-lg-12 col-sm-12 m-0">
                                            <span className="font-danger" id="role_error" />
                                          </div>
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          <span className="font-weight-normal">
                                            Position <span className="font-danger m-0">*</span>
                                          </span>

                                          <select
                                            required
                                            className="mb-2"
                                            name="role"
                                            id="role"
                                            onChange="remove_error_msg('role'); show_line_manager(this)"
                                            disabled
                                          >
                                            <option selected>None</option>
                                          </select>

                                          <div className="col-lg-12 col-sm-12 m-0">
                                            <span className="font-danger" id="role_error" />
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                          <label
                                            htmlFor="department"
                                            className="font-weight-normal"
                                          >
                                            Status
                                          </label>
                                          <select
                                            className="mb-2"
                                            name="status"
                                            id="status"
                                            disabled
                                            onChange={e => setStatus(e.target.value)}
                                          >
                                            <option value="" disabled defaultValue>
                                              --SELECT--
                                            </option>

                                            {statuses?.map(stat => (
                                              <option
                                                key={stat}
                                                value={stat}
                                                selected={profile?.status === stat}
                                              >
                                                {stat ? 'Available' : 'Unavailable'}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" row  m-0 p-2 page-profile-footer">
                              <button className="btn primary-btn" type="submit" onClick="" disabled>
                                Save
                              </button>
                            </div>
                          </>
                        ) : team.role === 'Manager' ? (
                          <ManegerPopUp team={team} />
                        ) : team.role === 'Technical Team' ? (
                          <TechTeamPopUp departmentManagers={departmentManagers} team={team} />
                        ) : team.role === 'Requester' ? (
                          <RequestPopup team={team} />
                        ) : null}
                      </form>
                    </div>
                  </div>
                </div>
              </Drawer>
            )}
          </Table>
        )}
      </div>
    </>
  );
}
