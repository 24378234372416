/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-empty-pattern */

import React from "react";
import IconInformation from "./icons/IconInformation";
import WOvsPMChart from "./WOvsPMChart";
import Tooltip from "./Tooltip";

export default function WOvsPM({ wovspmData }) {
  return (
    <div
      style={{
        flex: "1",
        // backgroundColor: "#F8F9F9",
        backgroundColor: "white",
        border: '1px solid #919EAB29',
        borderRadius: "12px",
        padding: "16px",
        minWidth: '222px',
        // minWidth: '222px',
        boxShadow: '0px 1px 2px 0px #919EAB29'
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#36383C",
            }}
          >
            WO vs PM
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#757980",
              marginTop: "8px",
            }}
          >
            Data of WO vs PM
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <Tooltip icon={<IconInformation color="#727478" />}>
            <h6 style={{fontSize: "12px", color: "#4D4D4D"}}>
              Percentage of Total Work Order vs Total Preventive Maintenance.
            </h6>
            <p style={{ fontSize: "12px", color: "#727478", marginBottom: "0px" }}>
              Preventive maintenance effectiveness is shown by its implementation, with higher percentages indicating better strategies.
            </p>
          </Tooltip>
        </div>
      </div>
      <div style={{
        width: "100%",
        height: "100px",
        marginTop: '45px'
      }}>
        <WOvsPMChart wovspmData={wovspmData} />
      </div>
    </div>
  );
}
