/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { subDays } from 'date-fns';
import { smartTryCatch } from '../../../utils';
import AnalyticsService from './analyticService';

const today = new Date();

const initialState = {
  analytics: {},
  // analytics status
  isAnalyticsLoading: false,
  isAnalyticsSuccess: false,
  isAnalyticsError: false,

  // to store the chart image link
  pieChartImg: '',
  progressChartImg: '',
  semiCircleChartImg: '',
  trendWorkChartImg: '',

  // filter data
  dateWord: null,
  custom_start: null,
  customDate: null,
  custom_end: null,
  filter: null,
  filterStartDate: subDays(today, 7), // 7 days ago
  filterEndDate: today,
  dateFilterState: [
    {
      startDate: subDays(today, 7),
      endDate: today,
      key: 'selection',
    },
  ],
  site: null,
  location: null,
  categoryFilter: null,
  typeFilter: null,
  staff: null,
};

export const getAnalyticsForDashboard = createAsyncThunk(
  'getAnalyticsForDashboard',
  async (data, thunkAPI) => {
    const analyticsResult = await smartTryCatch(
      AnalyticsService.getAnalyticsForDashboard,
      data,
      thunkAPI,
    );
    return analyticsResult;
  },
);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialState,
  reducers: {
    reset: state => {
      state.analytics = {};
      state.isAnalyticsError = false;
      state.isAnalyticsLoading = false;
      state.isAnalyticsSuccess = false;
      state.pieChartImg = '';
      state.progressChartImg = '';
      state.semiCircleChartImg = '';
      state.trendWorkChartImg = '';
      state.dateWord = null;
      state.customDate = null;
      state.custom_start = null;
      state.custom_end = null;
      state.filter = null;
      state.site = null;
      state.location = null;
      state.categoryFilter = null;
      state.typeFilter = null;
      state.staff = null;
    },
    setPieChartImage: (state, action) => {
      state.pieChartImg = action.payload;
    },
    setProgressChartImage: (state, action) => {
      state.progressChartImg = action.payload;
    },
    setSemiCircleChartImage: (state, action) => {
      state.semiCircleChartImg = action.payload;
    },
    setTrendWorkChartImage: (state, action) => {
      state.trendWorkChartImg = action.payload;
    },
    setdateWord: (state, action) => {
      state.dateWord = action.payload;
    },
    setCustomDate: (state, action) => {
      state.customDate = action.payload;
    },
    setCustomStart: (state, action) => {
      state.custom_start = action.payload;
    },
    setCustomEnd: (state, action) => {
      state.custom_end = action.payload;
    },
    setDate: (state, action) => {
      state.filter = action.payload;
    },
    setFilterStartDate: (state, action) => {
      state.filterStartDate = action.payload;
    },
    setFilterEndDate: (state, action) => {
      state.filterEndDate = action.payload;
    },
    setDateFilterState: (state, action) => {
      state.dateFilterState = action.payload;
    },
    setSite: (state, action) => {
      state.site = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setType: (state, action) => {
      state.typeFilter = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.categoryFilter = action.payload;
    },
    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    resetAnalytic: state => {
      state.pieChartImg = '';
      state.progressChartImg = '';
      state.semiCircleChartImg = '';
      state.trendWorkChartImg = '';
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAnalyticsForDashboard.pending, state => {
        state.isAnalyticsError = false;
        state.isAnalyticsLoading = true;
        state.isAnalyticsSuccess = false;
      })
      .addCase(getAnalyticsForDashboard.fulfilled, (state, action) => {
        state.isAnalyticsError = false;
        state.isAnalyticsLoading = false;
        state.isAnalyticsSuccess = true;
        state.analytics = action.payload;
      })
      .addCase(getAnalyticsForDashboard.rejected, state => {
        state.isAnalyticsError = true;
        state.isAnalyticsLoading = false;
        state.isAnalyticsSuccess = false;
      });
  },
});

export const {
  analytics,
  setPieChartImage,
  setProgressChartImage,
  setSemiCircleChartImage,
  setTrendWorkChartImage,
  setDate,
  setFilterStartDate,
  setFilterEndDate,
  setDateFilterState,
  setSite,
  setLocation,
  setCategoryFilter,
  setType,
  setStaff,
  resetAnalytic,
  setCustomStart,
  setCustomEnd,
  setdateWord,
  setCustomDate
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
