/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prefer-template */
/* eslint-disable dot-notation */

import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@material-ui/core';
import ReloadIcon from '../../assets/svg-icons/ReloadIcon';
import classes from '../../components/assetsTable/assetsTable.module.css';
import IconVerticalBox from '../../assets/svg-icons/IconVerticalBox';
import { getBills, getBillsSummary, setBOSDateFilterState, setBOSFilterEndDate, setBOSFilterStartDate, setBOSSearchValue, setBOSStatus } from '../../redux/features/SOR/SORSlice';
import IconSearch from '../../assets/svg-icons/IconSearch';
import DateRangeFilter from '../viewMaintenance/Componets/DateRangeFilter';
import styles from '../assetsPage/assestsPage.module.css';


function BOSFilter({
    setISShowFilter,
    iSShowFilter,
    allColumns,
    getDataRows,
    setIsLoading,
}) {
    const {
        BOSDateFilterState,
        BOSFilterStartDate,
        BOSFilterEndDate,
        BOSStatus,
        BOSRowPerPage,
        BOSCurrentPage,
        BOSSearchValue,
    } = useSelector(state => state.SOR);

    const dispatch = useDispatch()

    const handleEraserFilterClick = () => {
        // reset the state and store
        dispatch(setBOSStatus(""))
        dispatch(setBOSFilterStartDate(""))
        dispatch(setBOSFilterEndDate(""))
        dispatch(setBOSDateFilterState([
            {
                startDate: "",
                endDate: "",
                key: 'selection',
            },
        ]))
        const getData = async () => {
            setIsLoading(true)
            const filters =
            {
                rowPerPage: BOSRowPerPage,
                currentPage: BOSCurrentPage,
                searchValue: BOSSearchValue,
                startDate: "",
                endDate: "",
                status: '',
            }
            await dispatch(getBillsSummary(filters))
            setIsLoading(false)
        }
        getData()
    };

    const handelFetchFilters = () => {
        getDataRows()
    }
    const handelChange = e => {
        dispatch(setBOSSearchValue(e.target.value));
    };

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleUpdate = async () => {
        setShow(false);
        dispatch(setBOSFilterStartDate(BOSDateFilterState?.find(item => item.startDate)?.startDate))
        dispatch(setBOSFilterEndDate(BOSDateFilterState?.find(item => item.endDate)?.endDate))
    };

    return (
        <div className="wo-filters-wrapper" style={{ marginBottom: '8px', marginTop: "10px" }}>
            <div style={{ display: 'flex', gap: '16px' }}>
                <div className="filter-wrapper" style={{ width: "180px", alignSelf: "flex-end" }}>
                    <div className={styles.assetActions} style={{
                        display: 'flex',
                        backgroundColor: 'white',
                        border: '1px solid #E1E1E1',
                        borderRadius: '8px',
                        alignItems: 'center',
                        paddingLeft: '16px'
                        /* width: 250px; */
                    }}>
                        <IconSearch />
                        <input type="text" placeholder='Search' value={BOSSearchValue} onChange={handelChange}
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                        />
                    </div>
                </div>
                <div className="filter-wrapper" style={{ width: "180px", alignSelf: "flex-end" }}>
                    <div className='filters-left-wrapper-period' >
                        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Period </label>
                        <div className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                            style={{ fontSize: "14px", cursor: "pointer", width: "180px" }}
                            onClick={() => setShow(!show)}>
                            {BOSFilterStartDate ? <><span>{moment(BOSFilterStartDate).format("DD MMM YYYY")}</span> - <span>{moment(BOSFilterEndDate).format("DD MMM YYYY")}</span> </> : "All time"}
                        </div>
                        {
                            show && <>
                                <div className="tck-overlay" onClick={() => setShow(!show)} />
                                <DateRangeFilter
                                    show={show}
                                    handleUpdate={handleUpdate}
                                    handleClose={handleClose}
                                    state={BOSDateFilterState}
                                    setState={setBOSDateFilterState}
                                />
                            </>
                        }
                    </div>
                </div>
                <div className="filter-wrapper" style={{ width: '160px' }}>
                    <label style={{ color: '#36383c', fontWeight: 500 }}>Status</label>
                    <select
                        onChange={(e) => dispatch(setBOSStatus(e.target.value))}
                        value={BOSStatus}
                        className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '>
                        <option value="">All status</option>
                        <option value="draft">Draft</option>
                        <option value="published">Published</option>
                    </select>
                </div>
                <button style={{ alignSelf: "flex-end", color: 'black', padding: ".375rem .7rem", display: 'flex', alignItems: "center" }} className='new-btn btn' onClick={handleEraserFilterClick} title="Reset">
                    <ReloadIcon /> <span className='ml-2'>Reset</span>
                </button>
                <button style={{ alignSelf: "flex-end", padding: ".375rem .7rem", color: 'black', marginLeft: "-10px" }} className='new-btn btn' onClick={handelFetchFilters} title="Reload">
                    {/* <ReloadIcon /> */}
                    <FontAwesomeIcon icon={faRotate} style={{ color: 'black' }} /> <span className='ml-2'>Reload</span>
                </button>
            </div>

            <div style={{ display: 'flex', gap: '12px' }}>
                {/* <Button variant="contained" style={{
                        backgroundColor: '#36383C',
                        color: '#F8F9F9',
                        fontSize: '14px',
                        fontWeight: '600',
                        padding: '8px 16px',
                        borderRadius: '8px'
                    }}
                        // onClick={handleOpenAdvFilter}
                        className='adv-button'
                    >
                        Advanced filter
                    </Button> */}
                <div style={{ position: "relative" }}>
                    <IconButton
                        style={{
                            backgroundColor: '#F8F9F9',
                            borderRadius: '8px',
                            border: '1px solid #E1E1E1',
                        }}
                        variant="contained"
                        title="Column selection"
                        className='outlined-button mr-2'
                        onClick={() => setISShowFilter(!iSShowFilter)}
                    ><IconVerticalBox />
                    </IconButton>
                    {iSShowFilter && (
                        <>
                            <div className="tck-overlay" onClick={() => setISShowFilter(!iSShowFilter)} />
                            <div className={classes.newFilterTable}>
                                <div className={classes.newFilterTableCheckboxs}>
                                    <p className='weekly-frequency-label ml-0'>Filter column</p>
                                    {/* Loop through columns data to create checkbox */}
                                    {allColumns.map(column => {
                                        if (column.id !== 'delete-table-column') {
                                            return (
                                                <div key={column.id} className={classes.newFilterTableCheckboxs}>
                                                    <label className="weekly-frequency-switch black-switch">
                                                        <input type="checkbox"  {...column.getToggleHiddenProps()} />
                                                        <span className="weekly-frequency-slider" />
                                                    </label>
                                                    <span className="weekly-frequency-label">{column.Header}</span>
                                                </div>
                                            );
                                        }
                                    })}
                                    <br />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BOSFilter