import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getWorkOrderServiceRequestByAuthentication  = async () => {
  const res = await http.get(
    `${BASE_URL}/failure-code/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of workOrderServiceRequest by its company ID failed",
    res
  );
};

const getWorkOrderServiceRequestById = async (workOrderServiceRequestId) => {
  const res = await http.get(
    `${BASE_URL}/failure-code/${workOrderServiceRequestId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of workOrderServiceRequest by its ID failed",
    res
  );
};

const createWorkOrderServiceRequest = async (data) => {
  const res = await http.post(
    `${BASE_URL}/failure-code/`,
    data
  );

  return assert(res, res.data, "Creation of workOrderServiceRequest failed", res);
};

const updateWorkOrderServiceRequest = async (data) => {
  const res = await http.put(
    `${BASE_URL}/failure-code/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of workOrderServiceRequest failed", res);
};

const deleteWorkOrderServiceRequest = async (workOrderServiceRequestId) => {
  const res = await http.delete(
    `${BASE_URL}/failure-code/${workOrderServiceRequestId}/`
  );
  return assert(res, workOrderServiceRequestId, "Deletion of workOrderServiceRequest failed", res);
};

const workOrderServiceRequestService = {
  getWorkOrderServiceRequestByAuthentication,
  getWorkOrderServiceRequestById,
  createWorkOrderServiceRequest,
  updateWorkOrderServiceRequest,
  deleteWorkOrderServiceRequest,
};

export default workOrderServiceRequestService;
