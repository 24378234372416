import React from 'react';
import SectionTitle from './SectionTitle';
import SummaryItem from './SummaryItem';

/* eslint-disable no-nested-ternary */

export default function PMSummary({
  totalWO,
  percentTotalWO,
  completedWO,
  percentCompletedWO,
  openWO,
  percentOpenWO,
  onHoldWO,
  percentOnHoldWO,
  onProgressWO,
  percentOnProgressWO,
}) {  
  return (
    <div style={{ marginTop: '24px' }}>
      <SectionTitle title="Preventive maintenance summary" />
      <div className='analytic-cards-container'>
        <SummaryItem
          n={totalWO}
          np={percentTotalWO}
          disableChart
          status="Total PM"
          statusColor="#727478A3"
          tooltipLabel={<div>Total of <br /> <span style={{ fontWeight: "600" }}>Preventive maintenance</span> </div>}
          tooltipText={<p>
            Total of Preventive maintenances has {percentTotalWO === 0 ? <span style={{ fontWeight: "600", color: "#36383C" }}>no change</span> :
              percentTotalWO > 0 ? <span>increased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentTotalWO}% </span></span>
                : <span>decreased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentTotalWO}% </span></span>}  over the selected duration of time.
          </p>}
        />
        <SummaryItem
          n={openWO}
          np={percentOpenWO}
          status="Open"
          statusColor="#4472B8A3"
          tooltipLabel={<div>Total of <br /> <span style={{ fontWeight: "600" }}> Open Preventive maintenance</span> </div>}
          tooltipLabelWithgraph={<div>Percentage of <br /> <span style={{ fontWeight: "600" }}> Open Preventive maintenance</span> </div>}
          tooltipText={<p>
            Total of open Preventive maintenances has {percentOpenWO === 0 ? <span style={{ fontWeight: "600", color: "#36383C" }}>no change</span> :
              percentOpenWO > 0 ? <span>increased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentOpenWO}% </span></span>
                : <span>decreased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentOpenWO}% </span></span>}  over the selected duration of time.
          </p>}
          progress={(openWO / totalWO) * 100}
        />
        <SummaryItem
          n={onProgressWO}
          np={percentOnProgressWO}
          status="In progress"
          statusColor="#E3871EA3"
          tooltipLabel={<div>Total of <br /> <span style={{ fontWeight: "600" }}> In progress Preventive maintenance</span> </div>}
          tooltipLabelWithgraph={<div>Percentage of <br /> <span style={{ fontWeight: "600" }}> In progress Preventive maintenance</span> </div>}
          tooltipText={<p>
            Total of in progress Preventive maintenances has {percentOnProgressWO === 0 ? <span style={{ fontWeight: "600", color: "#36383C" }}>no change</span> :
              percentOnProgressWO > 0 ? <span>increased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentOnProgressWO}% </span></span>
                : <span>decreased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentOnProgressWO}% </span></span>}  over the selected duration of time.
          </p>}
          progress={(onProgressWO / totalWO) * 100}
        />
        <SummaryItem
          n={onHoldWO}
          np={percentOnHoldWO}
          status="On hold"
          statusColor="#575D5E"
          tooltipLabel={<div>Total of <br /> <span style={{ fontWeight: "600" }}> On hold Preventive maintenance</span> </div>}
          tooltipLabelWithgraph={<div>Percentage of <br /> <span style={{ fontWeight: "600" }}> On hold Preventive maintenance</span> </div>}
          tooltipText={<p>
            Total of on hold Preventive maintenances has {percentOnHoldWO === 0 ? <span style={{ fontWeight: "600", color: "#36383C" }}>no change</span> :
              percentOnHoldWO > 0 ? <span>increased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentOnHoldWO}% </span></span>
                : <span>decreased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentOnHoldWO}% </span></span>}  over the selected duration of time.
          </p>}
          progress={(onHoldWO / totalWO) * 100}
        />
        <SummaryItem
          n={completedWO}
          np={percentCompletedWO}
          status="Completed"
          statusColor="#20BB7DA3"
          tooltipLabel={<div>Total of <br /> <span style={{ fontWeight: "600" }}> Completed Preventive maintenance</span> </div>}
          tooltipLabelWithgraph={<div>Percentage of <br /> <span style={{ fontWeight: "600" }}> Completed Preventive maintenance</span> </div>}
          tooltipText={<p>
            Total of completed Preventive maintenances has {percentCompletedWO === 0 ? <span style={{ fontWeight: "600", color: "#36383C" }}>no change</span> :
              percentCompletedWO > 0 ? <span>increased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentCompletedWO}% </span></span>
                : <span>decreased by <span style={{ fontWeight: "600", color: "#36383C" }}>{percentCompletedWO}% </span></span>}  over the selected duration of time.
          </p>}
          progress={(completedWO / totalWO) * 100}
        />
      </div>
    </div>
  );
}
