/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prefer-template */
/* eslint-disable dot-notation */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { ButtonBase, IconButton, Modal } from '@material-ui/core';
import ReloadIcon from '../../assets/svg-icons/ReloadIcon';
import IconDownload from '../../assets/svg-icons/IconDownload';
import classes from '../../components/assetsTable/assetsTable.module.css';
import IconVerticalBox from '../../assets/svg-icons/IconVerticalBox';
import UploadIcon from '../../assets/svg-icons/UploadIcon';
import DownloadTemplateIcon from '../../assets/svg-icons/DownloadTemplateIcon';
import {
  downloadTemplate,
  exportSOR,
  getSORByPagination,
  importSOR,
  reset,
  setFilterWords,
  setImportFile,
  setIsBookmarked,
  setSearchValue,
  setSelectedFilter,
  setSource,
  setTrade,
} from '../../redux/features/SOR/SORSlice';
import AdvancedSearchInput from './advancedSearchInput';
import BookmarkIcon from '../../assets/svg-icons/BookmarkIcon';
import IconSearch from '../../assets/svg-icons/IconSearch';
import styles from '../assetsPage/assestsPage.module.css';
import EllipsisIcon from '../../assets/svg-icons/EllipsisIcon';

const EpqFilter = ({
  setISShowFilter,
  iSShowFilter,
  setIsShowMenu,
  isShowMenu,
  allColumns,
  getDataRows,
  setIsLoading,
  fromBillPopup,
}) => {
  const {
    fileExport,
    template,
    sources,
    source,
    rowPerPage,
    currentPage,
    searchValue,
    filterWords,
    SORTab,
    isBookmarked,
  } = useSelector((state) => state.SOR);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleEraserFilterClick = () => {
    // reset the state and store
    dispatch(setSource(''));
    dispatch(setIsBookmarked(false));
    const getData = async () => {
      setIsLoading(true);
      const filters = {
        rowPerPage,
        currentPage,
        searchValue,
        source: '',
        all: filterWords.all,
        exact: filterWords.exact,
        any: filterWords.any,
        none: filterWords.none,
        isBookmarked,
      };
      await dispatch(getSORByPagination(filters));
      setIsLoading(false);
    };
    getData();
  };

  const handelFetchFilters = () => {
    getDataRows();
  };

  // Advance filter dialog start
  const [openAdvFilter, setOpenAdvFilter] = useState(false);

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true);
  };

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false);
  };

  const handleEraserAdvancedFilterClick = () => {
    dispatch(setSource(''));
    dispatch(
      setFilterWords({
        all: '',
        exact: '',
        any: '',
        none: '',
      }),
    );
    dispatch(setSelectedFilter('all'));
  };
  const handleSearchAdvFilter = () => {
    getDataRows();
    setOpenAdvFilter(false);
  };

  // Advance filter dialog end

  const buttonDisabledStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    backgroundColor: '#D9314A',
    borderRadius: '8px',
  };

  const buttonStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#36383C',
    backgroundColor: '#F8F9F9',
    border: '1px solid #E1E1E1',
    borderRadius: '8px',
  };

  const AdvancedModal = (
    <Modal
      open={openAdvFilter}
      onClose={handleCloseAdvFilter}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '480px',
          padding: '12px 16px',
          backgroundColor: '#F8F9F9',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            fontWeight: '600',
            fontSize: '21px',
            marginBottom: '20px',
          }}
        >
          Advanced filter
        </div>
        <div
          className="wo-filter-wrapper"
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          <h6 className="mb-0">By word</h6>
          <AdvancedSearchInput
            title="All of these words"
            keyWord="all"
            example='Example: General checking ; contains both "General" and "checking"'
            val={filterWords.all}
          />
          <AdvancedSearchInput
            title="This exact phrase"
            keyWord="exact"
            example='Example: Cooling Tower ; contains the exact phrase "Cooling Tower"'
            val={filterWords.exact}
          />
          <AdvancedSearchInput
            title="Any of these words"
            keyWord="any"
            example='Example: Pintu Door ; contains either "Pintu" or "Door" (or both)'
            val={filterWords.any}
          />
          <AdvancedSearchInput
            title="None of these words"
            keyWord="none"
            example='Example: General checking ; contains both "General" and "checking"'
            val={filterWords.none}
          />
          <div className="filter-wrapper" style={{ marginTop: '-10px', marginBottom: '20px' }}>
            <label style={{ fontWeight: 500, color: '#36383c' }}>By source</label>
            <select
              onChange={(e) => dispatch(setSource(e.target.value))}
              value={source}
              className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md "
            >
              <option value="">All Sources</option>
              {sources &&
                sources.length > 0 &&
                sources.map((s) => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonBase
            autoCapitalize="none"
            onClick={handleEraserAdvancedFilterClick}
            style={buttonStyle}
          >
            Reset
          </ButtonBase>
          <div style={{ display: 'flex', gap: '12px' }}>
            <ButtonBase onClick={handleCloseAdvFilter} style={buttonStyle}>
              Close
            </ButtonBase>
            <ButtonBase onClick={handleSearchAdvFilter} style={buttonDisabledStyle}>
              Search
            </ButtonBase>
          </div>
        </div>
      </div>
    </Modal>
  );

  const fileExtension = '.csv';
  const fileExName = 'exported_SOR_data';

  const exportToCSV = async (apiData, fileName) => {
    const headers = Object.keys(apiData[0]);

    // Convert data to CSV format
    const csvContent = apiData
      .map((row) => {
        return headers
          .map((header) => {
            // If the field contains a comma or double quote, enclose it in quotes and escape any double quotes
            let field = row[header].replace(/"/g, '""'); // Escape double quotes
            if (
              field.includes(',') ||
              field.includes('"') ||
              field.includes('\n') ||
              field.includes('\r')
            ) {
              field = `"${field}"`; // Enclose in quotes if necessary
            }
            return field;
          })
          .join(',');
      })
      .join('\n');

    // Construct the final CSV content
    const finalCSV = headers.join(',') + '\n' + csvContent;

    // Create a Blob from the CSV content with the appropriate Content-Type
    const data = new Blob([finalCSV], { type: 'text/csv' });

    // Save the Blob as a file
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    if (fileExport?.length > 0) {
      exportToCSV(fileExport, fileExName);
      dispatch(reset());
    }
  }, [fileExport]);

  function createCSVContent(headers) {
    const csvContent = headers.join(',') + '\n';
    return csvContent;
  }

  function downloadCSVFile(csvContent, filename) {
    const data = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    if (template) {
      const handleDownload = () => {
        const csvContent = createCSVContent(template);
        downloadCSVFile(csvContent, 'SOR-Template.csv');
      };
      handleDownload();
      dispatch(reset());
    }
  }, [template]);

  const handelImportFile = async (e) => {
    dispatch(setImportFile(e.target.files[0]));
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    await dispatch(importSOR(formData));
  };

  const handelChange = (e) => {
    dispatch(setSearchValue(e.target.value));
  };

  return (
    <div className="wo-filters-wrapper" style={{ marginBottom: '8px', marginTop: '10px' }}>
      {SORTab === 'sor' && (
        <>
          <div style={{ display: 'flex', gap: '16px' }}>
            {!fromBillPopup && (
              <div className="filter-wrapper" style={{ width: '180px', alignSelf: 'flex-end' }}>
                <div
                  className={styles.assetActions}
                  style={{
                    display: 'flex',
                    backgroundColor: 'white',
                    border: '1px solid #E1E1E1',
                    borderRadius: '8px',
                    alignItems: 'center',
                    paddingLeft: '16px',
                    /* width: 250px; */
                  }}
                >
                  <IconSearch />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchValue}
                    onChange={handelChange}
                    className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md "
                  />
                </div>
              </div>
            )}
            <div className="filter-wrapper" style={{ width: '160px' }}>
              <label style={{ color: '#36383c', fontWeight: 500 }}>Sources</label>
              <select
                onChange={(e) => dispatch(setSource(e.target.value))}
                value={source}
                className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md "
              >
                <option value="">All Sources</option>
                {sources &&
                  sources.length > 0 &&
                  sources.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
              </select>
            </div>
            {!fromBillPopup && (
              <div
                className="filter-wrapper"
                style={{ width: '40px', display: 'flex', alignItems: 'flex-end' }}
              >
                <button
                  className="new-btn"
                  type="button"
                  title="Bookmark"
                  onClick={() => dispatch(setIsBookmarked(!isBookmarked))}
                >
                  <BookmarkIcon fill={isBookmarked ? '#36383C' : 'none'} />
                </button>
              </div>
            )}
            <button
              style={{
                alignSelf: 'flex-end',
                color: 'black',
                padding: '.375rem .7rem',
                display: 'flex',
                alignItems: 'center',
              }}
              className="new-btn btn"
              onClick={handleEraserFilterClick}
              title="Reset"
            >
              <ReloadIcon /> <span className="ml-2">Reset</span>
            </button>
            <button
              style={{
                alignSelf: 'flex-end',
                padding: '.375rem .7rem',
                color: 'black',
                marginLeft: '-10px',
              }}
              className="new-btn btn"
              onClick={handelFetchFilters}
              title="Reload"
            >
              {/* <ReloadIcon /> */}
              <FontAwesomeIcon icon={faRotate} style={{ color: 'black' }} />{' '}
              <span className="ml-2">Reload</span>
            </button>
          </div>

          <div style={{ display: 'flex', gap: '12px' }}>
            {fromBillPopup && (
              <div
                className={styles.assetActions}
                style={{
                  display: 'flex',
                  backgroundColor: '#F8F9F9',
                  border: '1px solid #E1E1E1',
                  borderRadius: '8px',
                  alignItems: 'center',
                  paddingRight: '16px',
                  /* width: 250px; */
                }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handelChange}
                />
                <IconSearch />
              </div>
            )}
            {!fromBillPopup && (
              <>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#36383C',
                    color: '#F8F9F9',
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '8px 16px',
                    borderRadius: '8px',
                  }}
                  onClick={handleOpenAdvFilter}
                  className="adv-button"
                >
                  Advanced filter
                </Button>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    style={{
                      backgroundColor: '#F8F9F9',
                      borderRadius: '8px',
                      border: '1px solid #E1E1E1',
                    }}
                    variant="contained"
                    title="Column selection"
                    className="outlined-button mr-2"
                    onClick={() => setISShowFilter(!iSShowFilter)}
                  >
                    <IconVerticalBox />
                  </IconButton>
                  {iSShowFilter && (
                    <>
                      <div className="tck-overlay" onClick={() => setISShowFilter(!iSShowFilter)} />
                      <div className={classes.newFilterTable}>
                        <div className={classes.newFilterTableCheckboxs}>
                          <p className="weekly-frequency-label ml-0">Filter column</p>
                          {/* Loop through columns data to create checkbox */}
                          {allColumns.map((column) => {
                            if (column.id !== 'delete-table-column') {
                              return (
                                <div key={column.id} className={classes.newFilterTableCheckboxs}>
                                  <label className="weekly-frequency-switch black-switch">
                                    <input type="checkbox" {...column.getToggleHiddenProps()} />
                                    <span className="weekly-frequency-slider" />
                                  </label>
                                  <span className="weekly-frequency-label">{column.Header}</span>
                                </div>
                              );
                            }
                          })}
                          <br />
                        </div>
                      </div>
                    </>
                  )}
                  <div style={{ position: 'relative' }}>
                    {user?.role === 'CliAdm' && (
                      <IconButton
                        title="Menu"
                        variant="contained"
                        style={{
                          backgroundColor: '#F8F9F9',
                          borderRadius: '8px',
                          border: '1px solid #E1E1E1',
                          width: '43px',
                          height: '44px',
                          color: '#36383C',
                        }}
                        onClick={() => setIsShowMenu(!isShowMenu)}
                        className="outlined-button"
                      >
                        <span
                          style={{
                            color: '#36383C',
                          }}
                        >
                          <EllipsisIcon />
                        </span>
                      </IconButton>
                    )}
                    {isShowMenu && (
                      <>
                        <div className="tck-overlay" onClick={() => setIsShowMenu(!isShowMenu)} />
                        <div className={`${classes.newFilterTable} ebq-buttons-header`}>
                          <button onClick={() => dispatch(exportSOR({ extension: 'JSON' }))}>
                            <IconDownload /> <span>Import SOR</span>
                          </button>
                          <button className="import-checklist mr-2 outlined-button">
                            <input
                              onChange={handelImportFile}
                              type="file"
                              name="file"
                              id="file"
                              accept=".csv"
                            />
                            <label
                              htmlFor="file"
                              className="mb-0"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                padding: '0px',
                                width: '100%',
                              }}
                            >
                              <UploadIcon /> <span style={{ fontWeight: '400' }}>Upload SOR</span>
                            </label>
                          </button>
                          <button
                            onClick={() =>
                              dispatch(downloadTemplate({ extension: 'CSV', template_type: 'SOR' }))
                            }
                          >
                            <DownloadTemplateIcon /> Download template
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {AdvancedModal}
        </>
      )}
    </div>
  );
};

export default EpqFilter;
