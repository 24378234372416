import React from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenSORModal, setSOR, updateBookmark } from '../../redux/features/SOR/SORSlice';
import BookmarkIcon from '../../assets/svg-icons/BookmarkIcon';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

function EpqItem() {
  const { openSORModal, SOR } = useSelector((state) => state.SOR);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setOpenSORModal(false));
  };
  const handleBookMark = () => {
    dispatch(setSOR({ ...SOR, is_bookmarked: !SOR.is_bookmarked }));
    dispatch(updateBookmark(SOR?.sor_id));
  };

  return (
    <Drawer
      anchor="right"
      open={openSORModal}
      onClose={close}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <div className="h-100 w-100">
        <div className="col-lg-12 col-sm-12 pb-5" id="">
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0 pt-3 pb-3">
            <div className="col-12 row px-2 ">
              <div className="my-auto" style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  type="button"
                  onClick={handleBookMark}
                  className="new-btn  mr-2"
                  style={{ backgroundColor: 'white' }}
                >
                  <BookmarkIcon fill={SOR?.is_bookmarked ? '#36383C' : 'none'} />
                </button>
                <b>{SOR?.sor_id}</b>
              </div>
              <div className="row ml-auto">
                <button
                  type="button"
                  className="btn tertiary-btn ml-2"
                  data-dismiss="modal"
                  onClick={close}
                >
                  <div className="fas fa-times" />
                </button>
              </div>
              {/* <div className="row justify-content-start mt-auto col-lg-12 col-sm-12 pt-3">
                <ul className="nav nav-pills" id="custom-tabs-two-tab" role="tablist">
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${
                        itemPopupTab === 'item-details-tab' && 'active'
                      } cursor-pointer`}
                      id="item-details-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="custom-tabs-two-meter"
                      aria-selected="false"
                    >
                      Item details
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${
                        itemPopupTab === 'reference-tab' && 'active'
                      } cursor-pointer`}
                      id="reference-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="custom-tabs-two-meter"
                      aria-selected="false"
                    >
                      Reference
                    </span>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 pb-5 " id="item-details-tab">
            <div className="col-lg-12 col-sm-12 pt-3 row">
              <div className="col-lg-6 col-sm-6">
                <label style={{ fontWeight: 500, color: '#36383C' }}>Source:</label>
                <input
                  type="text"
                  value={SOR?.source}
                  disabled
                  className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <label style={{ fontWeight: 500, color: '#36383C' }}>Year:</label>
                <input
                  type="text"
                  value={SOR?.year}
                  disabled
                  className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
                />
              </div>
            </div>

            <div className="col-lg-12 col-sm-12 pt-3 row">
              <div className="col-lg-6 col-sm-6">
                <label style={{ fontWeight: 500, color: '#36383C' }}>Reference ID:</label>
                <input
                  type="text"
                  value={`${SOR?.id_1  || ""}${SOR?.id_2? '/' : ''}${SOR?.id_2|| ""}${SOR?.id_3? '/' : ''}${SOR?.id_3|| ""}`}
                  disabled
                  className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <label style={{ fontWeight: 500, color: '#36383C' }}>Trade:</label>
                <input
                  type="text"
                  value={SOR?.trade || '-'}
                  disabled
                  className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
                />
              </div>
            </div>

            <div className="col-lg-12 col-sm-12 pt-3">
              <label style={{ fontWeight: 500, color: '#36383C' }}>Section title:</label>
              <textarea
                style={{ height: '100px' }}
                type="text"
                value={`${SOR?.header_1 || ""}${SOR?.header_2? '\n' : ''}${SOR?.header_2 || ""}${SOR?.header_3? '\n' : ''}${SOR?.header_3|| "" }`}
                disabled
                className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
              />
            </div>
            <div className="col-lg-12 col-sm-12 pt-3">
              <label style={{ fontWeight: 500, color: '#36383C' }}>Description:</label>
              <textarea
                style={{ height: '100px' }}
                value={`${SOR?.description_1 || ""}${SOR?.description_2? '\n' : ''}${SOR?.description_2 || ""}${SOR?.description_3? '\n' : ''}${SOR?.description_3 || ""}`}
                disabled
                className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
              />
            </div>
            <div className="col-lg-12 col-sm-12 pt-3 row">
              <div className="col-lg-6 col-sm-6">
                <label style={{ fontWeight: 500, color: '#36383C' }}>Unit:</label>
                <input
                  type="text"
                  value={SOR?.unit}
                  disabled
                  className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <label style={{ fontWeight: 500, color: '#36383C' }}>Rate (RM)</label>
                <input
                  type="text"
                  value={SOR?.rate}
                  disabled
                  className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
                />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 pt-3">
              <label style={{ fontWeight: 500, color: '#36383C' }}>Notes:</label>
              <textarea
                style={{ height: '100px' }}
                value={SOR?.notes || '-'}
                disabled
                className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md disable-calss"
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default EpqItem;
