/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SORService from './SORService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  SORs: [],
  SOR: null,
  BOQData: [],
  BOSData: [],
  singleBill: null,
  singleBillSummary: null,
  isPrint: false,
  isBillSummaryPrint: false,
  SORHistoryData: [],
  sorces: [],
  trades: [],
  summaryCounts: 0,

  itemPopupTab: 'item-details-tab',

  // filters for SOR
  searchValue: '',

  source: '',
  trade: '',
  filterWords: {
    all: '',
    exact: '',
    any: '',
    none: '',
  },
  selectedFilter: 'all',
  SORTab: 'sor',
  isBookmarked: false,

  // filters for BOQ
  BOQSearchValue: '',
  BOQFilterStartDate: '',
  BOQFilterEndDate: '',
  BOQDateFilterState: [
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ],
  BOQStatus: '',

  // filters for BOS
  BOSSearchValue: '',
  BOSFilterStartDate: '',
  BOSFilterEndDate: '',
  BOSDateFilterState: [
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ],
  BOSStatus: '',

  // filters for History
  historySearchValue: '',
  historyFilterStartDate: '',
  historyFilterEndDate: '',
  historyDateFilterState: [
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ],

  // pagination
  // SOR
  currentPage: 1,
  rowPerPage: 10,

  // BOQ
  BOQCurrentPage: 1,
  BOQRowPerPage: 10,

  // BOS
  BOSCurrentPage: 1,
  BOSRowPerPage: 10,

  // History
  historyCurrentPage: 1,
  historyRowPerPage: 10,

  // modals
  openSORModal: false,
  openSurchargeModal: false,
  openPenaltyModal: false,
  openAddItemsModal: false,

  // Summary Bill Data
  summaryBillData: [],
  summaryBillWorkData: {
    title: '',
    technicianName: '',
    contractorName: '',
    noVendor: '',
    startDate: '',
    endDate: '',
    jobNo: '',
    purchaseOrderNumber: '',
  },
  summarybillTotalPrice: null,

  // Bill Data
  billWorkOrderTypes: [],
  billData: [],
  billDataOnPopup: [],

  billTotalPrice: null,
  surchargeData: [],
  surchargeRates: [],
  surchargeTotalPrice: null,
  penaltyData: [],
  penaltyTotalPrice: 0,
  subTotal: null,

  billWorkData: {
    title: '',
    description: '',
    agreementNo: '',
    jobNum: '',
    order: '',
    orderType: '',
    location: '',
  },

  // request states
  isSORGetLoading: false,
  isBillCreateLoading: false,
  isBillGetLoading: false,
  isBillsGetLoading: false,
  isBillDeleteLoading: false,
  isBillUpdateLoading: false,
  isBillSummaryCreateLoading: false,
  isBillSummaryDeleteLoading: false,

  // error states
  isSORGetError: false,
  isBillGetError: false,
  isBillsGetError: false,
  isBillCreateError: false,
  isBillDeleteError: false,
  isBillUpdateError: false,
  isBillSummaryCreateError: false,
  isBillSummaryDeleteError: false,

  // success states
  isSORGetSuccess: false,
  isBillGetSuccess: false,
  isBillsGetSuccess: false,
  isBillCreateSuccess: false,
  isBillDeleteSuccess: false,
  isBillUpdateSuccess: false,
  isBillSummaryCreateSuccess: false,
  isBillSummaryDeleteSuccess: false,

  // Sor import & export states
  fileExport: [],
  fileImport: null,
  template: null,
  isSORExported: false,
  isSORImported: false,
  isSORImportedLoading: false,
  isSORImportedError: false,
  isSORDownloadLoading: false,
  isSORDownloadError: false,
  isSORDownloadSuccess: false,
};

export const getSORByAuthentication = createAsyncThunk(
  'getSORByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SORService.getSORByAuthentication, data, thunkAPI);
    return res;
  },
);
export const getSORSummary = createAsyncThunk('getSORSummary', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.getSORSummary, data, thunkAPI);
  return res;
});
export const getSORSoucrcesTardes = createAsyncThunk(
  'getSORSoucrcesTardes',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SORService.getSORSoucrcesTardes, data, thunkAPI);
    return res;
  },
);

export const getSORByPagination = createAsyncThunk('getSORByPagination', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.getSORByPagination, data, thunkAPI);
  return res;
});
export const getSORHistoryByPagination = createAsyncThunk(
  'getSORHistoryByPagination',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SORService.getSORHistoryByPagination, data, thunkAPI);
    return res;
  },
);

export const getBillWorkOrderType = createAsyncThunk(
  'getBillWorkOrderType',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SORService.getBillWorkOrderType, data, thunkAPI);
    return res;
  },
);

export const getSORById = createAsyncThunk('getSORById', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.getSORById, data, thunkAPI);
  return res;
});

export const createBill = createAsyncThunk('createBill', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.createBill, data, thunkAPI);
  return res;
});

export const createBillSummary = createAsyncThunk('createBillSummary', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.createBillSummary, data, thunkAPI);
  return res;
});

export const getBills = createAsyncThunk('getBills', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.getBills, data, thunkAPI);
  return res;
});

export const getBillsSummary = createAsyncThunk('getBillsSummary', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.getBillsSummary, data, thunkAPI);
  return res;
});

export const getBillById = createAsyncThunk('getBillById', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.getBillById, data, thunkAPI);
  return res;
});
export const getBillsSummaryById = createAsyncThunk(
  'getBillsSummaryById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SORService.getBillsSummaryById, data, thunkAPI);
    return res;
  },
);

export const deleteBillById = createAsyncThunk('deleteBillById', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.deleteBillById, data, thunkAPI);
  return res;
});
export const deleteBillSummaryById = createAsyncThunk(
  'deleteBillSummaryById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SORService.deleteBillSummaryById, data, thunkAPI);
    return res;
  },
);

export const updateBill = createAsyncThunk('updateBill', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.updateBill, data, thunkAPI);
  return res;
});
export const updateBillSummary = createAsyncThunk('updateBillSummary', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.updateBillSummary, data, thunkAPI);
  return res;
});
export const copyBOQ = createAsyncThunk('copyBOQ', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.copyBOQ, data, thunkAPI);
  return res;
});
export const updateBookmark = createAsyncThunk('updateBookmark', async (data, thunkAPI) => {
  const res = await smartTryCatch(SORService.updateBookmark, data, thunkAPI);
  return res;
});

export const exportSOR = createAsyncThunk('exportSOR', async (data, thunkAPI) => {
  const exportSORRes = await smartTryCatch(SORService.exportSOR, data, thunkAPI);
  return exportSORRes;
});
export const downloadTemplate = createAsyncThunk('downloadSORTemplate', async (data, thunkAPI) => {
  const downloadTemplatetRes = await smartTryCatch(SORService.downloadTemplate, data, thunkAPI);
  return downloadTemplatetRes;
});

export const importSOR = createAsyncThunk('importSOR', async (data, thunkAPI) => {
  const importSORRes = await smartTryCatch(SORService.importSOR, data, thunkAPI);
  return importSORRes;
});

export const SORSlice = createSlice({
  name: 'SOR',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isSORExported = false;
      state.fileExport = [];
      state.template = null;
      state.isSORImported = false;
      state.isSORImportedLoading = false;
      state.isSORImportedError = false;
      state.isSORDownloadSuccess = false;
      state.isSORDownloadLoading = false;
      state.isSORDownloadError = false;
      state.isBillCreateLoading = false;
      state.isBillCreateError = false;
      state.isBillCreateSuccess = false;
      state.isBillDeleteLoading = false;
      state.isBillDeleteError = false;
      state.isBillDeleteSuccess = false;
      state.isBillSummaryDeleteLoading = false;
      state.isBillSummaryDeleteSuccess = false;
      state.isBillSummaryDeleteError = false;
      state.isBillSummaryCreateLoading = false;
      state.isBillSummaryCreateError = false;
      state.isBillSummaryCreateSuccess = false;
    },
    resetState: (state) => {
      // Bill Data
      state.billData = [];
      state.billDataOnPopup = [];
      state.billTotalPrice = null;
      state.surchargeData = [];
      state.surchargeRates = [];
      state.surchargeTotalPrice = null;
      state.penaltyData = [];
      state.penaltyTotalPrice = 0;
      state.subTotal = null;
      state.singleBill = null;
      state.isPrint = null;
      state.billWorkData = {
        title: '',
        description: '',
        agreementNo: '',
        jobNum: '',
        order: '',
        orderType: '',
        location: '',
      };
    },
    resetBillSummaryState: (state) => {
      // Summary Bill Data
      state.summaryBillData = [];
      state.summaryBillWorkData = {
        title: '',
        technicianName: '',
        contractorName: '',
        noVendor: '',
        startDate: '',
        endDate: '',
        jobNo: '',
        purchaseOrderNumber: '',
      };
      state.summarybillTotalPrice = null;
      state.singleBillSummary = null;
      state.isBillSummaryPrint = null;
    },
    setImportFile: (state, action) => {
      state.fileImport = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    setBOQCurrentPage: (state, action) => {
      state.BOQCurrentPage = action.payload;
    },
    setBOQRowPerPage: (state, action) => {
      state.BOQRowPerPage = action.payload;
    },
    setBOSCurrentPage: (state, action) => {
      state.BOSCurrentPage = action.payload;
    },
    setBOSRowPerPage: (state, action) => {
      state.BOSRowPerPage = action.payload;
    },
    setHistoryCurrentPage: (state, action) => {
      state.historyCurrentPage = action.payload;
    },
    setHistoryRowPerPage: (state, action) => {
      state.historyRowPerPage = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSOR: (state, action) => {
      state.SOR = action.payload;
    },
    setOpenSORModal: (state, action) => {
      state.openSORModal = action.payload;
    },
    setOpenSurchargeModal: (state, action) => {
      state.openSurchargeModal = action.payload;
    },
    setOpenPenaltyModal: (state, action) => {
      state.openPenaltyModal = action.payload;
    },
    setOpenAddItemsModal: (state, action) => {
      state.openAddItemsModal = action.payload;
    },
    setSORTab: (state, action) => {
      state.SORTab = action.payload;
    },

    // filters
    setSource: (state, action) => {
      state.source = action.payload;
    },
    setTrade: (state, action) => {
      state.trade = action.payload;
    },
    setFilterWords: (state, action) => {
      state.filterWords = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setIsBookmarked: (state, action) => {
      state.isBookmarked = action.payload;
    },
    setBOQSearchValue: (state, action) => {
      state.BOQSearchValue = action.payload;
    },
    setBOQFilterStartDate: (state, action) => {
      state.BOQFilterStartDate = action.payload;
    },
    setBOQFilterEndDate: (state, action) => {
      state.BOQFilterEndDate = action.payload;
    },
    setBOQDateFilterState: (state, action) => {
      state.BOQDateFilterState = action.payload;
    },
    setBOQStatus: (state, action) => {
      state.BOQStatus = action.payload;
    },
    setBOSSearchValue: (state, action) => {
      state.BOSSearchValue = action.payload;
    },
    setBOSFilterStartDate: (state, action) => {
      state.BOSFilterStartDate = action.payload;
    },
    setBOSFilterEndDate: (state, action) => {
      state.BOSFilterEndDate = action.payload;
    },
    setBOSDateFilterState: (state, action) => {
      state.BOSDateFilterState = action.payload;
    },
    setBOSStatus: (state, action) => {
      state.BOSStatus = action.payload;
    },
    setHistorySearchValue: (state, action) => {
      state.historySearchValue = action.payload;
    },
    setHistoryFilterStartDate: (state, action) => {
      state.historyFilterStartDate = action.payload;
    },
    setHistoryFilterEndDate: (state, action) => {
      state.historyFilterEndDate = action.payload;
    },
    setHistoryDateFilterState: (state, action) => {
      state.historyDateFilterState = action.payload;
    },
    setBillSummaryData: (state, action) => {
      state.summaryBillData = action.payload;
    },
    setBillData: (state, action) => {
      state.billData = action.payload;
    },
    setBillDataOnPopup: (state, action) => {
      state.billDataOnPopup = action.payload;
    },
    setBillTotalPrice: (state, action) => {
      state.billTotalPrice = action.payload;
    },
    setSummaryBillTotalPrice: (state, action) => {
      state.summarybillTotalPrice = action.payload;
    },
    setSurchargeTotalPrice: (state, action) => {
      state.surchargeTotalPrice = action.payload;
    },
    setSurchargeData: (state, action) => {
      state.surchargeData = action.payload;
    },
    setSurchargeRates: (state, action) => {
      state.surchargeRates = action.payload;
    },
    setSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
    setPenaltyData: (state, action) => {
      state.penaltyData = action.payload;
    },
    setPenaltyTotalPrice: (state, action) => {
      state.penaltyTotalPrice = action.payload;
    },
    setSingleBill: (state, action) => {
      state.singleBill = action.payload;
    },
    setIsPrint: (state, action) => {
      state.isPrint = action.payload;
    },
    setIsBillSummaryPrint: (state, action) => {
      state.isBillSummaryPrint = action.payload;
    },
    setBillQty: (state, action) => {
      const { item, type, qty, dis, typeOfOrder } = action.payload;
      const findIndex = state.billData.findIndex((ele) => ele.id === item.id);
      const obj = state.billData[findIndex];

      if (type === 'DiscountInput') {
        obj.discountRate = Number(dis);
      }

      if (typeOfOrder === 'client') {
        if (type === 'inc') {
          obj.quantity += 1;
        } else if (type === 'dec') {
          if (obj.quantity > 1) {
            obj.quantity -= 1;
          }
        } else if (type === 'QTYInput') {
          obj.quantity = Number(qty);
        }
      } else {
        if (type === 'inc') {
          obj.vendorQuantity += 1;
        } else if (type === 'dec') {
          if (obj.vendorQuantity > 1) {
            obj.vendorQuantity -= 1;
          }
        } else if (type === 'QTYInput') {
          obj.vendorQuantity = Number(qty);
        }
      }
      const priceWithDiscount =
        obj.rate * obj.quantity - (obj.discountRate * obj.rate * obj.quantity) / 100;
      const vedorPriceWithDiscount =
        obj.rate * obj.vendorQuantity - (obj.discountRate * obj.rate * obj.vendorQuantity) / 100;
      obj.totalPrice = Number(priceWithDiscount.toFixed(2));
      obj.vendorTotalPrice = Number(vedorPriceWithDiscount.toFixed(2));
      obj.vendorTotalPrice = Number(vedorPriceWithDiscount.toFixed(2));
      state.billData[findIndex] = obj;
    },
    setBillWorkData: (state, action) => {
      state.billWorkData = action.payload;
    },
    setSummaryBillWorkData: (state, action) => {
      state.summaryBillWorkData = action.payload;
    },
    setItemPopupTab: (state, action) => {
      state.itemPopupTab = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSORByAuthentication.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORByAuthentication.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SORs = { ...action.payload };
      })
      .addCase(getSORByAuthentication.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      // .addCase(getBillWorkOrderType.pending, (state) => {
      //   state.isSORGetLoading = true;
      //   state.isSORGetSuccess = false;
      //   state.isSORGetError = false;
      // })
      .addCase(getBillWorkOrderType.fulfilled, (state, action) => {
        // state.isSORGetLoading = false;
        // state.isSORGetSuccess = true;
        // state.isSORGetError = false;
        state.billWorkOrderTypes = action.payload;
      })
      // .addCase(getBillWorkOrderType.rejected, (state) => {
      //   state.isSORGetLoading = false;
      //   state.isSORGetSuccess = false;
      //   state.isSORGetError = true;
      // })
      .addCase(getSORById.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORById.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SOR = action.payload;
      })
      .addCase(getSORById.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(getSORSummary.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORSummary.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.summaryCounts = action.payload;
      })
      .addCase(getSORSummary.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(createBill.pending, (state) => {
        state.isBillCreateLoading = true;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = false;
      })
      .addCase(createBill.fulfilled, (state) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = true;
        state.isBillCreateError = false;
      })
      .addCase(createBill.rejected, (state, action) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = action.payload;
      })
      .addCase(createBillSummary.pending, (state) => {
        state.isBillSummaryCreateLoading = true;
        state.isBillSummaryCreateSuccess = false;
        state.isBillSummaryCreateError = false;
      })
      .addCase(createBillSummary.fulfilled, (state) => {
        state.isBillSummaryCreateLoading = false;
        state.isBillSummaryCreateSuccess = true;
        state.isBillSummaryCreateError = false;
      })
      .addCase(createBillSummary.rejected, (state, action) => {
        state.isBillSummaryCreateLoading = false;
        state.isBillSummaryCreateSuccess = false;
        state.isBillSummaryCreateError = action.payload;
      })
      .addCase(getBills.pending, (state) => {
        state.isBillsGetLoading = true;
        state.isBillsGetSuccess = false;
        state.isBillsGetError = false;
      })
      .addCase(getBills.fulfilled, (state, action) => {
        state.isBillsGetLoading = false;
        state.isBillsGetSuccess = true;
        state.isBillsGetError = false;
        state.BOQData = action.payload;
      })
      .addCase(getBills.rejected, (state) => {
        state.isBillsGetLoading = false;
        state.isBillsGetSuccess = false;
        state.isBillsGetError = true;
      })
      .addCase(getBillsSummary.pending, (state) => {
        state.isBillsGetLoading = true;
        state.isBillsGetSuccess = false;
        state.isBillsGetError = false;
      })
      .addCase(getBillsSummary.fulfilled, (state, action) => {
        state.isBillsGetLoading = false;
        state.isBillsGetSuccess = true;
        state.isBillsGetError = false;
        state.BOSData = action.payload;
      })
      .addCase(getBillsSummary.rejected, (state) => {
        state.isBillsGetLoading = false;
        state.isBillsGetSuccess = false;
        state.isBillsGetError = true;
      })
      .addCase(getBillById.pending, (state) => {
        state.isBillGetLoading = true;
        state.isBillGetSuccess = false;
        state.isBillGetError = false;
      })
      .addCase(getBillById.fulfilled, (state, action) => {
        state.isBillGetLoading = false;
        state.isBillGetSuccess = true;
        state.isBillGetError = false;
        state.singleBill = action.payload;
      })
      .addCase(getBillById.rejected, (state) => {
        state.isBillGetLoading = false;
        state.isBillGetSuccess = false;
        state.isBillGetError = true;
      })
      .addCase(getBillsSummaryById.pending, (state) => {
        state.isBillGetLoading = true;
        state.isBillGetSuccess = false;
        state.isBillGetError = false;
      })
      .addCase(getBillsSummaryById.fulfilled, (state, action) => {
        state.isBillGetLoading = false;
        state.isBillGetSuccess = true;
        state.isBillGetError = false;
        state.singleBillSummary = action.payload;
      })
      .addCase(getBillsSummaryById.rejected, (state) => {
        state.isBillGetLoading = false;
        state.isBillGetSuccess = false;
        state.isBillGetError = true;
      })
      .addCase(deleteBillById.pending, (state) => {
        state.isBillDeleteLoading = true;
        state.isBillDeleteSuccess = false;
        state.isBillDeleteError = false;
      })
      .addCase(deleteBillById.fulfilled, (state, action) => {
        state.isBillDeleteLoading = false;
        state.isBillDeleteSuccess = true;
        state.isBillDeleteError = false;
        state.BOQData.results = state.BOQData.results.filter((boq) => boq.id !== +action.payload);
      })
      .addCase(deleteBillById.rejected, (state) => {
        state.isBillDeleteLoading = false;
        state.isBillDeleteSuccess = false;
        state.isBillDeleteError = true;
      })
      .addCase(deleteBillSummaryById.pending, (state) => {
        state.isBillSummaryDeleteLoading = true;
        state.isBillSummaryDeleteSuccess = false;
        state.isBillSummaryDeleteError = false;
      })
      .addCase(deleteBillSummaryById.fulfilled, (state, action) => {
        state.isBillSummaryDeleteLoading = false;
        state.isBillSummaryDeleteSuccess = true;
        state.isBillSummaryDeleteError = false;
        state.BOSData.results = state.BOSData.results.filter((bos) => bos.id !== +action.payload);
      })
      .addCase(deleteBillSummaryById.rejected, (state) => {
        state.isBillSummaryDeleteLoading = false;
        state.isBillSummaryDeleteSuccess = false;
        state.isBillSummaryDeleteError = true;
      })
      .addCase(updateBill.pending, (state) => {
        state.isBillCreateLoading = true;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = false;
      })
      .addCase(updateBill.fulfilled, (state, action) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = true;
        state.isBillCreateError = false;
        state.singleBill = action.payload;
      })
      .addCase(updateBill.rejected, (state, action) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = action.payload;
      })
      .addCase(updateBillSummary.pending, (state) => {
        state.isBillSummaryCreateLoading = true;
        state.isBillSummaryCreateSuccess = false;
        state.isBillSummaryCreateError = false;
      })
      .addCase(updateBillSummary.fulfilled, (state, action) => {
        state.isBillSummaryCreateLoading = false;
        state.isBillSummaryCreateSuccess = true;
        state.isBillSummaryCreateError = false;
        state.singleBillSummary = action.payload;
      })
      .addCase(updateBillSummary.rejected, (state, action) => {
        state.isBillSummaryCreateLoading = false;
        state.isBillSummaryCreateSuccess = false;
        state.isBillSummaryCreateError = action.payload;
      })
      // .addCase(copyBOQ.pending, (state) => {
      //   state.isBillCreateLoading = true;
      //   state.isBillCreateSuccess = false;
      //   state.isBillCreateError = false;
      // })
      // .addCase(copyBOQ.fulfilled, (state, action) => {
      //   state.isBillCreateLoading = false;
      //   state.isBillCreateSuccess = true;
      //   state.isBillCreateError = false;
      //   state.singleBill = action.payload;
      // })
      // .addCase(copyBOQ.rejected, (state) => {
      //   state.isBillCreateLoading = false;
      //   state.isBillCreateSuccess = false;
      //   state.isBillCreateError = true;
      // })
      // .addCase(updateBookmark.fulfilled, (state, action) => {
      //   state.SOR = action.payload;
      // })
      .addCase(getSORSoucrcesTardes.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORSoucrcesTardes.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.sources = action.payload.sources;
        state.trades = action.payload.trades;
      })
      .addCase(getSORSoucrcesTardes.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(getSORByPagination.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORByPagination.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SORs = action.payload;
      })
      .addCase(getSORByPagination.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(getSORHistoryByPagination.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORHistoryByPagination.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SORHistoryData = action.payload;
      })
      .addCase(getSORHistoryByPagination.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(exportSOR.pending, (state) => {
        state.isSORExported = false;
      })
      .addCase(exportSOR.fulfilled, (state, action) => {
        state.isSORExported = true;
        state.fileExport = action.payload;
      })
      .addCase(exportSOR.rejected, (state) => {
        state.isSORExported = false;
      })
      .addCase(downloadTemplate.pending, (state) => {
        state.isSORDownloadLoading = true;
        state.isSORDownloadSuccess = false;
        state.isSORDownloadError = false;
      })
      .addCase(downloadTemplate.fulfilled, (state, action) => {
        state.isSORDownloadLoading = false;
        state.isSORDownloadSuccess = true;
        state.isSORDownloadError = false;
        state.template = action.payload.split(',');
      })
      .addCase(downloadTemplate.rejected, (state) => {
        state.isSORDownloadLoading = false;
        state.isSORDownloadSuccess = false;
        state.isSORDownloadError = true;
      })
      .addCase(importSOR.pending, (state) => {
        state.isSORImported = false;
        state.isSORImportedLoading = true;
        state.isSORImportedError = false;
      })
      .addCase(importSOR.fulfilled, (state, action) => {
        state.isSORImportedLoading = false;
        state.isSORImported = true;
        state.isSORImportedError = false;
        state.fileImport = action.payload;
      })
      .addCase(importSOR.rejected, (state) => {
        state.isSORImported = false;
        state.isSORImportedLoading = false;
        state.isSORImportedError = true;
      });
  },
});

export const {
  reset,
  setCurrentPage,
  setRowPerPage,
  setBOQCurrentPage,
  setBOQRowPerPage,
  setBOSCurrentPage,
  setBOSRowPerPage,
  setHistoryCurrentPage,
  setHistoryRowPerPage,
  setSearchValue,
  setSOR,
  setOpenSORModal,
  setOpenAddItemsModal,
  setOpenSurchargeModal,
  setOpenPenaltyModal,
  setSORTab,
  setImportFile,
  setSource,
  setTrade,
  setSelectedFilter,
  setFilterWords,
  setBillWorkData,
  setSummaryBillWorkData,
  setItemPopupTab,
  setBillData,
  setBillDataOnPopup,
  setBillTotalPrice,
  setSummaryBillTotalPrice,
  setSurchargeTotalPrice,
  setSurchargeData,
  setSurchargeRates,
  setPenaltyData,
  setPenaltyTotalPrice,
  setBillQty,
  setSubTotal,
  resetState,
  setSingleBill,
  setIsPrint,
  setIsBookmarked,
  setBillSummaryData,
  resetBillSummaryState,
  setBOQSearchValue,
  setBOQFilterStartDate,
  setBOQFilterEndDate,
  setBOQDateFilterState,
  setBOQStatus,
  setBOSSearchValue,
  setBOSFilterStartDate,
  setBOSFilterEndDate,
  setBOSDateFilterState,
  setBOSStatus,
  setHistorySearchValue,
  setHistoryFilterStartDate,
  setHistoryFilterEndDate,
  setHistoryDateFilterState,
  setIsBillSummaryPrint,
} = SORSlice.actions;

export default SORSlice.reducer;
